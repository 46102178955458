import React, { useState } from "react";
import { TrialRequestForm, PostTrialRequestForm } from "./form";
import { useMedia } from "use-media";

export const RequestForm = (props) => {
  const [contactId, setContactId] = useState("");
  const isTwoSections = useMedia({ maxWidth: "1200px" });
  const additionalClass = props.additionalClass;
  const additionalClassPaid = props.additionalClassPaid;
  const isSmall = useMedia({ maxWidth: "520px" });
  console.log("props.RequestForm", props, isSmall);
  console.log("contactid:::",contactId)
  return (
    <>
      {props.isFooterForm ? (
        <section
          className={`${"footer_form"} ${
            additionalClass ? additionalClass : ""
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 footer_form_container">
                <div className="footer_form_left col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className="main-slider-left">
                    <h3 className="font-section-sub-header-bold font-section-cta-mobile-sub-header">
                      {props.product == "automate"
                        ? "Ready to get started?"
                        : "Ready to get started?"}
                    </h3>
                    <h5 className="font-section-sub-header-small font-section-cta-mobile--sub-header-small">
                      {props.product == "automate"
                        ? "Pre-built app workflows for FREE"
                        : "Pre-built bots and app workflows for FREE"}
                    </h5>
                    {props.automateContent ? (
                      <p className="automate_form_p">{props.content}</p>
                    ) : isSmall ? (
                      <p className="font-section-normal-text pr-0 pb-0">
                        Start on our free plan, and scale up <br />
                        as you grow.
                      </p>
                    ) : (
                      <p className="font-section-normal-text pr-0">
                        Start on our free plan, and scale up as you grow.
                      </p>
                    )}
                  </div>
                </div>
                <div className="footer_form_right col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className="trial_header_left request_form_assist_left">
                    <div
                      className={`main-slider-button beta-button ${
                        !isTwoSections ? "pt-4" : "pt-0"
                      }`}
                    >
                      {console.log("one")}
                      <TrialRequestForm
                        // product="automate"
                        AutomateCTA={props.AutomateCTA}
                        fromPage={props.fromPage}
                        product={props.product}
                        automateContent={props.automateContent}
                        content={props.content}
                        autoamteButton={props.autoamteButton}
                        setContactId={setContactId}
                        list={props.list}
                        isFooterForm={props.isFooterForm}
                        parentContainerRef={props.parentContainerRef}
                        additionalClassForm={props.additionalClassForm}
                        contentHidden={props.contentHidden}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className={`main-slider-button beta-button home_page_button request_form_top_slider ${
            additionalClassPaid ? additionalClassPaid : ""
          }`}
        >
          <TrialRequestForm
            // product="automate"
            AutomateCTA={props.AutomateCTA}
            product={props.product}
            automateContent={props.automateContent}
            content={props.content}
            autoamteButton={props.autoamteButton}
            setContactId={setContactId}
            fromPage={props.fromPage}
            list={props.list}
            parentContainerRef={props.parentContainerRef}
            onRegisterSuccessCallback={
              props.needSecondForm ? null : props.onRegisterSuccessCallback
            }
            additionalClassForm={props.additionalClassForm}
            contentHidden={props.contentHidden}
          />
        </div>
      )}
      <PostTrialRequestForm
        contactId={contactId}
        fromPage={props.fromPage}
        setContactId={setContactId}
        list={props.list}
        product="automate"
        onRegisterSuccessCallback={
          props.needSecondForm ? props.onRegisterSuccessCallback : null
        }
      />
    </>
  );
};
