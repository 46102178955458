import * as parseDomain from 'parse-domain'
import * as R from 'ramda'

import emailBlockList from './emailBlockList'

const pD = parseDomain.default ? parseDomain.default : parseDomain

const getDomain = R.compose(
    R.ifElse(R.startsWith('.'), R.slice(1, Infinity), R.identity),
    R.converge(R.unapply(R.join('.')), [R.prop('subdomain'), R.prop('domain'), R.prop('tld')]),
    pD
)
const domainIsExistedInBlackList = (domain) => {
    return R.findIndex(R.equals(domain), emailBlockList) !== -1
}
export const isBlockedDomain = R.compose(domainIsExistedInBlackList, getDomain)
