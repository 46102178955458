
const axios = require("axios").default;
export const options = [
    {
      value: "Afghanistan",
      "#text": "Afghanistan",
    },
    {
      value: "Albania",
      "#text": "Albania",
    },
    {
      value: "Algeria",
      "#text": "Algeria",
    },
    {
      value: "American Samoa",
      "#text": "American Samoa",
    },
    {
      value: "Andorra",
      "#text": "Andorra",
    },
    {
      value: "Angola",
      "#text": "Angola",
    },
    {
      value: "Anguilla",
      "#text": "Anguilla",
    },
    {
      value: "Antarctica",
      "#text": "Antarctica",
    },
    {
      value: "Antigua and Barbuda",
      "#text": "Antigua and Barbuda",
    },
    {
      value: "Argentina",
      "#text": "Argentina",
    },
    {
      value: "Armenia",
      "#text": "Armenia",
    },
    {
      value: "Aruba",
      "#text": "Aruba",
    },
    {
      value: "Australia",
      "#text": "Australia",
    },
    {
      value: "Austria",
      "#text": "Austria",
    },
    {
      value: "Azerbaijan",
      "#text": "Azerbaijan",
    },
    {
      value: "Bahamas",
      "#text": "Bahamas",
    },
    {
      value: "Bahrain",
      "#text": "Bahrain",
    },
    {
      value: "Bangladesh",
      "#text": "Bangladesh",
    },
    {
      value: "Barbados",
      "#text": "Barbados",
    },
    {
      value: "Belarus",
      "#text": "Belarus",
    },
    {
      value: "Belgium",
      "#text": "Belgium",
    },
    {
      value: "Belize",
      "#text": "Belize",
    },
    {
      value: "Benin",
      "#text": "Benin",
    },
    {
      value: "Bermuda",
      "#text": "Bermuda",
    },
    {
      value: "Bhutan",
      "#text": "Bhutan",
    },
    {
      value: "Bolivia",
      "#text": "Bolivia",
    },
    {
      value: "Bosnia and Herzegovina",
      "#text": "Bosnia and Herzegovina",
    },
    {
      value: "Botswana",
      "#text": "Botswana",
    },
    {
      value: "Bouvet Island",
      "#text": "Bouvet Island",
    },
    {
      value: "Brazil",
      "#text": "Brazil",
    },
    {
      value: "British Indian Ocean Territory",
      "#text": "British Indian Ocean Territory",
    },
    {
      value: "Brunei Darussalam",
      "#text": "Brunei Darussalam",
    },
    {
      value: "Bulgaria",
      "#text": "Bulgaria",
    },
    {
      value: "Burkina Faso",
      "#text": "Burkina Faso",
    },
    {
      value: "Burundi",
      "#text": "Burundi",
    },
    {
      value: "Cambodia",
      "#text": "Cambodia",
    },
    {
      value: "Cameroon",
      "#text": "Cameroon",
    },
    {
      value: "Canada",
      "#text": "Canada",
    },
    {
      value: "Cape Verde",
      "#text": "Cape Verde",
    },
    {
      value: "Cayman Islands",
      "#text": "Cayman Islands",
    },
    {
      value: "Central African Republic",
      "#text": "Central African Republic",
    },
    {
      value: "Chad",
      "#text": "Chad",
    },
    {
      value: "Chile",
      "#text": "Chile",
    },
    {
      value: "China",
      "#text": "China",
    },
    {
      value: "Christmas Island",
      "#text": "Christmas Island",
    },
    {
      value: "Cocos (Keeling) Islands",
      "#text": "Cocos (Keeling) Islands",
    },
    {
      value: "Colombia",
      "#text": "Colombia",
    },
    {
      value: "Comoros",
      "#text": "Comoros",
    },
    {
      value: "Congo",
      "#text": "Congo",
    },
    {
      value: "Congo, the Democratic Republic of the",
      "#text": "Congo, the Democratic Republic of the",
    },
    {
      value: "Cook Islands",
      "#text": "Cook Islands",
    },
    {
      value: "Costa Rica",
      "#text": "Costa Rica",
    },
    {
      value: "Cote D'Ivoire",
      "#text": "Cote D'Ivoire",
    },
    {
      value: "Croatia",
      "#text": "Croatia",
    },
    {
      value: "Cuba",
      "#text": "Cuba",
    },
    {
      value: "Cyprus",
      "#text": "Cyprus",
    },
    {
      value: "Czech Republic",
      "#text": "Czech Republic",
    },
    {
      value: "Denmark",
      "#text": "Denmark",
    },
    {
      value: "Djibouti",
      "#text": "Djibouti",
    },
    {
      value: "Dominica",
      "#text": "Dominica",
    },
    {
      value: "Dominican Republic",
      "#text": "Dominican Republic",
    },
    {
      value: "Ecuador",
      "#text": "Ecuador",
    },
    {
      value: "Egypt",
      "#text": "Egypt",
    },
    {
      value: "El Salvador",
      "#text": "El Salvador",
    },
    {
      value: "Equatorial Guinea",
      "#text": "Equatorial Guinea",
    },
    {
      value: "Eritrea",
      "#text": "Eritrea",
    },
    {
      value: "Estonia",
      "#text": "Estonia",
    },
    {
      value: "Ethiopia",
      "#text": "Ethiopia",
    },
    {
      value: "Falkland Islands (Malvinas)",
      "#text": "Falkland Islands (Malvinas)",
    },
    {
      value: "Faroe Islands",
      "#text": "Faroe Islands",
    },
    {
      value: "Fiji",
      "#text": "Fiji",
    },
    {
      value: "Finland",
      "#text": "Finland",
    },
    {
      value: "France",
      "#text": "France",
    },
    {
      value: "French Guiana",
      "#text": "French Guiana",
    },
    {
      value: "French Polynesia",
      "#text": "French Polynesia",
    },
    {
      value: "French Southern Territories",
      "#text": "French Southern Territories",
    },
    {
      value: "Gabon",
      "#text": "Gabon",
    },
    {
      value: "Gambia",
      "#text": "Gambia",
    },
    {
      value: "Georgia",
      "#text": "Georgia",
    },
    {
      value: "Germany",
      "#text": "Germany",
    },
    {
      value: "Ghana",
      "#text": "Ghana",
    },
    {
      value: "Gibraltar",
      "#text": "Gibraltar",
    },
    {
      value: "Greece",
      "#text": "Greece",
    },
    {
      value: "Greenland",
      "#text": "Greenland",
    },
    {
      value: "Grenada",
      "#text": "Grenada",
    },
    {
      value: "Guadeloupe",
      "#text": "Guadeloupe",
    },
    {
      value: "Guam",
      "#text": "Guam",
    },
    {
      value: "Guatemala",
      "#text": "Guatemala",
    },
    {
      value: "Guinea",
      "#text": "Guinea",
    },
    {
      value: "Guinea-Bissau",
      "#text": "Guinea-Bissau",
    },
    {
      value: "Guyana",
      "#text": "Guyana",
    },
    {
      value: "Haiti",
      "#text": "Haiti",
    },
    {
      value: "Heard Island and Mcdonald Islands",
      "#text": "Heard Island and Mcdonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      "#text": "Holy See (Vatican City State)",
    },
    {
      value: "Honduras",
      "#text": "Honduras",
    },
    {
      value: "Hong Kong",
      "#text": "Hong Kong",
    },
    {
      value: "Hungary",
      "#text": "Hungary",
    },
    {
      value: "Iceland",
      "#text": "Iceland",
    },
    {
      value: "India",
      "#text": "India",
    },
    {
      value: "Indonesia",
      "#text": "Indonesia",
    },
    {
      value: "Iran, Islamic Republic of",
      "#text": "Iran, Islamic Republic of",
    },
    {
      value: "Iraq",
      "#text": "Iraq",
    },
    {
      value: "Ireland",
      "#text": "Ireland",
    },
    {
      value: "Israel",
      "#text": "Israel",
    },
    {
      value: "Italy",
      "#text": "Italy",
    },
    {
      value: "Jamaica",
      "#text": "Jamaica",
    },
    {
      value: "Japan",
      "#text": "Japan",
    },
    {
      value: "Jordan",
      "#text": "Jordan",
    },
    {
      value: "Kazakhstan",
      "#text": "Kazakhstan",
    },
    {
      value: "Kenya",
      "#text": "Kenya",
    },
    {
      value: "Kiribati",
      "#text": "Kiribati",
    },
    {
      value: "Korea, Democratic People's Republic of",
      "#text": "Korea, Democratic People's Republic of",
    },
    {
      value: "Korea, Republic of",
      "#text": "Korea, Republic of",
    },
    {
      value: "Kuwait",
      "#text": "Kuwait",
    },
    {
      value: "Kyrgyzstan",
      "#text": "Kyrgyzstan",
    },
    {
      value: "Lao People's Democratic Republic",
      "#text": "Lao People's Democratic Republic",
    },
    {
      value: "Latvia",
      "#text": "Latvia",
    },
    {
      value: "Lebanon",
      "#text": "Lebanon",
    },
    {
      value: "Lesotho",
      "#text": "Lesotho",
    },
    {
      value: "Liberia",
      "#text": "Liberia",
    },
    {
      value: "Libyan Arab Jamahiriya",
      "#text": "Libyan Arab Jamahiriya",
    },
    {
      value: "Liechtenstein",
      "#text": "Liechtenstein",
    },
    {
      value: "Lithuania",
      "#text": "Lithuania",
    },
    {
      value: "Luxembourg",
      "#text": "Luxembourg",
    },
    {
      value: "Macao",
      "#text": "Macao",
    },
    {
      value: "Macedonia, the Former Yugoslav Republic of",
      "#text": "Macedonia, the Former Yugoslav Republic of",
    },
    {
      value: "Madagascar",
      "#text": "Madagascar",
    },
    {
      value: "Malawi",
      "#text": "Malawi",
    },
    {
      value: "Malaysia",
      "#text": "Malaysia",
    },
    {
      value: "Maldives",
      "#text": "Maldives",
    },
    {
      value: "Mali",
      "#text": "Mali",
    },
    {
      value: "Malta",
      "#text": "Malta",
    },
    {
      value: "Marshall Islands",
      "#text": "Marshall Islands",
    },
    {
      value: "Martinique",
      "#text": "Martinique",
    },
    {
      value: "Mauritania",
      "#text": "Mauritania",
    },
    {
      value: "Mauritius",
      "#text": "Mauritius",
    },
    {
      value: "Mayotte",
      "#text": "Mayotte",
    },
    {
      value: "Mexico",
      "#text": "Mexico",
    },
    {
      value: "Micronesia, Federated States of",
      "#text": "Micronesia, Federated States of",
    },
    {
      value: "Moldova, Republic of",
      "#text": "Moldova, Republic of",
    },
    {
      value: "Monaco",
      "#text": "Monaco",
    },
    {
      value: "Mongolia",
      "#text": "Mongolia",
    },
    {
      value: "Montserrat",
      "#text": "Montserrat",
    },
    {
      value: "Morocco",
      "#text": "Morocco",
    },
    {
      value: "Mozambique",
      "#text": "Mozambique",
    },
    {
      value: "Myanmar",
      "#text": "Myanmar",
    },
    {
      value: "Namibia",
      "#text": "Namibia",
    },
    {
      value: "Nauru",
      "#text": "Nauru",
    },
    {
      value: "Nepal",
      "#text": "Nepal",
    },
    {
      value: "Netherlands",
      "#text": "Netherlands",
    },
    {
      value: "Netherlands Antilles",
      "#text": "Netherlands Antilles",
    },
    {
      value: "New Caledonia",
      "#text": "New Caledonia",
    },
    {
      value: "New Zealand",
      "#text": "New Zealand",
    },
    {
      value: "Nicaragua",
      "#text": "Nicaragua",
    },
    {
      value: "Niger",
      "#text": "Niger",
    },
    {
      value: "Nigeria",
      "#text": "Nigeria",
    },
    {
      value: "Niue",
      "#text": "Niue",
    },
    {
      value: "Norfolk Island",
      "#text": "Norfolk Island",
    },
    {
      value: "Northern Mariana Islands",
      "#text": "Northern Mariana Islands",
    },
    {
      value: "Norway",
      "#text": "Norway",
    },
    {
      value: "Oman",
      "#text": "Oman",
    },
    {
      value: "Pakistan",
      "#text": "Pakistan",
    },
    {
      value: "Palau",
      "#text": "Palau",
    },
    {
      value: "Palestinian Territory, Occupied",
      "#text": "Palestinian Territory, Occupied",
    },
    {
      value: "Panama",
      "#text": "Panama",
    },
    {
      value: "Papua New Guinea",
      "#text": "Papua New Guinea",
    },
    {
      value: "Paraguay",
      "#text": "Paraguay",
    },
    {
      value: "Peru",
      "#text": "Peru",
    },
    {
      value: "Philippines",
      "#text": "Philippines",
    },
    {
      value: "Pitcairn",
      "#text": "Pitcairn",
    },
    {
      value: "Poland",
      "#text": "Poland",
    },
    {
      value: "Portugal",
      "#text": "Portugal",
    },
    {
      value: "Puerto Rico",
      "#text": "Puerto Rico",
    },
    {
      value: "Qatar",
      "#text": "Qatar",
    },
    {
      value: "Reunion",
      "#text": "Reunion",
    },
    {
      value: "Romania",
      "#text": "Romania",
    },
    {
      value: "Russian Federation",
      "#text": "Russian Federation",
    },
    {
      value: "Rwanda",
      "#text": "Rwanda",
    },
    {
      value: "Saint Helena",
      "#text": "Saint Helena",
    },
    {
      value: "Saint Kitts and Nevis",
      "#text": "Saint Kitts and Nevis",
    },
    {
      value: "Saint Lucia",
      "#text": "Saint Lucia",
    },
    {
      value: "Saint Pierre and Miquelon",
      "#text": "Saint Pierre and Miquelon",
    },
    {
      value: "Saint Vincent and the Grenadines",
      "#text": "Saint Vincent and the Grenadines",
    },
    {
      value: "Samoa",
      "#text": "Samoa",
    },
    {
      value: "San Marino",
      "#text": "San Marino",
    },
    {
      value: "Sao Tome and Principe",
      "#text": "Sao Tome and Principe",
    },
    {
      value: "Saudi Arabia",
      "#text": "Saudi Arabia",
    },
    {
      value: "Senegal",
      "#text": "Senegal",
    },
    {
      value: "Serbia and Montenegro",
      "#text": "Serbia and Montenegro",
    },
    {
      value: "Seychelles",
      "#text": "Seychelles",
    },
    {
      value: "Sierra Leone",
      "#text": "Sierra Leone",
    },
    {
      value: "Singapore",
      "#text": "Singapore",
    },
    {
      value: "Slovakia",
      "#text": "Slovakia",
    },
    {
      value: "Slovenia",
      "#text": "Slovenia",
    },
    {
      value: "Solomon Islands",
      "#text": "Solomon Islands",
    },
    {
      value: "Somalia",
      "#text": "Somalia",
    },
    {
      value: "South Africa",
      "#text": "South Africa",
    },
    {
      value: "South Georgia and the South Sandwich Islands",
      "#text": "South Georgia and the South Sandwich Islands",
    },
    {
      value: "Spain",
      "#text": "Spain",
    },
    {
      value: "Sri Lanka",
      "#text": "Sri Lanka",
    },
    {
      value: "Sudan",
      "#text": "Sudan",
    },
    {
      value: "Suriname",
      "#text": "Suriname",
    },
    {
      value: "Svalbard and Jan Mayen",
      "#text": "Svalbard and Jan Mayen",
    },
    {
      value: "Swaziland",
      "#text": "Swaziland",
    },
    {
      value: "Sweden",
      "#text": "Sweden",
    },
    {
      value: "Switzerland",
      "#text": "Switzerland",
    },
    {
      value: "Syrian Arab Republic",
      "#text": "Syrian Arab Republic",
    },
    {
      value: "Taiwan, Province of China",
      "#text": "Taiwan, Province of China",
    },
    {
      value: "Tajikistan",
      "#text": "Tajikistan",
    },
    {
      value: "Tanzania, United Republic of",
      "#text": "Tanzania, United Republic of",
    },
    {
      value: "Thailand",
      "#text": "Thailand",
    },
    {
      value: "Timor-Leste",
      "#text": "Timor-Leste",
    },
    {
      value: "Togo",
      "#text": "Togo",
    },
    {
      value: "Tokelau",
      "#text": "Tokelau",
    },
    {
      value: "Tonga",
      "#text": "Tonga",
    },
    {
      value: "Trinidad and Tobago",
      "#text": "Trinidad and Tobago",
    },
    {
      value: "Tunisia",
      "#text": "Tunisia",
    },
    {
      value: "Turkey",
      "#text": "Turkey",
    },
    {
      value: "Turkmenistan",
      "#text": "Turkmenistan",
    },
    {
      value: "Turks and Caicos Islands",
      "#text": "Turks and Caicos Islands",
    },
    {
      value: "Tuvalu",
      "#text": "Tuvalu",
    },
    {
      value: "Uganda",
      "#text": "Uganda",
    },
    {
      value: "Ukraine",
      "#text": "Ukraine",
    },
    {
      value: "United Arab Emirates",
      "#text": "United Arab Emirates",
    },
    {
      value: "United Kingdom",
      "#text": "United Kingdom",
    },
    {
      value: "United States",
      "#text": "United States",
    },
    {
      value: "United States Minor Outlying Islands",
      "#text": "United States Minor Outlying Islands",
    },
    {
      value: "Uruguay",
      "#text": "Uruguay",
    },
    {
      value: "Uzbekistan",
      "#text": "Uzbekistan",
    },
    {
      value: "Vanuatu",
      "#text": "Vanuatu",
    },
    {
      value: "Venezuela",
      "#text": "Venezuela",
    },
    {
      value: "Viet Nam",
      "#text": "Viet Nam",
    },
    {
      value: "Virgin Islands, British",
      "#text": "Virgin Islands, British",
    },
    {
      value: "Virgin Islands, U.s.",
      "#text": "Virgin Islands, U.s.",
    },
    {
      value: "Wallis and Futuna",
      "#text": "Wallis and Futuna",
    },
    {
      value: "Western Sahara",
      "#text": "Western Sahara",
    },
    {
      value: "Yemen",
      "#text": "Yemen",
    },
    {
      value: "Zambia",
      "#text": "Zambia",
    },
    {
      value: "Zimbabwe",
      "#text": "Zimbabwe",
    },
  ];


  export const replaceSpaceandtoLower = (val) => {
    return val.replace(/\s+/g, "-").toLowerCase();
  };

  export function callApi(appName) {
    // return Promise.resolve(staticResponse);
    const url = `http://localhost:2019/jumpstart/${appName}`;
  
    // const url = "/jumpstart/office-365";
  
    // console.log(process.env.GATSBY_APP_APPS_LIST);
    const options = {
      url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    return axios(options)
      .then(resp => resp.data)
      .catch(error => error);
  }