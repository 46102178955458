import React from "react";

export default function ChatChannel(props) {
  const additionalClass = props.additionalClass;
  const children = props.children;
  const additionalClassParent = props.additionalClassParent;
  return (
    <section
      className={`${"container_trial integrations_container"} ${
        additionalClass ? additionalClass : ""
      } ${additionalClassParent ? additionalClassParent : "" }`}
    >
      {" "}
      {children}{" "}
    </section>
  );
}
