import React from "react";

LeftImageWithContent.MainHeader = ({ children }) => {
  return <h2>{children}</h2>;
};

LeftImageWithContent.SubHeader = ({ children }) => {
  return <>{children}</>;
};
LeftImageWithContent.HeaderIcon = ({ children }) => {
  return <>{children}</>;
};
LeftImageWithContent.Link = ({ children }) => {
  return <>{children}</>;
};

export default function LeftImageWithContent({
  children,
  image,
  isFeature,
  altImage,
  bgColor,
  iD,
  singleImage,
  rightImage,
  rightImageMobile,
  ImageMob,
  additionalClassImageWidth,
}) {
  return (
    <section
      className={
        isFeature
          ? `${"features_content_left features_img_left pl-10-tage landing_page trial_page_img"} ${
              bgColor ? bgColor : ""
            }`
          : `${"features_img_left pl-10-tage landing_page trial_page_img"} ${
              bgColor ? bgColor : ""
            }`
      }
      id={iD ? iD : ""}
    >
      <div className="container">
        <div className="row">
          <div
            className={
              singleImage
                ? `${"col-md-6 feature_page_img_right single_iamge_wrapper"}`
                : `${"col-md-6 feature_page_img_right"}`
            }
          >
            {ImageMob ? (
              <picture>
                <source media="(max-width:768px)" srcSet={rightImageMobile} />
                <img src={rightImage} altImage={altImage} loading="lazy"></img>
              </picture>
            ) : (
              <img
                className=""
                src={image}
                loading="lazy"
                className={` ${
                  additionalClassImageWidth ? additionalClassImageWidth : ""
                }`}
                alt={altImage ? altImage : "trial"}
              />
            )}
          </div>
          <div className="col-md-6 feature_page_content_left">{children}</div>
        </div>
      </div>
    </section>
  );
}

// <h3>Why keep employees waiting on support.</h3>
// <p>
//   With Workativ, employees can solve their issues and requests
//   instantly with an AI and Automation powered chatbot.
// </p>
// }
// export function RightImage() {
//   return (
//     <section className="features_content_left pl-20-tage mt-0 landing_page trial_page_img bg_trial_page">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-6 feature_page_content_left">
//             <h3>Automate instantly. No coding. </h3>
//             <p>
//               Connect Workativ Assistant with your business’s apps and automate
//               workflows that perform actions with those applications. Be it
//               creating a ticket in Freshservice or onboarding a new user in your
//               HR systems, we’ve got you covered.
//             </p>
//             <p>
//               By the way, there are 100+ automations in marketplace and new ones
//               added each month.{" "}
//             </p>
//           </div>
//           <div className="col-md-6 feature_page_img_right ">
//             <img src={Image} alt="trial" />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
