import React from "react";
const Card = ({ image, header, content }) => {
  return (
    <div className=" card_landing_section">
      <div className="card_landing_trial">
        <img src={image} alt={header}/>
        {header && <h4>{header}</h4> }
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default function Cards({ cards }) {
  return (
    <section className="trial_cards_page">
      <div className="container">
        <div className="col-12 grid_card_trial">
          <div className="row">
            {cards.map(card => (
              <Card {...card} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
