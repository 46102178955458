import React from "react";

const Image = require("../../assets/images/test/trial_section2_img1.png");

RightImageWithContent.MainHeader = ({ children }) => {
  return <h2>{children}</h2>;
};

RightImageWithContent.SubHeader = ({ children }) => {
  return <>{children}</>;
};
RightImageWithContent.Header = ({ children }) => {
  return <>{children}</>;
};
RightImageWithContent.Link = ({ children }) => {
  return <>{children}</>;
};

export function RightImageWithContent({ children, image, video }) {
  return (
    <section className="features_content_left pl-20-tage mt-0 landing_page trial_page_img bg_trial_page integrations_page_form">
      <div className="container">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">{children}</div>
          <div className="col-md-6 feature_page_img_right ">
            <img src={image} alt="trial" />
          </div>
        </div>
      </div>
    </section>
  );
}

RightImageWithContentFeature.MainHeader = ({ children }) => {
  return <>{children}</>;
};

RightImageWithContentFeature.SubHeader = ({ children }) => {
  return <>{children}</>;
};
RightImageWithContentFeature.Header = ({ children }) => {
  return <>{children}</>;
};
RightImageWithContentFeature.Link = ({ children }) => {
  return <>{children}</>;
};

export default function RightImageWithContentFeature({
  children,
  image,
  isFeature,
  altImage,
  bgColor,
  iD,
  rightImage,
  rightImageMobile,
  ImageMob,
  singleImage,
  additionalClass,
  additionalClassImageWidth,
}) {
  return (
    <section
      className={
        isFeature
          ? `${"features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page"} ${
              bgColor ? bgColor : ""
            }`
          : `${"features_img_left pl-10-tage landing_page trial_page_img bg_trial_page"} ${
              bgColor ? bgColor : ""
            } ${additionalClass ? additionalClass : ""}`
      }
      id={iD ? iD : ""}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">{children}</div>
          <div
            className={
              singleImage
                ? `${"col-md-6 feature_page_img_right single_iamge_wrapper"}`
                : `${"col-md-6 feature_page_img_right"}`
            }
          >
            {ImageMob ? (
              <picture>
                <source media="(max-width:768px)" srcSet={rightImageMobile} />
                <img src={rightImage} altImage={altImage} loading="lazy"></img>
              </picture>
            ) : (
              <img
                className=""
                src={image}
                loading="lazy"
                className={` ${
                  additionalClassImageWidth ? additionalClassImageWidth : ""
                }`}
                alt={altImage ? altImage : "trial"}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
