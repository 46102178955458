import React from "react";

export default function FooterForm(props) {
  return (
    <section className="footer_form">
      <div className="container">
        <div className="row">
          <div className="col-md-12 footer_form_container">
            <div className="footer_form_left col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="main-slider-left">
                <h3 className="font-section-sub-header">Ready to get started?</h3>
                <h5 className="font-section-sub-header-small">Pre-built bots and app workflows for FREE</h5>
                <p className="font-section-normal-text pr-0">Start on our free plan, and scale up as you grow.</p>
              </div>
            </div>
            <div className="footer_form_right col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="trial_header_left">
                <div className="main-slider-button beta-button pt-4">
                  <form className="sign_up_form" name="form">
                    <input
                      type="text"
                      id="beta-name-footer"
                      onkeyup="handlechangeFooter(event)"
                      name="firstname"
                      placeholder="Name"
                      required
                    />
                    <input
                      type="text"
                      id="beta-email-footer"
                      onkeyup="handlechangeFooter(event)"
                      name="lastname"
                      placeholder="Email"
                      required
                    />
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      onclick="createContectFooter(event)"
                      id="register-for-trail-footer"
                      className="btn btn-primary disabled_button"
                    >
                      Signup
                    </button>
                  </form>
                  {props.AutomateCTA ?  <div className="form_below_content signup_cta">
        <span>Easy setup.</span>
        <span>No credit card. </span>
        <span>Onboarding included.</span>
      </div> :
      <div className="form_below_content signup_cta ">
        <span>Easy setup.</span>
        <span>No credit card. </span>
        <span>Onboarding included.</span>
      </div>
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
