import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import Cards from "../../components/chat-channel/Cards";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { RequestFormModal } from "../../components/modalform";
import { useMedia } from "use-media";

import { RequestFormWithOnlyButton } from "../../components/form/form";
import {
  useScrollPosition,
  useDisableBodyScroll,
  useUniqueId,
  useShareSessionStorge,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from "../../components/useDisableBodyScroll";

const TopImage = require("../../assets/automate/workflow_automation/workflow_automation_header.png");

const PopUpRight = require("../../assets/automate/home/automate_home_header.png");
const LogoImg = require("../../assets/images/popup/popup_assistant_logo.svg");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

const noCoding = require("../../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../../assets/images/landing_page/bottom_icon_3.png");

const section1 = require("../../assets/automate/workflow_automation/workflow_automation_sec_1.png");
const section2 = require("../../assets/automate/workflow_automation_sec_2.png");
const section3 = require("../../assets/automate/workflow_automation/workflow_automation_sec_3.png");
const section4 = require("../../assets/automate/workflow_automation/workflow_automation_sec_4.png");
const section5 = require("../../assets/automate/workflow_automation/workflow_automation_sec_5.png");
const section6 = require("../../assets/automate/workflow_automation/workflow_automation_sec_6.png");
const section7 = require("../../assets/automate/home/automate_img_4.png");
const section8 = require("../../assets/automate/workflow_automation/workflow_automation_sec_8.png");
const section9 = require("../../assets/automate/workflow_automation/workflow_automation_sec_9.png");
const section10 = require("../../assets/automate/workflow_automation/workflow_automation_sec_10.png");

const featureContent = [
  {
    header: "Workflow designer canvas",
    content:
      "Use workativ’s no-code canvas to build simple single step or complex multistep workflows easily. Setting conditions for workflow execution, execute parallel actions, or combine data using join capabilities – we got you covered. Workativ’s workflow designer is simple and easy to use three step canvas making it easy for just anybody to get going in minutes.",
    image: section1,
    alt: "No-code Workflow Builder",
  },
  {
    header: "App integrations",
    content:
      "Workativ provides pre-built app integrations so you don’t have to custom develop. Connect with your apps securely in few clicks as workativ supports industry leading authentications such as OAuth, Basic Auth, and Custom Auth to support various app authentication needs.",
    image: section2,
    alt: "app integration with workflow automation",
  },
  {
    header: "Application triggers",
    content:
      "Workativ provides several ways to trigger workflows; whether it is setting up event triggers to auto-respond to app-related events or polling triggers to repeatedly check for changes periodically, at regular intervals. Also, you can call workflows using our webhooks from an external app or a chatbot easily.",
    image: section3,
    alt: "workflow triggers and automations",
    link: "More on triggers",
    url: "/",
  },
  {
    header: "Workflow marketplace",
    content:
      "Workativ provides 1000s of pre-built workflow recipes from our marketplace. Connect your apps and use workflows in seconds. Access our workflow marketplace to choose from over 50+ apps and 400+ actions and 1000s of workflow recipes. Easily download, connect, and activate in few clicks. No coding.",
    image: section4,
    alt: "workflow recipe marketplace",
    link: "Go to workflow marketplace",
    url: "/",
  },
  {
    header: "On-prem app connector",
    content:
      "On-prem Connect is a secure connector to help you connect workativ to your on-prem applications behind your firewall seamlessly and securely without opening ports, so you can create and automate workflows between on-prem and cloud apps easily. Installing on-prem connector and connecting apps is easy and no coding required at all.",
    image: section6,
    alt: "connect cloud with on-prem app",
    link: "More on on-prem connect",
    url: "/on-prem-connect",
  },
  {
    header: "Service orchestration",
    content:
      "Workativ uses powerful service orchestration engine to orchestrate multiple tasks through multiple apps and deliver true workflow automation. Using one-click app integration, you can seamlessly automate tasks, process and workflows spanning several apps instantly.",
    image: section5,
    alt: "Multi-step workflows",
    link: "More on workflow automation",
    url: "/on-prem-connect",
  },
  {
    header: "Dashboard analytics",
    content:
      "Workativ offers detailed analytics on both product usage and workflows as well. Dashboard offers various data points on product effectiveness such as – most run workflows, requests auto resolved, staff hours saved, cost saving etc.",
    image: section7,
    alt: "workflow automation dashboard",
  },
  {
    header: "Error handling",
    content:
      "Workativ’s advanced error handling will help you handle errors that may occur during workflow executions. And you may set conditions, and or notifications that will help take next best workflow action to either overcome and move forward or report to the admin.",
    image: section8,
    alt: "advanced error handling  in workflow automation",
  },
  {
    header: "Workflow testing",
    content:
      "Testing is critical to workflows to ensure they work at each step. Using workativ’s testing capability you can test workflows with live data at every step of the workflow to ensure your workflows are in order.",
    image: section9,
    alt: "workfow testing",
  },
  {
    header: "Logs & debugging",
    content:
      "Set email notifications when a workflow fails, and debug failed workflows with ease without the need for developers. Take corrective actions using detailed logs with meaningful error messages that are easy to understand.",
    image: section10,
    alt: "logs and debugging for workflow automation software",
  },
];

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content: `Connect chatbot with your on-prem app using our  <span>
    <a href='/conversational-ai-platform/on-prem-app-connector'>secure connector</a>
  </span> so you can create workflows to automate tasks from chatbot without a hitch, be it on-prem or cloud.`,
    image: boostIT,
  },
];

export default function DialogDesigner() {
  return (
    <>
      <TitleAndMetaTags
        title="No-code Workflow Builder for Free | Workativ Automate"
        description="Use our free no-code workflow builder to connect with apps and automate workflows and process in minutes. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
      />
      <Container>
        <div className="automate_center_wrapper automate_product">
          <Layout
            backgroundColor={"bg_feature"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <TopFormWithImage
              image={TopImage}
              altImage={"no-code workflow automation platform"}
              product="automate"
            >
              <TopFormWithImage.Header>
                Workflow Automation Platform
              </TopFormWithImage.Header>
              <TopFormWithImage.Content>
                Workativ’s easy to use no-code workflow automation platform
                helps you build simple to complex workflows easily in minutes.
                Explore powerful workflow designer with triggers, webhooks, app
                integrations, pre-built workflow marketplace and much more.
              </TopFormWithImage.Content>
              <RequestForm
                product="automate"
                AutomateCTA
                autoamteButton
                isFooterForm={false}
              />
            </TopFormWithImage>
            {/* <ScrollPopUpForAutomatePages /> */}
            {featureContent.map((feature, index) => {
              const isFeature = index === 0;
              if (index % 2 == 0) {
                return (
                  <RightImageWithContentFeature
                    isFeature={isFeature}
                    ImageMob
                    rightImage={feature.image}
                    rightImageMobile={feature.imagemob}
                    image={feature.image}
                    altImage={feature.alt}
                  >
                    <RightImageWithContentFeature.Header>
                      <h3>
                        <span>{feature.header}</span>
                      </h3>
                    </RightImageWithContentFeature.Header>

                    <RightImageWithContentFeature.SubHeader>
                      <p>{feature.content}</p>
                    </RightImageWithContentFeature.SubHeader>
                    {/* <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </RightImageWithContentFeature.Link> */}
                  </RightImageWithContentFeature>
                );
              }
              return (
                <div className="features_container_left">
                  <LeftImageWithContent
                    ImageMob
                    rightImage={feature.image}
                    rightImageMobile={feature.imagemob}
                    image={feature.image}
                    altImage={feature.alt}
                  >
                    <LeftImageWithContent.HeaderIcon>
                      <h3>{feature.header}</h3>
                    </LeftImageWithContent.HeaderIcon>

                    <LeftImageWithContent.SubHeader>
                      <p>{feature.content}</p>
                    </LeftImageWithContent.SubHeader>
                    {/* <LeftImageWithContent.Link>
                  {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                    </LeftImageWithContent.Link> */}
                  </LeftImageWithContent>
                </div>
              );
            })}

            <section className="center_image_home cards_landing_page">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3>Your data is yours and you control it</h3>
                      <p>
                        Workativ has implemented robust security processes and
                        controls that are in compliance with industry-leading
                        standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Cards cards={cardData} />
            </section>

            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="We need your feedback, inputs, and experience to help us build a great product!"
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
              />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
}

export function ScrollPopUpForAutomatePages({ scrollPercentageToShowPopup }) {
  const isSmall = useMedia({ maxWidth: "520px" });

  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  const AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
    "AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY";

  const POPUP_EXPIRY_IN_MINUTES = 5 * 60 * 1000;
  // const POPUP_EXPIRY_IN_MINUTES = 1 * 10 * 10;

  // console.log("AutoPopUp", AutoPopUp);

  const percentToShowPopup = scrollPercentageToShowPopup
    ? scrollPercentageToShowPopup
    : 50;

  const [popupVisiblity, setPopupVisblity] = useState(false);

  const [closedOnce, setCloesedOnce] = useState(false);

  const [makeThankyouVisible, setMakeThankyouVisible] = useState("hide");

  const scrollPercentage = useScrollPosition();

  const uniqueId = useUniqueId();

  function closeThankyouModal() {
    setMakeThankyouVisible("hide");
    window.history.pushState(
      {},
      "",
      window.location.origin + window.location.pathname
    );
  }

  const storedKey = getLocalStorageWithExpiry(
    AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
  );

  const sessionPopupShownOnce =
    storedKey == null || storedKey == undefined || storedKey == ""
      ? "not-shown"
      : `shown::${storedKey.componentId}`;

  const flogtoShowPopupBasedOnSession =
    sessionPopupShownOnce === `shown::${uniqueId}` ||
    sessionPopupShownOnce === "not-shown";

  useDisableBodyScroll(
    popupVisiblity && flogtoShowPopupBasedOnSession && !closedOnce
  );

  const closePopup = (event) => {
    setPopupVisblity(false);
    setCloesedOnce(true);
  };

  useEffect(() => {
    if (scrollPercentage > percentToShowPopup) {
      setPopupVisblity(true);
    }
  }, [scrollPercentage]);

  React.useEffect(() => {
    if (sessionPopupShownOnce === "not-shown" && popupVisiblity) {
      setLocalStorageWithExpiry(
        AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
        { route: window.location.href, componentId: uniqueId },
        POPUP_EXPIRY_IN_MINUTES
      );
    }
  }, [popupVisiblity]);

  const toShowPopup =
    flogtoShowPopupBasedOnSession && popupVisiblity && !closedOnce;

  // console.log(
  //   "toShowPopup",
  //   toShowPopup,
  //   flogtoShowPopupBasedOnSession,
  //   popupVisiblity,
  //   !closedOnce
  // );

//   return (<>
//     {plan == "" ? null : (
//       <RequestFormModal
//         setPlan={setPlan}
//         plan={plan}
//         showThanksPage={showThanksPage}
//         setShowThanksPage={setShowThanksPage}
//       />)}
//   </>)
// }

return toShowPopup ? (
  <>
    <section className="popup-background automate_popup new_automate_popup">
      <div class="container">
        <div className="row">
          <div class="col-md-11 col-sm-12 m-auto">
            <div className="close-circle cursor-pointer">
              <a class="close cursor-pointer" onClick={closePopup}>
                &#9447;
              </a>
            </div>
            <div class="row popup popup-assistants popup_automate_update_23">
              <div class="content-left-popup col-sm-12 col-md-7">
                <h3 className="font-section-sub-header-bold mb-0">
                Automate your tasks, now!
                </h3>
                <h4 className="font-section-normal-text mb-3">
                  Have a repetitive process? Automate it in minutes.<br></br>{" "}
                  No coding required.
                </h4>
                <RequestForm
                  product="automate"
                  type="popup"
                  isFooterForm={false}
                  onRegisterSuccessCallback={closePopup}
                  needSecondForm={true}
                />
                {isSmall ? (
                  <h5
                    className="font-section-small-text"
                    style={{ marginTop: "20px" }}
                  >
                    No credit card required.<br></br> No strings attached.
                  </h5>
                ) : (
                  <h5
                    className="font-section-small-text"
                    style={{ marginTop: "20px" }}
                  >
                    No credit card required. No strings attached.
                  </h5>
                )}

                <div className="line_or_pop">
                  <hr className="seperator-pop-demo hl-left"></hr>
                  <span className="font-section-normal-text">OR</span>
                  <hr className="seperator-pop-demo hl-right"></hr>
                </div>
                <div className="demo-container-pop">
                  <button
                    className="popup-demo font-section-small-text font-size-reduce-pop"
                    onClick={() =>
                      window.open(
                        "https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    <span>BOOK DEMO</span>
                  </button>
                </div>
              </div>
              <div className="content-right-popup col-md-5">
                <img src={PopUpRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
) : null;
}
