import React, { useEffect, useState } from "react";
import {
  CookieConsentProvider,
  useCookieConsentContext,
} from "@use-cookie-consent/react";
import Cookies from 'js-cookie'

export const useCookieBanner = () => {

  const [_triggerRender, setTriggerRender] = React.useState(Math.random());
  const {
    acceptAllCookies,
    acceptCookies,
    consent,
  } = useCookieConsentContext();

  const discardCookieSaving = () => {
    console.log("DOING :: discardCookieSaving called");
    var in30Sec = new Date(new Date().getTime() + 0.5 * 60 * 1000);
    Cookies.set('cookie-banner-dismissed', new Date().toISOString(), {
      expires: in30Sec
    });
    setTriggerRender(Math.random());
  }

  const wasCookieBannerWasDiscarded = Cookies.get('cookie-banner-dismissed') ? true : false;

  const showCookieConfiguration = Object.keys(consent).length <= 1 && !wasCookieBannerWasDiscarded;

  React.useEffect(() => {
    console.log("consent", consent);
    if (consent.thirdParty) {
      Cookies.set("gatsby-gdpr-google-tagmanager", true);
    } else {
      Cookies.set("gatsby-gdpr-google-tagmanager", false);
    }
  }, [consent]);


  return {
    acceptAllCookies,
    acceptCookies,
    showCookieConfiguration,
    consent,
    discardCookieSaving
  };
};