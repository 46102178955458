import React, { useState, useReducer } from "react";
import styled, { css } from "styled-components";
import { DropdownArrow, UpArrowIcon } from "../components/common/Icons";
import { useMedia } from "use-media";
import automateLogo from "../assets/images/workativ-automate-logo.svg";
import assistantLogo from "../assets/images/workativ-assistant-logo.svg";

import workativ_assistant_full from "../assets/images/header/assistant.svg";
import workativ_automate_full from "../assets/images/header/automate.svg";

import wlogo from "../assets/images/workativ-logo.png";
import alogo from "../assets/images/assistant-logo.png";
// import smf from "../assets/images/footer_icons/facebook.svg";
// import sml from "../assets/images/footer_icons/linkedin.svg";
// import smt from "../assets/images/footer_icons/twitter.svg";
// import smy from "../assets/images/footer_icons/youtube.svg";

import {
  Youtube,
  LinkedIn,
  Twitter,
  FaceBook,
} from "../components/common/Icons";

import awsLogo from "../assets/images/aws.png";
import { title } from "process";
import { render } from "react-dom";

const FooterWrapper = styled.section`
  width: 100%;
  float: left;
  padding: 60px 0px;
  background: #f5f5f5;
  @media (max-width: 767px) {
    padding: 0px;
  }
`;
export const Container = styled.div`
  @media (max-width: 767px) {
    padding: 0px;
  }
`;
export const Row = styled.div`
  padding: 0px 15px;

  @media (max-width: 767px) {
    padding: 0px;
    margin: 0px;
  }

  ${(props) =>
    props.socialWrapper &&
    css`
      dispaly: flex;
      justify-content: space-between;
    `}
`;

const Logo = styled.img`
  width: 140px;
  height: 100%;
  float: left;
  @media (max-width: 767px) {
    width: 180px;
    height: auto;
  }
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    width: 280px;
  }
`;
const FooterLinkWrapper = styled.div`
  width: 100%;
  float: left;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .footer_right_mobile.automate_footer .footer_aws {
    display: none;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
  }
  @media (max-width: 992px) {
    flex-direction: row;
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0px;
    .footer_right_mobile.automate_footer:first-child {
      order: 2;
    }
    .footer_right_mobile.automate_footer:first-child .footer_aws {
      display: block;
      margin: 0 auto;
    }
    .footer_right_mobile.automate_footer:first-child .footer_aws img {
      padding: 40px 0px;
      top: 0px;
    }
  }
`;
const FooterLink = styled.div`
  width: 100%;
  float: left;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const WrapperLink = styled.div`
  width: 100%;
  float: left;
  .w-100.position-relative {
    max-height: 720px;
  }
  .w-100.position-absolute {
    width: 100%;
    height: 100%;
    bottom: 0px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 0px;
  }
`;
const Header = styled.h4`
  text-align: left;
  font-size: 15px;
  line-height: 18px;
  font-weight: normal;
  color: #333333;
  margin-bottom: 12px;
  font-family: "Gordita-Medium";
  @media (max-width: 1024px) {
    margin-bottom: 15px;
    margin-top: 10px;
    width: auto;
    float: left;
  }
  @media (max-width: 767px) {
    margin: 0px;
    padding: 0px 20px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c1c1c1;
    background: #f8f8f8;
    font-size: 16px;
    color: #000;
  }
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 16px;
  }
  @media (min-width: 2058px) and (max-width: 2560px) {
    font-size: 26px !important;
  }
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  width: 100%;
  float: left;
`;

const Link = styled.a`
  font-weight: normal;
  color: #4e5257;
  width: auto;
  float: left;
  cursor: pointer;
  font-family: "Gordita-Regular";
  font-size: 0.82rem;
  display: inline-flex;
  padding-bottom: 10px;
  line-height: inherit;
  text-decoration: none;
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 16px;
  }
  @media (min-width: 2058px) and (max-width: 2560px) {
    font-size: 1.4rem !important;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 60px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 40px;
    font-size: 14px;
    font-family: "Gordita-Medium";
    color: #000;
  }
  :hover {
    color: #333;
    text-decoration: underline;
  }
  ${(props) =>
    props.primary &&
    css`
      text-align: left;
      font-size: 15px;
      line-height: 18px;
      font-weight: normal;
      color: #333333;
      margin-bottom: 12px;
      font-family: "Gordita-Medium";
      @media (min-width: 1800px) and (max-width: 2560px) {
        font-size: 16px;
      }
    `}
  @media (max-width:1024px) {
    ${(props) =>
      props.primary &&
      css`
        margin-bottom: 0px;
      `}
  }
  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
      color: #333;
    `}
`;
const FooterLogo = styled.div`
  width: 100%;
  float: left;
`;

const WrapperBottom = styled.div`
  width: 100%;
  float: left;
  padding-top:  ${(props) => (props.noPadding ? "0px" : "20px")};

  .w-100.position-relative{
    max-height:720px;
  }
  .w-100.position-absolute{
    width:100%;
    height:100%;
    bottom:0px;
  }

  @media (max-width: 1024px) {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  @media (max-width: 992px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    ali
  }
`;

const AwsImage = styled.img`
  width: 100%;
  height: auto;
  float: left;
  @media (max-width: 1024px) {
    width: 200px;
  }
  @media (max-width: 767px) {
    width: 185px;
  }
`;
const DropdownIcon = styled.span`
  width: 18px;
  height: 18px;
  float: right;
  fill: #333;
  display: none;
  @media (max-width: 767px) {
    display: block;
    width: 13px;
    height: 13px;
    margin-left: 7px;
  }
`;
const MobLogo = styled.div`
  display: none;
  margin-bottom: 15px;
  width: 100%;
  float: left;
  @media (max-width: 767px) {
    display: block;
    margin: 0px;

    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      border-bottom: 1px solid #c1c1c1;
      background: #f8f8f8;
      padding: 0px 20px;
    }
  }
`;
const DesktopLogo = styled.div`
  display: block;
  width: 100%;
  float: left;
  @media (max-width: 767px) {
    display: none;
  }
  ${FooterLogo} {
    padding-top: 20px;
  }
`;

const MiddleDiv = styled.div`
  width: 100%;
  float: left;
  @media (max-width: 1024px) {
    ${WrapperLink} {
      padding-bottom: 0px;
    }
  }
`;

const openCloseReducer = (headerList, action) => {
  // console.log(headerList, action)
  if (action.type == "toggle") {
    return headerList.map((list) => {
      if (list.title === action.header) {
        return { ...list, show: !list.show };
      }
      return { ...list, show: false };
    });
  }
};

export default function Footer(props) {
  // Common footer
  const footerCommonList = [
    {
      title: "Products",
      index: 0,
      workativLogo: true,
      links: [
        {
          text: "Workativ Assistant",
          link: "/conversational-ai-platform",
        },
        {
          text: "Workativ Automate",
          link: "/workflow-automation-platform",
        },
      ],
    },
    {
      title: "Company",
      index: 0,
      links: [
        {
          text: "Careers",
          link: "/careers",
        },
        {
          text: "Partners",
          link: "/partner",
        },
        {
          text: "Contact Us",
          link: "/contact-us",
        },
        {
          text: "Security",
          link: "/security",
        },
      ],
    },
  ];
  // Workativ footer
  const footerList = [
    {
      title: "",
      index: 1,
      workativLogo: true,
      links: [],
    },
    {
      title: "",
      index: 2,
      links: [],
    },

    {
      title: "Products",
      index: 3,
      links: [
        {
          text: "Workativ Assistant",
          link: "/conversational-ai-platform",
        },
        {
          text: "Workativ Automate",
          link: "/workflow-automation-platform",
        },
      ],
    },
    {
      title: "Company",
      index: 4,
      links: [
        {
          text: "Careers",
          link: "/careers",
        },
        {
          text: "Partners",
          link: "/partner",
        },
        {
          text: "Contact Us",
          link: "/contact-us",
        },
        {
          text: "Security",
          link: "/security",
        },
      ],
    },
    {
      title: "",
      index: 5,
      links: [],
    },
    {
      title: "",
      awsLogo: true,
      index: 6,
      links: [],
    },
  ];

  //Assistant Footer
  const footerAssistantList = [
    {
      title: "Top Features",
      index: 1,
      workativLogo: true,
      links: [
        {
          text: "Conversational AI Platform​",
          link: "/conversational-ai-platform/conversational-ai",
        },
        {
          text: "Chatbot Builder ",
          link: "/conversational-ai-platform/chatbot-builder",
        },
        {
          text: "Chatbot Automation​ (RPA)",
          link: "/conversational-ai-platform/chatbot-automation",
        },
        {
          text: "Knowledge AI",
          link: "/conversational-ai-platform/knowledge-ai",
          new: true,
        },
        {
          text: "Shared Inbox",
          link: "/conversational-ai-platform/shared-inbox",
          comingsoon: true,
        },
        {
          text: "Chatbot Templates",
          link: "/conversational-ai-platform/chatbot-marketplace-templates",
        },
        {
          text: "FAQ Builder",
          link: "/conversational-ai-platform/faq-chatbot-builder",
        },
        {
          text: "Ominichannel",
          link: "/conversational-ai-platform/chatbot-channels",
        },
        {
          text: "Chatbot Analytics",
          link: "/conversational-ai-platform/chatbot-analytics-performance",
        },
        {
          text: "Live Agent Handover",
          link: "/conversational-ai-platform/agent-handover-chatbot",
        },
        {
          text: "Approval Management",
          link: "/conversational-ai-platform/approval-management-chatbot",
        },
        {
          text: "App Connector",
          link: "/conversational-ai-platform/on-prem-app-connector",
        },
        {
          text: "All Features​",
          link: "/conversational-ai-platform/features",
          underline: true,
        },
      ],
    },
    // {
    //   title: "How it works",
    //   headerAsLink: "/conversational-ai-platform/how-it-works",
    //   index: 1,
    //   links: []
    // },

    {
      title: "Chatbot Usecases",
      index: 2,
      links: [
        {
          text: "Virtual Agent​",
          link: "/conversational-ai-platform/virtual-agent",
        },
        {
          text: "IT Helpdesk Chatbot",
          link: "/conversational-ai-platform/it-helpdesk-chatbot",
        },
        {
          text: "HR Chatbot",
          link: "/conversational-ai-platform/hr-chatbot",
        },

        {
          text: "AI Self Service",
          link: "/conversational-ai-platform/ai-self-service-chatbot",
        },

        {
          text: "Conversational AI Chatbot​",
          link: "/conversational-ai-platform/conversational-ai-bot",
        },
      ],
    },

    {
      title: "Chatbot Channels",
      index: 2,
      links: [
        {
          text: "Slack",
          link: "/conversational-ai-platform/slack-chatbot",
        },
        {
          text: "MS Teams",
          link: "/conversational-ai-platform/ms-teams-bot",
        },
        {
          text: "Chat Widget",
          link: "/conversational-ai-platform/chatbot-widget",
        },
      ],
    },
    {
      title: "Pricing",
      headerAsLink: "/conversational-ai-platform/pricing",
      index: 2,
      links: [],
      class: "pricing_footer_color",
    },
    {
      title: "Chatbot Integrations",
      index: 3,
      links: [
        {
          text: "ServiceNow",
          link: "/conversational-ai-platform/servicenow-chatbot",
        },
        {
          text: "BambooHR",
          link: "/conversational-ai-platform/bamboohr-chatbot",
        },
        {
          text: "Workday",
          link: "/conversational-ai-platform/workday-chatbot",
        },
        {
          text: "Okta",
          link: "/conversational-ai-platform/okta-chatbot",
        },
        {
          text: "Azure Active Directory",
          link: "/conversational-ai-platform/active-directory-chatbot",
        },
        {
          text: "SolarWinds",
          link: "/conversational-ai-platform/solarwinds-chatbot",
        },
        {
          text: "JAMF",
          link: "/conversational-ai-platform/jamf-chatbot",
        },
        {
          text: "Jira Service Management",
          link: "/conversational-ai-platform/jira-service-desk-chatbot",
        },
        {
          text: "Gusto",
          link: "/conversational-ai-platform/gusto-chatbot",
        },
        {
          text: "Salesforce Identity",
          link: " /conversational-ai-platform/salesforce-identity-chatbot",
        },
        {
          text: "Twilio",
          link: "/conversational-ai-platform/twilio-chatbot",
        },
        {
          text: "Box",
          link: "/conversational-ai-platform/box-chatbot",
        },
        {
          text: "Office 365",
          link: "/conversational-ai-platform/office-365-chatbot",
        },
        {
          text: "All Integrations",
          link: "/conversational-ai-platform/integrations",
          underline: true,
        },
      ],
    },
    {
      title: "Marketplace",
      index: 4,
      links: [
        {
          text: "Pre-built Chatbots​",
          link:
            "/conversational-ai-platform/pre-built-chatbot-template-marketplace",
        },
        {
          text: "App Workflows for Chatbot​",
          link: "/conversational-ai-platform/app-workflow-template-marketplace",
        },
      ],
    },
    {
      title: "Popular Workflows",
      index: 4,
      links: [
        {
          text: "Unlock Account",
          link: "/use-cases/unlock-account-automation",
        },
        {
          text: "Password Reset",
          link: "/use-cases/reset-password-automation",
        },
        {
          text: "Employee Onboarding",
          link: "/use-cases/employee-onboarding-automation",
        },
        {
          text: "User Provisioning",
          link: "/use-cases/user-provisioning-automation",
        },
        {
          text: "Payroll Enquiry",
          link: "/use-cases/payroll-enquiry-automation",
        },
        {
          text: " Leave Management",
          link: "/use-cases/leave-management-automation",
        },
        {
          text: "Employee Offboarding",
          link: "/use-cases/employee-offboarding-automation",
        },
        {
          text: " AI Ticketing",
          link: "/use-cases/ai-ticketing",
        },
      ],
    },

    {
      title: "Resources",
      index: 5,
      awsLogo: true,
      links: [
        {
          text: "Customer Success Stories",
          link:
            "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation",
        },
        {
          text: "Videos",
          link: "/conversational-ai-platform/chatbot-videos",
        },
        {
          text: "Blog",
          link: "https://workativ.com/blog",
        },
        // {
        //   text: "ROI Calculator",
        //   link: "/conversational-ai-platform/chatbot-roi-calculator",
        // },
        {
          text: "Whitepaper",
          link: "/conversational-ai-platform/chatbot-whitepaper",
        },
        {
          text: "All Resources",
          link: "/conversational-ai-platform/resources",
          underline: true,
        },

        {
          text: "Book Demo",
          link: "https://calendly.com/workativ/workativ-assistant-demo-page",
          class: "last_demo_footer",
        },
      ],
    },
    {
      title: "Compare",
      index: 5,

      links: [
        {
          text: "IBM Watson Assistant Alternative",
          link: "/conversational-ai-platform/ibm-watson-assistant-alternative",
        },
        {
          text: "Microsoft Power Virtual Agent Alternative",
          link:
            "/conversational-ai-platform/microsoft-power-virtual-agent-alternative",
        },
      ],
    },
  ];

  //Automate Footer
  const footerAutomateList = [
    {
      title: "Products",
      index: 1,
      workativLogo: true,
      links: [
        {
          text: "Workativ Assistant",
          link: "/conversational-ai-platform",
        },
        {
          text: "Workativ Automate",
          link: "/workflow-automation-platform",
        },
      ],
    },
    {
      title: "Company",
      index: 1,
      links: [
        {
          text: "Careers",
          link: "/careers",
        },
        {
          text: "Partners",
          link: "/partner",
        },
        {
          text: "Contact Us",
          link: "/contact-us",
        },
        {
          text: "Security",
          link: "/security",
        },
      ],
    },
    {
      title: "Product​​",
      index: 2,
      autoamteImg: true,
      links: [
        {
          text: "Features",
          link: "/workflow-automation-platform/workflow-automation-feature",
        },
      ],
    },
    //

    {
      title: "Pricing",
      headerAsLink: "/workflow-automation-platform/pricing",
      index: 2,
      links: [
        // {
        //   text: "Book Demo",
        //   link: "/workflow-automation-platform/demo",
        //   class: "last_demo_footer"
        // },
      ],
    },
    {
      title: "Book Demo",
      type: "button",
      headerAsLink:
        "https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/",
      index: 2,
      class: "last_demo_footer",
      links: [],
    },
    {
      title: "Workflows by Apps​",
      index: 3,
      comingSoon: false,
      links: [
        {
          text: "MS Intune",
          link: "/workflow-automation-platform/integrations/microsoft-intune",
        },
        {
          text: "Okta",
          link: "/workflow-automation-platform/integrations/okta",
        },
        {
          text: "BambooHR",
          link: "/workflow-automation-platform/integrations/bamboohr",
        },
        {
          text: "Azure Active Directory",
          link: "workflow-automation-platform/integrations/microsoft-azure-ad",
        },
        {
          text: "Ivanti",
          link: "/workflow-automation-platform/integrations/ivanti",
        },
        {
          text: "Zoho People",
          link: "/workflow-automation-platform/integrations/zoho-people",
        },
        {
          text: "OrangeHRM",
          link: "/workflow-automation-platform/integrations/orangehrm",
        },
        {
          text: "Gusto",
          link: "/workflow-automation-platform/integrations/gusto",
        },
        {
          text: "Duo Security",
          link: "/workflow-automation-platform/integrations/duo-security",
        },
        {
          text: "Jumpcloud",
          link: "/workflow-automation-platform/integrations/jumpcloud",
        },
        {
          text: "All Apps",
          link: "/workflow-automation-platform/integrations",
          underline: true,
        },
      ],
    },
    {
      title: "Popular Apps",
      index: 4,
      links: [
        {
          text: "ServiceNow",
          link: "/workflow-automation-platform/integrations/servicenow",
        },
        {
          text: "Jira",
          link: "/workflow-automation-platform/integrations/jira",
        },
        {
          text: "Slack",
          link: "/workflow-automation-platform/integrations/slack",
        },
        {
          text: "Twilio",
          link: "/workflow-automation-platform/integrations/twilio",
        },
        {
          text: "Google Calendar",
          link: "/workflow-automation-platform/integrations/google-calendar",
        },
        {
          text: "MS Teams",
          link: "/workflow-automation-platform/integrations/microsoft-teams",
        },
        {
          text: "Outlook",
          link: "/workflow-automation-platform/integrations/outlook",
        },
        {
          text: "Sharepoint",
          link: "/conversational-ai-platform/sharepoint-chatbot",
        },
        {
          text: "Freshdesk",
          link: "/conversational-ai-platform/freshdesk-chatbot",
        },
        {
          text: " Google Drive",
          link: "/conversational-ai-platform/google-drive-chatbot",
        },
        {
          text: "All Apps",
          link: "/workflow-automation-platform/integrations",
          underline: true,
        },
      ],
    },
    {
      title: "Popular Integrations",
      index: 5,
      links: [
        {
          text: "MS Azure AD + ServiceNow",
          link:
            "/workflow-automation-platform/integrations/microsoft-azure-ad+servicenow",
        },
        {
          text: "GDrive + MS Intune",
          link:
            "/workflow-automation-platform/integrations/google-drive+microsoft-intune",
        },
        {
          text: "BambooHR + Gusto",
          link: "/workflow-automation-platform/integrations/bamboohr+gusto",
        },
        {
          text: "​​ServiceNow + Outlook",
          link: "/workflow-automation-platform/integrations/servicenow+outlook",
        },
        {
          text: "Jira + MS Intune",
          link:
            "/workflow-automation-platform/integrations/jira-service-management+microsoft-intune",
        },
        {
          text: "Gusto + Netsuite",
          link: "/workflow-automation-platform/integrations/gusto+netsuite",
        },
        {
          text: "BambooHR + MS Azure AD",
          link:
            "/workflow-automation-platform/integrations/bamboohr+microsoft-azure-ad",
        },
        {
          text: "ServiceNow + MS Intune",
          link:
            "/workflow-automation-platform/integrations/servicenow+microsoft-intune",
        },
        {
          text: "Gusto + Google Calendar",
          link:
            "/workflow-automation-platform/integrations/gusto+google-calendar",
        },
        {
          text: "Jira + MS Teams",
          link:
            "/workflow-automation-platform/integrations/jira-service-management+microsoft-teams",
        },
        {
          text: "All Integration",
          link: "/workflow-automation-platform/integrations",
          underline: true,
        },
      ],
    },

    {
      title: "Resources",
      index: 6,
      awsLogo: true,
      hasLeftPadding: true,
      links: [
        {
          text: "Blog",
          link: "https://workativ.com/blog",
        },
        // {
        //   text: "ROI Calculator",
        //   link: "/workflow-automation-platform/chatbot-roi-calculator",
        // },

        // {
        //   text: "Help",
        //   link: "https://help.workativ.com",
        // },
      ],
    },
  ];

  const product = props.product ? props.product : "ASSISTANT";

  const commonList = footerCommonList.map((x) => ({ ...x, show: false }));

  const productList =
    product == "WORKATIV"
      ? footerList.map((x) => ({ ...x, show: false }))
      : product == "AUTOMATE"
      ? footerAutomateList.map((x) => ({ ...x, show: false }))
      : footerAssistantList.map((x) => ({ ...x, show: false }));

  const getLogoComponent = (logoof) => {
    const headerImagesWithAltText = {
      ASSISTANT: {
        image: workativ_assistant_full,
        alt: "Workativ Assistant",
        href: "/conversational-ai-platform",
      },
      AUTOMATE: {
        image: workativ_automate_full,
        alt: "Workativ Automate",
        href: "/workflow-automation-platform",
      },
      WORKATIV: {
        image: wlogo,
        alt: "Workativ",
        href: "/",
      },
    };

    const logoOfProduct = logoof ? logoof : "WORKATIV";

    const logoData = headerImagesWithAltText[logoOfProduct]
      ? headerImagesWithAltText[logoOfProduct]
      : headerImagesWithAltText["WORKATIV"];

    return <Logo src={logoData.image} alt={logoData.alt} />;
  };
  const href = (product) => {
    if (product === "ASSISTANT") return "/conversational-ai-platform";
    else if (product === "WORKATIV") return "/";
    else if (product === "AUTOMATE") return "/workflow-automation-platform";
    else return "/";
  };
  const dropDownList = commonList.concat(productList);

  const isSmallScreen = useMedia({ maxWidth: "767px" });
  const [showHideList, setShowHideList] = useState(dropDownList);

  const [sectionList, dispatch] = useReducer(openCloseReducer, dropDownList);

  const updateShowList = (headerToToggle, event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // console.log("event happening", event)
    dispatch({ type: "toggle", header: headerToToggle });
  };

  let divideArray = (arr) => {
    const [head, ...tail] = arr;

    if (tail.length > 1) {
      return {
        first: head,
        middle: tail.slice(0, tail.length - 1),
        last: tail[tail.length - 1],
      };
    }

    return {
      first: head,
      middle: [],
      last: tail.length > 0 ? tail[tail.length - 1] : null,
    };
  };

  const renderLinksLeft = () => {
    const list = [0];
    return list.map((value, index) => {
      const filtertedList = sectionList.filter((item) => item.index === value);

      const { first, middle, last } = divideArray(filtertedList);

      const firstHeight = `${first.links * 60}px`;
      // const [first, second] = filtertedList;
      return (
        <>
          {isSmallScreen ? (
            <LastMenuWrapper className="footerlink_left footerlink_left_mobile">
              <FooterLink key={index}>
                <>
                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      <div>
                        {(first.show || !isSmallScreen) &&
                          first.links.map((item) => (
                            <Li key={item.link.toString() + Math.random()}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </div>
                    </Ul>
                  </WrapperLink>
                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className={`footer_menu ${item.class}`}
                                href={item.headerAsLink}
                              >
                                <Header>
                                  {item.title}
                                  {/* <DropdownIcon>
                                {item.show ? (
                                  <UpArrowIcon />
                                ) : (
                                  <DropdownArrow />
                                )}
                              </DropdownIcon> */}
                                </Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              {(item.show || !isSmallScreen) &&
                                item.links.map((it) => (
                                  <Li key={it.link.toString() + Math.random()}>
                                    <Link
                                      className={`url_manipulation ${item.class}`}
                                      underline={it.underline ? true : false}
                                      href={it.link}
                                    >
                                      {it.text}
                                    </Link>
                                  </Li>
                                ))}
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>
                  {last && (
                    <WrapperBottom>
                      {last.headerAsLink ? (
                        <a className="footer_menu" href={last.headerAsLink}>
                          <Header>{last.title}</Header>
                        </a>
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        {(last.show || !isSmallScreen) &&
                          last.links.map((item, index) => (
                            <Li key={index}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </Ul>
                    </WrapperBottom>
                  )}

                  <div className="aws_logo_mob">
                    <WrapperBottom className="WrapperBottom">
                      <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                    </WrapperBottom>
                  </div>
                </>
              </FooterLink>
            </LastMenuWrapper>
          ) : (
            <LastMenuWrapper className="footerlink_left">
              <FooterLink key={index}>
                <>
                  {first.workativLogo && (
                    <MobLogo>
                      <FooterLogo>
                        <Link href="/" className="url_manipulation">
                          <Logo src={wlogo} alt="workativ"></Logo>
                        </Link>
                      </FooterLogo>
                    </MobLogo>
                  )}

                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      {(first.show || !isSmallScreen) &&
                        first.links.map((item) => (
                          <Li key={item.link.toString() + Math.random()}>
                            <Link
                              className={`url_manipulation ${item.class}`}
                              underline={item.underline ? true : false}
                              href={item.link}
                            >
                              {item.text}{" "}
                              {item.new ? (
                                <sup className="footer-new-launch font-section-small-signup-form">
                                  Beta
                                </sup>
                              ) : null}
                              {item.comingsoon ? (
                                <sup className="footer-new-launch-coming-soon font-section-small-signup-form">
                                  Coming soon
                                </sup>
                              ) : null}
                            </Link>
                          </Li>
                        ))}
                    </Ul>
                  </WrapperLink>
                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className={`footer_menu ${item.class}`}
                                href={item.headerAsLink}
                              >
                                <Header>
                                  {item.title}
                                  {/* <DropdownIcon>
                              {item.show ? (
                                <UpArrowIcon />
                              ) : (
                                <DropdownArrow />
                              )}
                            </DropdownIcon> */}
                                </Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              {(item.show || !isSmallScreen) &&
                                item.links.map((it) => (
                                  <Li key={it.link.toString() + Math.random()}>
                                    <Link
                                      className={`url_manipulation ${item.class}`}
                                      underline={it.underline ? true : false}
                                      href={it.link}
                                    >
                                      {it.text}
                                    </Link>
                                  </Li>
                                ))}
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>
                  {last && (
                    <WrapperBottom>
                      {last.headerAsLink ? (
                        <a className="footer_menu" href={last.headerAsLink}>
                          <Header>{last.title}</Header>
                        </a>
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        {console.log(last.links)}
                        {(last.show || !isSmallScreen) &&
                          last.links.map((item, index) => (
                            <Li key={index}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </Ul>
                    </WrapperBottom>
                  )}

                  {first.awsLogo && (
                    <WrapperBottom>
                      <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                    </WrapperBottom>
                  )}

                  {first.workativLogo && (
                    <DesktopLogo>
                      <WrapperBottom>
                        <FooterLogo className="footerlogo_left">
                          <Link href="/" className="url_manipulation">
                            <Logo src={wlogo} alt="workativ"></Logo>
                          </Link>
                        </FooterLogo>
                      </WrapperBottom>
                    </DesktopLogo>
                  )}
                </>
              </FooterLink>
            </LastMenuWrapper>
          )}
        </>
      );
    });
  };
  const renderLinksWorkativ = () => {
    const list = [1, 2, 3, 4, 5, 6];
    return list.map((value, index) => {
      const filtertedList = sectionList.filter((item) => item.index === value);

      const { first, middle, last } = divideArray(filtertedList);
      // const [first, second] = filtertedList;
      return (
        <LastMenuWrapper className="footer_home">
          <FooterLink key={index}>
            <>
              {first.workativLogo && (
                <MobLogo>
                  <FooterLogo>
                    <Link href="/" className="url_manipulation">
                      <Logo src={wlogo} alt="workativ"></Logo>
                    </Link>
                  </FooterLogo>
                </MobLogo>
              )}
              <WrapperLink className="wrapperlink">
                {first.headerAsLink ? (
                  <a className="footer_menu" href={first.headerAsLink}>
                    <Header>
                      {first.title}
                      <DropdownIcon>
                        {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                      </DropdownIcon>
                    </Header>
                  </a>
                ) : (
                  <Header
                    onClick={(event) => updateShowList(first.title, event)}
                  >
                    {first.title}
                    <DropdownIcon>
                      {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                    </DropdownIcon>
                  </Header>
                )}
                <Ul>
                  {(first.show || !isSmallScreen) &&
                    first.links.map((item) => (
                      <Li key={item.link.toString() + Math.random()}>
                        <Link
                          className={`url_manipulation ${item.class}`}
                          underline={item.underline ? true : false}
                          href={item.link}
                        >
                          {item.text}
                        </Link>
                      </Li>
                    ))}
                </Ul>
              </WrapperLink>
              <MiddleDiv>
                {middle.length > 0 ? (
                  <WrapperBottom>
                    {middle.map((item) => (
                      <WrapperLink>
                        {item.headerAsLink ? (
                          <a className="footer_menu" href={item.headerAsLink}>
                            <Header>
                              {item.title}
                              {/* <DropdownIcon>
                                {item.show ? (
                                  <UpArrowIcon />
                                ) : (
                                  <DropdownArrow />
                                )}
                              </DropdownIcon> */}
                            </Header>
                          </a>
                        ) : (
                          <Header
                            onClick={(event) =>
                              updateShowList(item.title, event)
                            }
                          >
                            {item.title}
                            <DropdownIcon>
                              {item.show ? <UpArrowIcon /> : <DropdownArrow />}
                            </DropdownIcon>
                          </Header>
                        )}

                        <Ul>
                          {(item.show || !isSmallScreen) &&
                            item.links.map((it) => (
                              <Li key={it.link.toString() + Math.random()}>
                                <Link
                                  className={`url_manipulation ${item.class}`}
                                  underline={it.underline ? true : false}
                                  href={it.link}
                                >
                                  {it.text}
                                </Link>
                              </Li>
                            ))}
                        </Ul>
                      </WrapperLink>
                    ))}
                  </WrapperBottom>
                ) : null}
              </MiddleDiv>
              {last && (
                <WrapperBottom>
                  {last.headerAsLink ? (
                    <a className="footer_menu" href={last.headerAsLink}>
                      <Header>{last.title}</Header>
                    </a>
                  ) : (
                    <Header
                      onClick={(event) => updateShowList(last.title, event)}
                    >
                      {last.title}
                      <DropdownIcon>
                        {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                      </DropdownIcon>
                    </Header>
                  )}
                  <Ul>
                    {(last.show || !isSmallScreen) &&
                      last.links.map((item, index) => (
                        <Li key={index}>
                          <Link
                            className={`url_manipulation ${item.class}`}
                            underline={item.underline ? true : false}
                            href={item.link}
                          >
                            {item.text}
                          </Link>
                        </Li>
                      ))}
                  </Ul>
                </WrapperBottom>
              )}

              {first.awsLogo && (
                <WrapperBottom className="footer_aws">
                  <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                </WrapperBottom>
              )}

              {first.workativLogo && (
                <DesktopLogo>
                  <WrapperBottom>
                    <FooterLogo className="footerlogo_left">
                      <Link href="/" className="url_manipulation">
                        <Logo
                          className="home_img_footer"
                          src={wlogo}
                          alt="workativ"
                        ></Logo>
                      </Link>
                    </FooterLogo>
                  </WrapperBottom>
                </DesktopLogo>
              )}
            </>
          </FooterLink>
        </LastMenuWrapper>
      );
    });
  };
  const renderLinksAutomate = () => {
    const renderButtonOrLink = (link) => {
      console.log("LINK :::: ", link);
      if (link.type === "button") {
        return (
          <Link
            className={`url_manipulation ${link.class}`}
            underline={link.underline ? true : false}
            href={link.headerAsLink.trim()}
          >
            {link.title}
          </Link>
        );
      }

      return (
        <a className="footer_menu" href={link.headerAsLink}>
          <Header>{link.title} ss</Header>
        </a>
      );
    };
    const list = [1, 2, 3, 4, 5, 6];
    return list.map((value, index) => {
      const filtertedList = sectionList.filter((item) => item.index === value);

      const { first, middle, last } = divideArray(filtertedList);
      // const [first, second] = filtertedList;
      // console.log("render links automate", first, middle, last)
      return (
        <>
          {isSmallScreen ? (
            <LastMenuWrapper className="footer_right footer_right_mobile automate_footer">
              <FooterLink key={index}>
                <>
                  {first.autoamteImg && (
                    <div className="autoamte_img">
                      <DesktopLogo>
                        <FooterLogo className="autoamte_imglogo">
                          <Link
                            href="/workflow-automation-platform"
                            className="url_manipulation"
                          >
                            <Logo src={automateLogo} alt="automate"></Logo>
                          </Link>
                        </FooterLogo>
                      </DesktopLogo>
                    </div>
                  )}
                  {first.autoamteImg && (
                    <div className="autoamte_img">
                      <MobLogo className="automate_img_logo">
                        <FooterLogo className="autoamte_imglogo">
                          <Link
                            href="/workflow-automation-platform"
                            className="url_manipulation"
                          >
                            <Logo src={automateLogo} alt="automate"></Logo>
                          </Link>
                        </FooterLogo>
                      </MobLogo>
                    </div>
                  )}

                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      {(first.show || !isSmallScreen) &&
                        first.links.map((item) => (
                          <Li key={item.link.toString() + Math.random()}>
                            <Link
                              className={`url_manipulation ${item.class}`}
                              underline={item.underline ? true : false}
                              href={item.link.trim()}
                            >
                              {item.text}
                            </Link>
                          </Li>
                        ))}
                    </Ul>
                  </WrapperLink>

                  {first.comingSoon ? (
                    <div className="footer_menu coming_soon_center">
                      <Header>
                        <div className="coming_soon_wrapper">
                          <span className="coming_soon">Coming soon</span>
                        </div>
                      </Header>
                    </div>
                  ) : null}

                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className="footer_menu"
                                href={item.headerAsLink}
                              >
                                <Header>{item.title}</Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              {(item.show || !isSmallScreen) &&
                                item.links.map((it) => (
                                  <Li key={it.link.toString() + Math.random()}>
                                    <Link
                                      className={`url_manipulation ${item.class}`}
                                      underline={it.underline ? true : false}
                                      href={it.link}
                                    >
                                      {it.text}
                                    </Link>
                                  </Li>
                                ))}
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>

                  {last && (
                    <WrapperBottom>
                      {last.headerAsLink ? (
                        <a className="footer_menu" href={last.headerAsLink}>
                          <Header>{last.title}</Header>
                        </a>
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        {(last.show || !isSmallScreen) &&
                          last.links.map((item, index) => (
                            <Li key={index}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </Ul>
                    </WrapperBottom>
                  )}

                  <WrapperBottom className="footer_aws">
                    <AwsAutomate>
                      <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                    </AwsAutomate>
                  </WrapperBottom>

                  {first.workativLogo && (
                    <DesktopLogo>
                      <WrapperBottom className="pt-0 pb-0 footerlogo_left">
                        <FooterLogo>
                          <Link href="/" className="url_manipulation">
                            <Logo src={wlogo} alt="workativ"></Logo>
                          </Link>
                        </FooterLogo>
                      </WrapperBottom>
                    </DesktopLogo>
                  )}
                </>
              </FooterLink>
            </LastMenuWrapper>
          ) : (
            <LastMenuWrapper className="footer_right automate_footer">
              <FooterLink
                key={index}
                className={
                  first.hasLeftPadding ? "footer_last_column_padding" : ""
                }
              >
                <>
                  {first.workativLogo && (
                    <MobLogo>
                      <FooterLogo>
                        <Link href="/" className="url_manipulation ">
                          <Logo src={wlogo} alt="workativ"></Logo>
                        </Link>
                      </FooterLogo>
                      {/* <FooterLogo>
              <Link href={href(product)} className="url_manipulation">
                {getLogoComponent(product)}
                </Link>
              </FooterLogo> */}
                    </MobLogo>
                  )}
                  {first.autoamteImg && (
                    <div className="autoamte_img footer_automate_logo_23">
                      <DesktopLogo>
                        <FooterLogo className="autoamte_imglogo">
                          <Link
                            href="/workflow-automation-platform"
                            className="url_manipulation"
                          >
                            <Logo src={automateLogo} alt="automate"></Logo>
                          </Link>
                        </FooterLogo>
                      </DesktopLogo>
                    </div>
                  )}
                  {first.autoamteImg && (
                    <div className="autoamte_img">
                      <MobLogo className="automate_img_logo">
                        <FooterLogo className="autoamte_imglogo">
                          <Link
                            href="/workflow-automation-platform"
                            className="url_manipulation"
                          >
                            <Logo src={automateLogo} alt="automate"></Logo>
                          </Link>
                        </FooterLogo>
                      </MobLogo>
                    </div>
                  )}

                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      {(first.show || !isSmallScreen) &&
                        first.links.map((item) => (
                          <Li key={item.link.toString() + Math.random()}>
                            <Link
                              className={`url_manipulation ${
                                item.class ? item.class : ""
                              }`}
                              underline={item.underline ? true : false}
                              href={item.link.trim()}
                            >
                              {item.text}
                            </Link>
                          </Li>
                        ))}
                    </Ul>
                  </WrapperLink>

                  {first.comingSoon ? (
                    <div className="footer_menu coming_soon_center">
                      <Header>
                        <div className="coming_soon_wrapper">
                          <span className="coming_soon">Coming soon</span>
                        </div>
                      </Header>
                    </div>
                  ) : null}

                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className="footer_menu"
                                href={item.headerAsLink}
                              >
                                <Header>{item.title}</Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              {(item.show || !isSmallScreen) &&
                                item.links.map((it) => (
                                  <Li key={it.link.toString() + Math.random()}>
                                    <Link
                                      className={`url_manipulation ${item.class}`}
                                      underline={it.underline ? true : false}
                                      href={it.link}
                                    >
                                      {it.text}
                                    </Link>
                                  </Li>
                                ))}
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>

                  {last && (
                    <WrapperBottom
                      noPadding={last.headerAsLink && last.type === "button"}
                    >
                      {last.headerAsLink ? (
                        renderButtonOrLink(last)
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        {(last.show || !isSmallScreen) &&
                          last.links.map((item, index) => (
                            <Li key={index}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </Ul>
                    </WrapperBottom>
                  )}

                  {first.awsLogo && (
                    <WrapperBottom className="footer_aws">
                      <AwsAutomate>
                        <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                      </AwsAutomate>
                    </WrapperBottom>
                  )}
                  {first.workativLogo && (
                    <DesktopLogo>
                      <WrapperBottom className="pt-0 pb-0 footerlogo_left">
                        <FooterLogo>
                          <Link href="/" className="url_manipulation">
                            <Logo src={wlogo} alt="workativ"></Logo>
                          </Link>
                        </FooterLogo>
                      </WrapperBottom>
                    </DesktopLogo>
                  )}
                </>
              </FooterLink>
            </LastMenuWrapper>
          )}
        </>
      );
    });
  };
  const isWorkativFooter = product == "WORKATIV";
  const isAutomateFooter = product == "AUTOMATE";

  //renderlinksRight
  const renderLinksRight = () => {
    const list = [1, 2, 3, 4, 5];
    return list.map((value, index) => {
      const filtertedList = sectionList.filter((item) => item.index === value);

      const { first, middle, last } = divideArray(filtertedList);
      const firstHeight = `${first.links * 60}px`;
      const middleHeight = `${first.links * 60}px`;
      const lastHeight = `${first.links * 60}px`;

      // const [first, second] = filtertedList;
      return (
        <>
          {isSmallScreen ? (
            <LastMenuWrapper className="footer_right footer_right_mobile">
              <FooterLink key={index}>
                <>
                  {first.workativLogo && (
                    <MobLogo>
                      <FooterLogo>
                        <Link href={href(product)} className="url_manipulation">
                          {getLogoComponent(product)}
                        </Link>
                      </FooterLogo>
                    </MobLogo>
                  )}
                  {first.workativLogo && (
                    <DesktopLogo>
                      <WrapperBottom className="pt-0 pb-0">
                        <FooterLogo className="pt-0">
                          <Link
                            href={href(product)}
                            className="url_manipulation"
                          >
                            {getLogoComponent(product)}
                          </Link>
                        </FooterLogo>
                      </WrapperBottom>
                    </DesktopLogo>
                  )}
                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      <div>
                        {(first.show || !isSmallScreen) &&
                          first.links.map((item) => (
                            <Li
                              className="no_children_li_footer"
                              key={item.link.toString() + Math.random()}
                            >
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                                {item.new ? (
                                  <sup className="footer-new-launch font-section-small-signup-form">
                                    Beta
                                  </sup>
                                ) : null}
                                {item.comingsoon ? (
                                  <sup className="footer-new-launch-coming-soon font-section-small-signup-form">
                                    Coming soon
                                  </sup>
                                ) : null}
                              </Link>
                            </Li>
                          ))}
                      </div>
                    </Ul>
                  </WrapperLink>
                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className={`footer_menu ${item.class}`}
                                href={item.headerAsLink}
                              >
                                <Header>
                                  {item.title}
                                  {/* <DropdownIcon>
                                {item.show ? (
                                  <UpArrowIcon />
                                ) : (
                                  <DropdownArrow />
                                )}
                              </DropdownIcon> */}
                                </Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              <div>
                                {(item.show || !isSmallScreen) &&
                                  item.links.map((it) => (
                                    <Li
                                      key={it.link.toString() + Math.random()}
                                    >
                                      <Link
                                        className={`url_manipulation ${item.class}`}
                                        underline={it.underline ? true : false}
                                        href={it.link}
                                      >
                                        {it.text}
                                      </Link>
                                    </Li>
                                  ))}
                              </div>
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>
                  {last && (
                    <WrapperBottom>
                      {last.headerAsLink ? (
                        <a
                          className={`footer_menu ${last.class}`}
                          href={last.headerAsLink}
                        >
                          <Header>{last.title}</Header>
                        </a>
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        <div>
                          {(last.show || !isSmallScreen) &&
                            last.links.map((item, index) => (
                              <Li key={index}>
                                <Link
                                  className={`url_manipulation ${item.class}`}
                                  underline={item.underline ? true : false}
                                  href={item.link}
                                >
                                  {item.text}
                                </Link>
                              </Li>
                            ))}
                        </div>
                      </Ul>
                    </WrapperBottom>
                  )}

                  {/* {first.awsLogo && (
                    <WrapperBottom>
                      <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                    </WrapperBottom>
                  )} */}
                </>
              </FooterLink>
            </LastMenuWrapper>
          ) : (
            <LastMenuWrapper className="footer_right pl-20px">
              <FooterLink key={index}>
                <>
                  {first.workativLogo && (
                    <MobLogo>
                      <FooterLogo>
                        <Link href={href(product)} className="url_manipulation">
                          {getLogoComponent(product)}
                        </Link>
                      </FooterLogo>
                    </MobLogo>
                  )}
                  {first.workativLogo && (
                    <DesktopLogo>
                      <WrapperBottom className="pt-0 pb-0">
                        <FooterLogo className="pt-0">
                          <Link
                            href={href(product)}
                            className="url_manipulation"
                          >
                            {getLogoComponent(product)}
                          </Link>
                        </FooterLogo>
                      </WrapperBottom>
                    </DesktopLogo>
                  )}
                  <WrapperLink className="wrapperlink">
                    {first.headerAsLink ? (
                      <a className="footer_menu" href={first.headerAsLink}>
                        <Header>
                          {first.title}
                          <DropdownIcon>
                            {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      </a>
                    ) : (
                      <Header
                        onClick={(event) => updateShowList(first.title, event)}
                      >
                        {first.title}
                        <DropdownIcon>
                          {first.show ? <UpArrowIcon /> : <DropdownArrow />}
                        </DropdownIcon>
                      </Header>
                    )}
                    <Ul>
                      {(first.show || !isSmallScreen) &&
                        first.links.map((item) => (
                          <Li key={item.link.toString() + Math.random()}>
                            <Link
                              className={`url_manipulation ${item.class}`}
                              underline={item.underline ? true : false}
                              href={item.link}
                            >
                              {item.text}
                            </Link>
                            {item.new ? (
                              <sup className="footer-new-launch font-section-small-signup-form">
                                Beta
                              </sup>
                            ) : null}
                            {item.comingsoon ? (
                              <sup className="footer-new-launch-coming-soon font-section-small-signup-form">
                                Coming soon
                              </sup>
                            ) : null}
                          </Li>
                        ))}
                    </Ul>
                  </WrapperLink>
                  <MiddleDiv>
                    {middle.length > 0 ? (
                      <WrapperBottom>
                        {middle.map((item) => (
                          <WrapperLink>
                            {item.headerAsLink ? (
                              <a
                                className={`footer_menu ${item.class}`}
                                href={item.headerAsLink}
                              >
                                <Header>
                                  {item.title}
                                  {/* <DropdownIcon>
                              {item.show ? (
                                <UpArrowIcon />
                              ) : (
                                <DropdownArrow />
                              )}
                            </DropdownIcon> */}
                                </Header>
                              </a>
                            ) : (
                              <Header
                                onClick={(event) =>
                                  updateShowList(item.title, event)
                                }
                              >
                                {item.title}
                                <DropdownIcon>
                                  {item.show ? (
                                    <UpArrowIcon />
                                  ) : (
                                    <DropdownArrow />
                                  )}
                                </DropdownIcon>
                              </Header>
                            )}

                            <Ul>
                              {(item.show || !isSmallScreen) &&
                                item.links.map((it) => (
                                  <Li key={it.link.toString() + Math.random()}>
                                    <Link
                                      className={`url_manipulation ${item.class}`}
                                      underline={it.underline ? true : false}
                                      href={it.link}
                                    >
                                      {it.text}
                                    </Link>
                                  </Li>
                                ))}
                            </Ul>
                          </WrapperLink>
                        ))}
                      </WrapperBottom>
                    ) : null}
                  </MiddleDiv>
                  {last && (
                    <WrapperBottom>
                      {last.headerAsLink ? (
                        <a
                          className={`footer_menu ${last.class}`}
                          href={last.headerAsLink}
                        >
                          <Header>{last.title}</Header>
                        </a>
                      ) : (
                        <Header
                          onClick={(event) => updateShowList(last.title, event)}
                        >
                          {last.title}
                          <DropdownIcon>
                            {last.show ? <UpArrowIcon /> : <DropdownArrow />}
                          </DropdownIcon>
                        </Header>
                      )}
                      <Ul>
                        {(last.show || !isSmallScreen) &&
                          last.links.map((item, index) => (
                            <Li key={index}>
                              <Link
                                className={`url_manipulation ${item.class}`}
                                underline={item.underline ? true : false}
                                href={item.link}
                              >
                                {item.text}
                              </Link>
                            </Li>
                          ))}
                      </Ul>
                    </WrapperBottom>
                  )}

                  {first.awsLogo && (
                    <WrapperBottom>
                      <AwsImage src={awsLogo} alt="footer_aws"></AwsImage>
                    </WrapperBottom>
                  )}
                </>
              </FooterLink>
            </LastMenuWrapper>
          )}
        </>
      );
    });
  };

  // const renderLinks = isWorkativFooter ? renderLinksWorkativ() : renderLinksLeft().concat(renderLinksRight());

  const renderLinks = isWorkativFooter
    ? renderLinksWorkativ()
    : isAutomateFooter
    ? renderLinksAutomate()
    : renderLinksLeft().concat(renderLinksRight());
  // console.log("renderLinks",renderLinks);

  return (
    <>
      <FooterWrapper className="paid_ad_remove_footer">
        <Container className="container">
          <Row className="row">
            <FooterLinkWrapper>{renderLinks}</FooterLinkWrapper>
          </Row>
        </Container>
      </FooterWrapper>
      <FooterSocial className="paid_ad_remove_footer">
        <Container className="container">
          <Row socialWrapper className="row">
            <LeftContent>
              Workativ Copyrights ©{" "}
              <ContentSpanYear>{new Date().getFullYear()}</ContentSpanYear>. All
              rights reserved.
            </LeftContent>
            <CenterContent>
              <LeftContent>
                <Link className="url_manipulation" href="/terms">
                  Terms of Use
                </Link>
              </LeftContent>
              <LeftContent>
                <span className="pad-left-15">|</span>
              </LeftContent>
              <LeftContent>
                <Link className="url_manipulation" href="/privacy">
                  Privacy Policy
                </Link>
              </LeftContent>
              <LeftContent>
                <span className="pad-left-15">|</span>
              </LeftContent>
              <LeftContent>
                <Link className="url_manipulation" href="/data-privacy-addendum">
                  DPA
                </Link>
              </LeftContent>
            </CenterContent>

            <SocialIcons>
              <ContentSpan>Follow us:</ContentSpan>
              <IconsWeb>
                <Link
                  className="url_manipulation"
                  href="https://www.facebook.com/workativ"
                >
                  <FaceBook />
                </Link>
                <Link
                  className="url_manipulation"
                  href="https://twitter.com/workativ"
                >
                  <Twitter />
                </Link>
                <Link
                  className="url_manipulation"
                  href="https://www.linkedin.com/company/workativ-com"
                >
                  <LinkedIn />
                </Link>
                <Link
                  className="url_manipulation"
                  href="https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg"
                >
                  <Youtube />
                </Link>
              </IconsWeb>
            </SocialIcons>
          </Row>
        </Container>
      </FooterSocial>
    </>
  );
}

const LeftContent = styled.div`
  width: auto;
  float: left;
  font-weight: normal;
  color: #333;
  display: block;
  font-size: 13px;
  font-family: "Gordita-Regular";
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    font-size: 20px;
  }
  ${Link} {
    margin-left: 15px;
    padding-bottom: 0px;
  }
  @media (max-width: 767px) {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
    ${Link} {
      margin-left: 15px;
      padding-bottom: 0px;
      padding-left: 0px;
      font-family: "Gordita-Regular";
      font-size: 14px;
      color: #333;
    }
  }
`;
const CenterContent = styled.div`
  width: auto;
  float: left;
  font-weight: normal;
  color: #333;
  display: block;
  font-size: 13px;
  font-family: "Gordita-Regular";

  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    ${LeftContent} {
      width: auto;
      display: flex;
    }
    ${Link}:first-child {
      margin-left: 0px;
      height: auto;
      border: none;
      padding-right: 0px;
    }
  }
`;
const ContentSpan = styled.span`
  width: auto;
  float: left;
  font-weight: normal;
  color: #4e5257;
  display: block;
  font-size: 13px;
  font-family: "Gordita-Regular";
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const SocialIcons = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  @media (max-width: 375px) {
    width: 100%;
    float: left;
    margin-bottom: 5px;
    justify-content: center;
    margin-top: 5px;
  }
`;
const IconsWeb = styled.span`
  width: auto;
  height: auto;

  ${Link} {
    margin-left: 7px;
    padding-bottom: 0px;
    width: 15px;
    height: 15px;
    @media screen and (min-width: 2058px) and (max-width: 2560px) {
      width: 23px;
      height: 23px;
    }
    @media (max-width: 767px) {
      padding: 0px;
      border: none;

      & svg {
        fill: #000000;
        color: #000000;
      }
    }

    &:nth-child(1):hover {
      color: #405b95;
    }
    &:nth-child(2):hover {
      color: #68ace9;
    }
    &:nth-child(3):hover {
      color: #2e76ad;
    }
    &:nth-child(4):hover {
      color: #d53e34;
    }
  }
  & svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    width: auto;
    height: auto;
    display: flex;
    border: none;
  }
`;

const ContentSpanYear = styled.span`
  font-weight: normal;
  color: #4e5257;
  font-size: 13px;
  font-family: "Gordita-Regular";
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const LastMenuWrapper = styled.div`
  width: 100%;
  float: left;
  :last-child ${FooterLink} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  ${AwsImage} {
    position: relative;
    bottom: 0px;
  }
  @media (max-width: 1024px) {
    width: 33.33%;
    ${AwsImage} {
      top: 30px;
      position: relative;
    
    }
    @media (max-width: 992px) {
      width: 50%;
      display: flex;
      align-self: flex-start;
      ${AwsImage} {
        position: relative;
        bottom: 0px;
              }
  }
  @media (max-width: 767px) {
    width: 100%;
    ${AwsImage} {
      position: relative;
      bottom: 0px;
      padding-top: 40px;
      padding-bottom: 20px;
      width: 200px;
            }
  }
  @media (max-width: 520px) {
    width: 100%;
    
}
`;

const AwsAutomate = styled.div`
width:100%;
float:left;
display: flex;
justify-content: flex-start;
${AwsImage} {
  width:80%;
}
@media (max-width: 1024px) {
  justify-content: flex-start;
  ${AwsImage} {
    width:60%;
  }
  @media (max-width: 992px) {
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
`;
const FooterSocial = styled.div`
  width: 100%;
  float: left;
  padding: 15px 0px;
  @media (max-width: 767px) {
    ${LeftContent} {
      padding-bottom: 12px;
    }
  }
  @media (max-width: 520px) {
    ${LeftContent} {
      width: 100%;
      justify-content: center;
      padding-bottom: 5px;
      text-align: center;
    }
    ${CenterContent} {
      ${LeftContent} {
        width: auto;
      }
    }
    ${SocialIcons} {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }
`;
