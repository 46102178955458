import { useEffect, useState } from "react";

export const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);
};

function getDocHeight() {
  var D = document;
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  );
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  function amountscrolled() {
    var winheight =
      window.innerHeight ||
      (document.documentElement || document.body).clientHeight;
    var docheight = getDocHeight();
    var scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
    var trackLength = docheight - winheight;
    var pctScrolled = Math.floor((scrollTop / trackLength) * 100); // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)

    return pctScrolled;
  }
  const handleScroll = () => {
    const pctScrolled = amountscrolled();
    setScrollPosition(pctScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
};

export function useUniqueId() {
  const [id] = useState(
    () => `component-${Math.random().toString(16).slice(2)}`
  );
  return id;
}

export function useShareSessionStorge() {
  useEffect(() => {
    loadSessionStorage();
    // loadSessionStorage();
  }, []);
}

export const loadSessionStorage = function () {
  //source: http://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
  // console.log('executing session storage initializer')

  console.log("load session storagr", sessionStorage);

  if (!sessionStorage.length) {
    // Ask other tabs for session storage
    localStorage.setItem("getSessionStorage", Date.now().toString());
  }

  window.addEventListener("storage", function (event) {
    console.log("storage event", event);

    if (event.key == "getSessionStorage") {
      console.log("get session storage");
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
    } else if (event.key == "sessionStorage" && !sessionStorage.length) {
      console.log("store session storage");
      let data = JSON.parse(event.newValue);

      for (let key in data) {
        sessionStorage.setItem(key, data[key]);
      }

      //showSessionStorage()
    }
  });

  window.onbeforeunload = function () {
    sessionStorage.clear();
  };
};

export function setLocalStorageWithExpiry(key, value, ttl) {
  if (typeof window !== "undefined" && window.localStorage) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    // console.log("setLocalStorageWithExpiry ::", key, JSON.stringify(item));
    localStorage.setItem(key, JSON.stringify(item));
  } else {
    return null;
  }
}

export function getLocalStorageWithExpiry(key) {
  if (typeof window !== "undefined" && window.localStorage) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } else {
    return null;
  }
}
