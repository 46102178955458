import {
  ByFeaturesMobile,
  ChatbotIntegration,
  CompanySizeMobile,
  CustomeSpotlight,
  Learn,
  MarketPlace,
  MarketplaceMobile,
  UsecasesMobile,
  WhyWorkativ,
} from "../pages/conversational-ai-platform/header-assistants";
import {
  CloseIcon,
  DropdownArrow,
  Mob_Assistant_Icon,
  Mob_Automate_Icon,
  OpenIcon,
} from "../components/common/Icons";
import React, { useEffect, useReducer, useState } from "react";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
  useUniqueId,
} from "../components/useDisableBodyScroll";

import { Row } from "../components/Footer";
import { ScrollPopUpForAutomatePages } from "../pages/workflow-automation-platform/workflow-builder";
import alogo from "../assets/images/workativ-assistant-logo.svg";
import automateLogo from "../assets/images/workativ-automate-logo.svg";
import { props } from "ramda";
import styled from "styled-components";
import { useMedia } from "use-media";
import wlogo from "../assets/images/workativ-logo.png";
// Mobile images
import workativ_assistant_full from "../assets/images/header/assistant.svg";
import workativ_automate_full from "../assets/images/header/automate.svg";
import workativ_sub_logo from "../assets/images/workativ-subhead-logo.png";

// import { CookiesPoup } from "../pages/homepage";

//Mobile images
export const assistantImg = require("../assets/images/homepage/group1.png");
export const autoamteImg = require("../assets/images/automate_icon.png");

export const redirectToWithUtm = (url, utm) => {
  const toRestore = new URLSearchParams();
  const currentParams = new URLSearchParams(window.location.search);
  let tempParams = {};
  for (const [key, value] of currentParams) {
    if (!key.startsWith("utm_")) {
      toRestore.append(key, value);
    }
  }
 
  // console.log("DOING :: sessionStorageKeys 1", toRestore);
  // const sessionStorageKeys = Object.entries(sessionStorage);
  for (const [key, value] of Object.entries(sessionStorage)) {
    if (key.startsWith("utm_")) {
      toRestore.append(key, value);
    }
  }
  // console.log("DOING :: sessionStorageKeys", toRestore);
  // const params = toRestore.toString();
  // console.log("DOING :: sessionStorageKeys", sessionStorageKeys);
  const params = toRestore.toString();
  window.location.href = `${url}?${params}`;
};

const HeaderWrapper = styled.section`
  width: 100%;
  float: left;
  position: relative;
  z-index: 11;
  background: ${(props) => (props.bgColor ? "#f4f4f4" : "")};
  @media (max-width: 1024px) {
    height: ${(props) => (props.fullScreen ? "100vh" : "")};
    position: ${(props) => (props.fullScreen ? "fixed" : "")};
    height: ${(props) => (props.fullScreenTop ? "30px" : "")};
    position: ${(props) => (props.fullScreenTop ? "fixed" : "")};
    padding-top: ${(props) => (props.paddingTop ? "0px" : "")};
    background: #fff;
    margin-bottom: 0px;
    top: 0;
  }
`;
const TopHeader = styled.div`
  height: 36px;
  display: flex;
  float: left;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    height: ${(props) => (props.fullScreen ? "100vh" : "")};
    position: ${(props) => (props.fullScreen ? "fixed" : "")};
    z-index: 2;
    background: #fff;
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;
const NavbarTop = styled.div``;
const NavbarTopLogo = styled.img`
  width: 140px;
  height: 100%;
  display: flex;
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    width: 240px;
  }
  @media (max-width: 1500px) {
    width: 130px;
  }
  @media (max-width: 1024px) {
    width: 160px;
    height: 100%;
  }
  @media (max-width: 1024px) {
    width: 140px;
    height: 100%;
  }
`;

const Link = styled.a`
  color: #333333;
  cursor: pointer;
`;
const LinkWrapper = styled.div`
  width: auto;
  float: left;
  display: flex;
  font-size: 15px;
  font-family: "Gordita-Medium";
  position: relative;
  top: 2px;
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 18px;
  }
  ${Link} {
    text-decoration: none;
    font-size: 15px;
    font-family: "Gordita-Medium";
    @media (min-width: 1800px) and (max-width: 2560px) {
      font-size: 18px;
    }
    @media (min-width: 2058px) and (max-width: 2560px) {
      font-size: 28px;
    }
  }
  ${Link}:hover {
    color: #2468f6;
  }
  @media (max-width: 1024px) {
    ${Link} {
      font-size: 18px;
      font-family: "Gordita-Medium";
      color: #333;
    }
  }
  @media (max-width: 1024px) {
    ${Link} {
      font-size: 16px;
      font-family: "Gordita-Medium";
      color: #333;
      width: 100%;
      float: left;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
const StyledLink = styled(Link)`
  margin-right: 20px;
  color: #2a68f6;

  @media (max-width: 1024px) {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #2a68f6 !important;
    width: 100%;
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export function HeaderTop() {
  return (
    <HeaderWrapper>
      <Container className="container">
        <Row className="row">
          <TopHeader>
            <NavbarTop>
              <Link href="/">
                {" "}
                <NavbarTopLogo src={wlogo} alt="workativ"></NavbarTopLogo>
              </Link>
            </NavbarTop>
            <LinkWrapper>
              {" "}
              <StyledLink href="https://calendly.com/workativ/workativ-assistant-demo-page">
                Book Demo
              </StyledLink>
              <Link href="/contact-us">Contact Us</Link>
            </LinkWrapper>
          </TopHeader>
        </Row>
      </Container>
    </HeaderWrapper>
  );
}

const Navbar = styled.div`
  height: 80px;
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
const NavbarLeft = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    float: left;
    display: block;
  }
`;
const NavbarMobMButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  width: 32px;
  float: right;
  height: 24px;
  position: relative;
  cursor: pointer;
  display: none;

  :focus {
    outline: none;
  }
  @media (max-width: 1024px) {
    display: block;
  }
  @media only screen and (min-width: 321px) and (max-width: 820px) {
    display: ${(props) => (props.hideMobileNavButton ? "none" : "block")};
  }
`;
const NavbarLogoWrapper = styled.span`
  @media (max-width: 1024px) {
    width: 100%;
    float: left;
  }
`;
const NavbarLogo = styled.img`
  width: 240px;
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    width: 340px;
  }
  @media (max-width: 1700px) {
    width: 220px;
  }
  @media (max-width: 1200px) {
    width: 200px;
  }
`;
const NavbarButton = styled.button`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2468f6;
  color: #2468f6;
  border-radius: 24px;
  min-width: 0px;
  width: max-content;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  cursor: pointer;
  transition: transform 0.5s ease;
  font-size: 20px;
  font-family: "Gordita-Medium";
  position: relative;
  padding: 0px 20px;
  padding-top: 1px;
  z-index: 1;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px !important;

  :hover {
    transform: scale(1.012);
    border: 1px solid #2468f6;
  }
  @media (max-width: 1700px) {
    height: 38px;
    font-size: 14px;
    padding: 0px 15px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    float: left;
  }
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 14px;
  }
  @media (min-width: 2058px) and (max-width: 2560px) {
    font-size: 24px;
    height: 60px;
    border-radius: 50px;
  }
`;

const NavbarRight = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    float: left;
    display: block;
    margin-top: 15px;
  }
  ${Link} {
    text-decoration: none;
  }
`;

const NavbarWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
`;
const NavbartopWrapper = styled.div`
  width: 100%;
  float: left;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const TopHeaderFixed = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  ${NavbarWrapper} {
    width: auto;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  ${NavbartopWrapper} {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      display: flex;
      place-content: flex-end;
    }
  }
  ${NavbarTop} {
    width: auto;
    float: left;
    @media (max-width: 1024px) {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      padding: 0px 20px;
      border-bottom: 1px solid #d9d9d9;
    }
  }
  ${LinkWrapper} {
    align-items: center;
  }
  @media (max-width: 1024px) {
    display: block;
    ${LinkWrapper} {
      align-items: center;
      padding-bottom: 12px;
    }
  }
  @media (max-width: 1024px) {
    display: block;
    ${LinkWrapper} {
      padding-bottom: 0px;
      width: 100%;
      height: 60px;
      background: #f8f8f8;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
    }
  }
`;
const NavbarIcon = styled.span`
  width: 22px;
  height: 22px;
  float: right;
  display: none;
  margin-right: 4px;

  & svg {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 767px) {
    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const openCloseReducer = (headerList, action) => {
  // console.log("action", action);

  switch (action.type) {
    case "header::toggle": {
      const headerName = action.text;
      const clickedHeaderDetails = headerList.map((list) => {
        if (list.text === headerName) {
          const updatedLeafs = list.subMenu
            ? list.subMenu.map((sm) => {
                return { ...sm, showLeaf: false };
              })
            : list.subMenu;
          return {
            ...list,
            showSubmenu: !list.showSubmenu,
            subMenu: updatedLeafs,
          };
        }
        const updatedLeafs =
          list.subMenu &&
          list.subMenu.map((sm) => {
            return { ...sm, showLeaf: false };
          });
        return { ...list, showSubmenu: false, subMenu: updatedLeafs };
      });
      // console.log("toggle", clickedHeaderDetails, headerName);
      return clickedHeaderDetails;
    }
    case "submenu::toggle": {
      const headerName = action.header;
      const leaf = action.leaf;
      const clickedHeaderDetails = headerList.map((list) => {
        if (list.text === headerName) {
          const updatedLeafs = list.subMenu.map((sm) => {
            if (sm.text === leaf) {
              return { ...sm, showLeaf: !sm.showLeaf };
            }
            return { ...sm, showLeaf: false };
          });

          return { ...list, subMenu: updatedLeafs };
        }
        return { ...list, showSubmenu: false };
      });
      // console.log("clickedHeaderDetails", clickedHeaderDetails, headerList);
      return clickedHeaderDetails;
    }
  }
};

const openCloseReducerCommon = (headerCommonList, action) => {
  // console.log("action", action);

  switch (action.type) {
    case "header::toggle": {
      const headerName = action.text;
      const clickedHeaderDetails = headerCommonList.map((list) => {
        if (list.text === headerName) {
          const updatedLeafs = list.subMenu
            ? list.subMenu.map((sm) => {
                return { ...sm, showLeaf: false };
              })
            : list.subMenu;
          return {
            ...list,
            showSubmenu: !list.showSubmenu,
            subMenu: updatedLeafs,
          };
        }
        const updatedLeafs =
          list.subMenu &&
          list.subMenu.map((sm) => {
            return { ...sm, showLeaf: false };
          });
        return { ...list, showSubmenu: false, subMenu: updatedLeafs };
      });
      // console.log("toggle", clickedHeaderDetails, headerName);
      return clickedHeaderDetails;
    }
    case "submenu::toggle": {
      const headerName = action.header;
      const leaf = action.leaf;
      const clickedHeaderDetails = headerCommonList.map((list) => {
        if (list.text === headerName) {
          const updatedLeafs = list.subMenu.map((sm) => {
            if (sm.text === leaf) {
              return { ...sm, showLeaf: !sm.showLeaf };
            }
            return { ...sm, showLeaf: false };
          });

          return { ...list, subMenu: updatedLeafs };
        }
        return { ...list, showSubmenu: false };
      });
      // console.log("clickedHeaderDetails", headerCommonList, clickedHeaderDetails);
      return clickedHeaderDetails;
    }
  }
};

const getLogoComponent = (logoof) => {
  const headerImagesWithAltText = {
    ASSISTANT: {
      // image: alogo,
      image: workativ_assistant_full,
      alt: "Workativ Assistant",
      href: "/conversational-ai-platform",
    },
    AUTOMATE: {
      // image: automateLogo,
      image: workativ_automate_full,
      alt: "Workativ Automate",
      href: "/workflow-automation-platform",
    },
    WORKATIV: {
      image: wlogo,
      alt: "Workativ",
      href: "/",
    },
  };

  const logoOfProduct = logoof ? logoof : "WORKATIV";

  const logoData = headerImagesWithAltText[logoOfProduct]
    ? headerImagesWithAltText[logoOfProduct]
    : headerImagesWithAltText["WORKATIV"];

  return <NavbarLogo src={logoData.image} alt={logoData.alt} />;
};
const getLogoComponentMobile = (logoof) => {
  const headerImagesWithAltText = {
    ASSISTANT: {
      image: workativ_assistant_full,
      alt: "Workativ Assistant",
      href: "/conversational-ai-platform",
    },
    AUTOMATE: {
      image: workativ_automate_full,
      alt: "Workativ Automate",
      href: "/workflow-automation-platform",
    },
    WORKATIV: {
      image: wlogo,
      alt: "Workativ",
      href: "/",
    },
  };

  const logoOfProduct = logoof ? logoof : "WORKATIV";

  const logoData = headerImagesWithAltText[logoOfProduct]
    ? headerImagesWithAltText[logoOfProduct]
    : headerImagesWithAltText["WORKATIV"];

  return <NavbarLogo src={logoData.image} alt={logoData.alt} />;
};

//Header common

const headersCommon = [
  {
    text: "Products",
    showSubmenu: false,
    subMenu: [
      {
        text: "Workativ Assistant",
        link: "/conversational-ai-platform",
        image: <Mob_Assistant_Icon />,
        mobImage: <Mob_Assistant_Icon />,
        content:
          "Conversational AI platform to build chatbots with automation for workplace support",
      },
      {
        text: "Workativ Automate",
        link: "/workflow-automation-platform",
        image: <Mob_Automate_Icon />,
        mobImage: <Mob_Automate_Icon />,
        content:
          "Workflow Automation platform to connect your apps and automate workflows like a pro",
      },
    ],
  },
  {
    text: "Company",
    showSubmenu: false,
    subMenu: [
      {
        text: "Careers",
        link: "/careers",
      },
      {
        text: "Partners",
        link: "/partner",
      },
      {
        text: "Security",
        link: "/security",
      },
    ],
  },
];

// Workativ Header Start

const headers = [
  {
    text: "Products",
    showSubmenu: false,
    subMenu: [
      {
        text: "Workativ Assistant",
        link: "/conversational-ai-platform",
      },
      {
        text: "Workativ Automate",
        link: "/workflow-automation-platform",
      },
    ],
  },
  {
    text: "Why Workativ",
    showSubmenu: false,
    subMenu: [
      {
        text: "By Solutions",
        showLeaf: false,
        leaf: [
          {
            text: "Conversational IT Support ​",
            link: "/assistant/it-helpdesk-chatbot",
          },
          {
            text: "Conversational HR Support",
            link: "/conversational-ai-platform/conversational-hr-support",
          },
          {
            text: "Workflow Automation",
            link: "/assistant/workflow-automation",
          },
        ],
      },
      {
        text: "By Capabilities",
        showLeaf: false,
        leaf: [
          {
            text: "Conversational AI",
            link: "/conversational-ai-platform/chatbot-builder",
          },
          {
            text: " Process Automation",
            link: "/conversational-ai-platform/chatbot-automation",
          },
          {
            text: "Application Triggers",
            link: "/automate/triggers",
          },
          {
            text: "On-Prem Connect",
            link: "/conversational-ai-platform/on-prem-app-connector",
          },
          {
            text: "Analytics",
            link: "/conversational-ai-platform/featuresanalytics",
          },
          {
            text: "Logs",
            link: "/conversational-ai-platform/chatbot-analytics-performance",
          },
          {
            text: "Training",
            link: "/conversational-ai-platform/chatbot-analytics-performance",
          },
          {
            text: "Chat Channels",
            link: "/conversational-ai-platform/chatbot-channels",
          },
          {
            text: "All Capabilities",
            link: "/conversational-ai-platform/features",
          },
        ],
      },

      {
        text: " By Automations",
        showLeaf: false,
        leaf: [
          {
            text: "Unlock Account",
            link: "/use-cases/unlock-account-automation",
          },
          {
            text: "Password Reset",
            link: "/use-cases/reset-password-automation",
          },
          {
            text: "Employee Onboarding",
            link: "/use-cases/employee-onboarding-automation",
          },
          {
            text: "User Provisioning",
            link: "/use-cases/user-provisioning-automation",
          },
          {
            text: "Payroll Enquiry",
            link: "/use-cases/payroll-enquiry-automation",
          },
          {
            text: " Leave Management",
            link: "/use-cases/leave-management-automation",
          },
          {
            text: "Employee Offboarding",
            link: "/use-cases/employee-offboarding-automation",
          },
          {
            text: " AI Ticketing",
            link: "/use-cases/ai-ticketing",
          },
        ],
      },
    ],
  },
];

// Workativ Header End

// Assistant Header Start

const CHATBOT_INTEGRATION_MAX = 12;
const WHY_WORKATIV_MAX = 10;

const headersAssistant = [
  {
    text: "Why Workativ",
    showSubmenu: false,
    maxItems: 8,
    subMenu: [
      {
        text: "By Features",
        showLeaf: false,
        type: "full-length-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Conversational AI",
            link: "/conversational-ai-platform/conversational-ai",
          },
          {
            text: "Chatbot Builder ",
            link: "/conversational-ai-platform/chatbot-builder",
          },

          {
            text: "Chatbot Automation​ (RPA)",
            link: "/conversational-ai-platform/chatbot-automation",
          },
          {
            text: "Knowledge AI",
            link: "/conversational-ai-platform/knowledge-ai",
            new: true,
          },
          {
            text: "Shared Inbox",
            link: "/conversational-ai-platform/shared-inbox",
            comingsoon: true,
          },
          {
            text: "Chatbot Templates",
            link: "/conversational-ai-platform/chatbot-marketplace-templates",
          },
          {
            text: "Chatbot Analytics",
            link: "/conversational-ai-platform/chatbot-analytics-performance",
          },
          // {
          //   text: "On-prem App Connector",
          //   link: "/conversational-ai-platform/on-prem-app-connector"
          // },
          {
            text: "FAQ Builder",
            link: "/conversational-ai-platform/faq-chatbot-builder",
          },
          {
            text: "Ominichannel​",
            link: "/conversational-ai-platform/chatbot-channels",
          },
          {
            text: "Agent Handover",
            link: "/conversational-ai-platform/agent-handover-chatbot",
          },
          {
            text: "Approval Management",
            link: "/conversational-ai-platform/approval-management-chatbot",
          },
          {
            text: "All Features​",
            link: "/conversational-ai-platform/features",
            type: "domain-landing-page-redirect-button",
          },
        ],
      },
      {
        text: "By Usecases",
        showLeaf: false,
        type: "full-length-submenu",
        maxItems: WHY_WORKATIV_MAX,
        leaf: [
          {
            text: "Virtual Agent​",
            link: "/conversational-ai-platform/virtual-agent",
          },
          {
            text: "IT Helpdesk Chatbot",
            link: "/conversational-ai-platform/it-helpdesk-chatbot",
          },
          {
            text: "HR Chatbot",
            link: "/conversational-ai-platform/hr-chatbot",
          },
          {
            text: "AI Self Service",
            link: "/conversational-ai-platform/ai-self-service-chatbot",
          },
          {
            text: "Conversational AI Chatbot​",
            link: "/conversational-ai-platform/conversational-ai-bot",
          },
        ],
      },
      {
        text: "By Company Size",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: WHY_WORKATIV_MAX,
        leaf: [
          {
            text: "Mid-Market / Enterprise",
            link:
              "/conversational-ai-platform/employee-experience-platform-for-enterprise",
          },
        ],
      },
      {
        text: "Customer Success Stories",
        link:
          "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation",
      },

      // {
      //   text: "How it works",
      //   link: "/conversational-ai-platform/how-it-works"
      // }
    ],
  },
  {
    text: "Chatbot Integrations",
    showSubmenu: false,
    maxItems: CHATBOT_INTEGRATION_MAX,
    subMenu: [
      {
        text: "HR",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Zenefits",
            link: "/conversational-ai-platform/zenefits-chatbot",
          },
          {
            text: "BambooHR",
            link: "/conversational-ai-platform/bamboohr-chatbot",
          },
          {
            text: "Workday",
            link: "/conversational-ai-platform/workday-chatbot",
          },
          {
            text: "Zoho People",
            link: "/conversational-ai-platform/zoho-people-chatbot",
          },
          {
            text: "Deputy",
            link: "/conversational-ai-platform/deputy-chatbot",
          },
          {
            text: "OrangeHRM",
            link: "/conversational-ai-platform/orange-hrm-chatbot",
          },
          {
            text: "PurelyHR",
            link: "/conversational-ai-platform/purelyhr-chatbot",
          },
          {
            text: "People HR",
            link: "/conversational-ai-platform/peoplehr-chatbot",
          },
          {
            text: "Fingercheck",
            link: "/conversational-ai-platform/fingercheck-chatbot",
          },
          {
            text: "TSheets",
            link: "/conversational-ai-platform/tsheets-chatbot",
          },
          {
            text: "Gusto",
            link: "/conversational-ai-platform/gusto-chatbot",
          },
          {
            text: "WebHR",
            link: "/conversational-ai-platform/webhr-chatbot",
          },
          {
            text: "GreytHR",
            link: "/conversational-ai-platform/greythr-chatbot",
          },
          {
            text: "SAP Success factor",
            link: "/conversational-ai-platform/sap-successfactors-chatbot",
          },
        ],
      },
      {
        text: "ITSM",
        showLeaf: false,
        type: "full-length-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Freshdesk",
            link: "/conversational-ai-platform/freshdesk-chatbot",
          },
          {
            text: "Freshservice",
            link: "/conversational-ai-platform/freshservice-chatbot",
          },
          {
            text: "ServiceNow",
            link: "/conversational-ai-platform/servicenow-chatbot",
          },
          {
            text: "Zendesk",
            link: "/conversational-ai-platform/zendesk-chatbot",
          },
          {
            text: "Jira",
            link: "/conversational-ai-platform/jira-chatbot",
          },
          {
            text: "Jira Service Desk",
            link: "/conversational-ai-platform/jira-service-desk-chatbot",
          },
          {
            text: "SolarWinds",
            link: "/conversational-ai-platform/solarwinds-chatbot",
          },
          {
            text: "ZohoDesk",
            link: "/conversational-ai-platform/zoho-desk-chatbot",
          },
          {
            text: "((OTRS)) Community Edition",
            link: "/conversational-ai-platform/otrs-chatbot",
          },
          {
            text: "ServiceDesk Plus",
            link: "/conversational-ai-platform/servicedesk-plus-chatbot",
          },
          {
            text: " IBM Control Desk",
            link: "/conversational-ai-platform/ibm-control-desk-chatbot",
          },
          {
            text: "HaloITSM",
            link: "/conversational-ai-platform/halo-itsm-chatbot",
          },
          {
            text: "InvGate",
            link: "/conversational-ai-platform/invgate-chatbot",
          },
          {
            text: "Marval",
            link: "/conversational-ai-platform/marval-chatbot",
          },
          {
            text: "Salesforce Service Cloud",
            link:
              "/conversational-ai-platform/salesforce-service-cloud-chatbot",
          },
          {
            text: "Cherwell",
            link: "/conversational-ai-platform/cherwell-chatbot",
          },
          {
            text: "Sysaid",
            link: "/conversational-ai-platform/sysaid-chatbot",
          },
          {
            text: "TOPdesk",
            link: "/conversational-ai-platform/topdesk-chatbot",
          },
          {
            text: "Ivanti",
            link: "/conversational-ai-platform/ivanti-chatbot",
          },
          {
            text: "Alloy Navigator",
            link: "/conversational-ai-platform/alloy-navigator-chatbot",
          },
          {
            text: "Hornbill",
            link: "/conversational-ai-platform/hornbill-chatbot",
          },
          {
            text: "JSM",
            link: "/conversational-ai-platform/jsm-chatbot",
          },
        ],
      },
      {
        text: "CRM",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "ActiveCampaign",
            link: "/conversational-ai-platform/activecampaign-chatbot",
          },
          {
            text: "Hubspot CRM",
            link: "/conversational-ai-platform/hubspot-crm-chatbot",
          },
          {
            text: "Salesforce CRM",
            link: "/conversational-ai-platform/salesforce-crm-chatbot",
          },
          {
            text: "Zoho CRM",
            link: "/conversational-ai-platform/zoho-crm-chatbot",
          },
          {
            text: "Freshworks CRM",
            link: "/conversational-ai-platform/freshworks-crm-chatbot",
          },
          {
            text: "Insightly",
            link: "/conversational-ai-platform/insightly-chatbot",
          },
          {
            text: "Pipedrive",
            link: "/conversational-ai-platform/pipedrive-chatbot",
          },
          {
            text: "Zendesk Sell",
            link: "/conversational-ai-platform/zendesk-sell-chatbot",
          },
          {
            text: "Microsoft Dynamics",
            link: "/conversational-ai-platform/microsoft-dynamics-chatbot",
          },
        ],
      },
      {
        text: "Access Management",
        showLeaf: false,
        type: "full-length-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Okta",
            link: "/conversational-ai-platform/okta-chatbot",
          },
          {
            text: "Office 365",
            link: "/conversational-ai-platform/office-365-chatbot",
          },
          {
            text: "LastPass",
            link: "/conversational-ai-platform/lastpass-chatbot",
          },
          {
            text: "Microsoft Azure AD",
            link: "/conversational-ai-platform/active-directory-chatbot",
          },
          {
            text: "Auth0",
            link: "/conversational-ai-platform/auth0-chatbot",
          },
          {
            text: "Onelogin",
            link: "/conversational-ai-platform/onelogin-chatbot",
          },
          {
            text: "Jumpcloud",
            link: "/conversational-ai-platform/jumpcloud-chatbot",
          },
          {
            text: "Idaptive",
            link: "/conversational-ai-platform/idaptive-chatbot",
          },
          {
            text: "PingIdentity",
            link: "/conversational-ai-platform/ping-identity-chatbot",
          },
          {
            text: "Fusion Auth",
            link: "/conversational-ai-platform/fusionauth-chatbot",
          },
          {
            text: "Duo Security",
            link: "/conversational-ai-platform/duo-security-chatbot",
          },
          {
            text: "AWS IAM ",
            link: "/conversational-ai-platform/aws-chatbot",
          },
          {
            text: "Salesforce Identity",
            link: " /conversational-ai-platform/salesforce-identity-chatbot",
          },
          {
            text: "ManageEngine AD",
            link: " /conversational-ai-platform/manageengine-ad-chatbot",
          },
          {
            text: "RSA Secure ID",
            link: "/conversational-ai-platform/rsa-securid-chatbot",
          },
          {
            text: "Manage Engine AD Manager Plus",
            link:
              "/conversational-ai-platform/manageengine-ad-manager-plus-chatbot",
          },
        ],
      },
      {
        text: "Document Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Dropbox",
            link: "/conversational-ai-platform/dropbox-chatbot",
          },
          {
            text: "Box",
            link: "/conversational-ai-platform/box-chatbot",
          },
          {
            text: "Onedrive",
            link: "/conversational-ai-platform/onedrive-chatbot",
          },
          {
            text: "Google Drive",
            link: "/conversational-ai-platform/google-drive-chatbot",
          },
          {
            text: "SharePoint",
            link: "/conversational-ai-platform/sharepoint-chatbot",
          },
          {
            text: "Confluence",
            link: "/conversational-ai-platform/confluence-chatbot",
          },
        ],
      },
      {
        text: "Expense Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Zoho Expense",
            link: "/conversational-ai-platform/zoho-expense-chatbot",
          },
          {
            text: "Expensify",
            link: "/conversational-ai-platform/expensify-chatbot",
          },
          {
            text: "Rydoo",
            link: "/conversational-ai-platform/rydoo-chatbot",
          },
          {
            text: "Trip Actions",
            link: "/conversational-ai-platform/tripactions-chatbot",
          },
          {
            text: "Certify Expense",
            link: "/conversational-ai-platform/certify-chatbot",
          },
        ],
      },
      {
        text: "Mobile Device Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Microsoft Intune",
            link: "/conversational-ai-platform/microsoft-intune-chatbot",
          },
          {
            text: "JAMF",
            link: "/conversational-ai-platform/jamf-chatbot",
          },
          {
            text: "ManageEngine MDM",
            link: "/conversational-ai-platform/manageengine-mdm-chatbot",
          },
          {
            text: "MobileIron",
            link: "/conversational-ai-platform/mobileiron-chatbot",
          },
          {
            text: "MaaS360",
            link: "/conversational-ai-platform/maas360-chatbot",
          },
          {
            text: "Airwatch",
            link: "/conversational-ai-platform/airwatch-chatbot",
          },
          {
            text: "Hexnode",
            link: "/conversational-ai-platform/hexnode-chatbot",
          },
          {
            text: "Citrix",
            link: "/conversational-ai-platform/citrix-chatbot",
          },
          {
            text: "Addigy",
            link: "/conversational-ai-platform/addigy-chatbot",
          },
        ],
      },
      {
        text: "Collaboration",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Teams",
            link: "/conversational-ai-platform/ms-teams-bot",
          },
          {
            text: "Slack",
            link: "/conversational-ai-platform/slack-chatbot",
          },
        ],
      },
      {
        text: "Email Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Outlook",
            link: "/conversational-ai-platform/outlook-chatbot",
          },
          {
            text: "Gmail",
            link: "/conversational-ai-platform/gmail-chatbot",
          },
        ],
      },
      {
        text: "SMS Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Twilio",
            link: "/conversational-ai-platform/twilio-chatbot",
          },
          {
            text: "Vonage",
            link: "/conversational-ai-platform/vonage-chatbot",
          },
          {
            text: "Telnyx",
            link: "/conversational-ai-platform/telnyx-chatbot",
          },
        ],
      },

      {
        text: "Notifications",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Slack",
            link: "/conversational-ai-platform/slack-chatbot",
          },
        ],
      },
      {
        text: "Project Management",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Notion",
            link: "/conversational-ai-platform/notion-chatbot",
          },
        ],
      },
      {
        text: "Accounting",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Xero",
            link: "/conversational-ai-platform/xero-chatbot",
          },

          {
            text: "Freshbooks",
            link: "/conversational-ai-platform/freshbooks-chatbot",
          },

          {
            text: "NetSuite",
            link: "/conversational-ai-platform/netsuite-chatbot",
          },

          {
            text: "Quickbooks",
            link: "/conversational-ai-platform/quickbooks-chatbot",
          },

          {
            text: "Sage Intacct",
            link: "/conversational-ai-platform/sage-intacct-chatbot",
          },
        ],
      },
      {
        text: "MSP Software",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "HaloPSA",
            link: "/conversational-ai-platform/halopsa-chatbot",
          },
          {
            text: "ConnectWise",
            link: "/conversational-ai-platform/connectwise-chatbot",
          },
        ],
      },
      {
        text: "Organizer",
        showLeaf: false,
        type: "normal-aligned-submenu",
        maxItems: CHATBOT_INTEGRATION_MAX,
        leaf: [
          {
            text: "Google Calendar",
            link: "/conversational-ai-platform/google-calendar-chatbot",
          },
        ],
      },
      {
        text: "All Integrations",
        link: "/conversational-ai-platform/integrations",
        type: "domain-landing-page-redirect-button",
      },
    ],
  },
  {
    text: "Marketplace",
    showSubmenu: false,
    subMenu: [
      {
        text: "Pre-built Chatbots​",
        link:
          "/conversational-ai-platform/pre-built-chatbot-template-marketplace",
      },
      {
        text: "App Workflows for Chatbot​",
        link: "/conversational-ai-platform/app-workflow-template-marketplace",
      },
    ],
  },

  {
    text: "Pricing",
    link: "/conversational-ai-platform/pricing",
  },
  {
    text: "Learn",
    showSubmenu: false,
    subMenu: [
      {
        text: "Videos",
        link: "/conversational-ai-platform/chatbot-videos",
      },
      {
        text: "Blog",
        link: "https://workativ.com/",
      },
      // {
      //   text: "ROI Calculator",
      //   link: "/conversational-ai-platform/chatbot-roi-calculator",
      // },
      {
        text: "Whitepaper",
        link: "/conversational-ai-platform/chatbot-whitepaper",
      },
      {
        text: "All Resources",
        link: "/conversational-ai-platform/resources",
      },
    ],
  },
];

// Assistant Header End

// Automate Header Start

const headersAutomate = [
  {
    text: "Why Workativ",
    showSubmenu: false,
    subMenu: [
      {
        text: "Product Overview",
        link: "/workflow-automation-platform/workflow-automation-feature",
      },
    ],
  },

  {
    text: "App Workflows",
    showSubmenu: false,
    subMenu: [
      {
        text: "MS Intune",
        link: "/workflow-automation-platform/integrations/microsoft-intune",
      },
      {
        text: "Okta",
        link: "/workflow-automation-platform/integrations/okta",
      },
      {
        text: "BambooHR",
        link: "/workflow-automation-platform/integrations/bamboohr",
      },
      {
        text: "Azure Active Directory",
        link: "/workflow-automation-platform/integrations/microsoft-azure-ad",
      },
      {
        text: "Ivanti",
        link: "/workflow-automation-platform/integrations/ivanti",
      },
      {
        text: "Zoho People",
        link: "/workflow-automation-platform/integrations/zoho-people",
      },
      {
        text: "OrangeHRM",
        link: "/workflow-automation-platform/integrations/orangehrm",
      },
      {
        text: "Gusto",
        link: "/workflow-automation-platform/integrations/gusto",
      },
      {
        text: "Duo Security",
        link: "/workflow-automation-platform/integrations/duo-security",
      },
      {
        text: "Jumpcloud",
        link: "/workflow-automation-platform/integrations/jumpcloud",
      },
      {
        text: "All Apps",
        link: "/workflow-automation-platform/integrations",
        type: "domain-landing-page-redirect-button",
      },
    ],
  },
  {
    text: "Popular Integrations​",
    showSubmenu: false,

    subMenu: [
      {
        text: "MS Azure AD + ServiceNow",
        link:
          "/workflow-automation-platform/integrations/microsoft-azure-ad+servicenow",
      },
      {
        text: "GDrive + MS Intune",
        link:
          "/workflow-automation-platform/integrations/google-drive+microsoft-intune",
      },
      {
        text: "BambooHR + Gusto",
        link: "/workflow-automation-platform/integrations/bamboohr+gusto",
      },
      {
        text: "​​ServiceNow + Outlook",
        link: "/workflow-automation-platform/integrations/servicenow+outlook",
      },
      {
        text: "Jira + MS Intune",
        link:
          "/workflow-automation-platform/integrations/jira-service-management+microsoft-intune",
      },
      {
        text: "Gusto + Netsuite",
        link: "/workflow-automation-platform/integrations/gusto+netsuite",
      },
      {
        text: "BambooHR + MS Azure AD",
        link:
          "/workflow-automation-platform/integrations/bamboohr+microsoft-azure-ad",
      },
      {
        text: "ServiceNow + MS Intune",
        link:
          "/workflow-automation-platform/integrations/servicenow+microsoft-intune",
      },
      {
        text: "Gusto + Google Calendar",
        link:
          "/workflow-automation-platform/integrations/gusto+google-calendar",
      },
      {
        text: "Jira + MS Teams",
        link:
          "/workflow-automation-platform/integrations/jira-service-management+microsoft-teams",
      },
      {
        text: "All Integrations",
        link: "/workflow-automation-platform/integrations",
        type: "domain-landing-page-redirect-button",
      },
    ],
  },

  {
    text: "Pricing",
    link: "/workflow-automation-platform/pricing",
  },
];

// Automate Header End

// header changes
const headerList = (logoFor) => {
  if (logoFor === "ASSISTANT") return headersAssistant;
  else if (logoFor === "WORKATIV") return headers;
  else if (logoFor === "AUTOMATE") return headersAutomate;
  else return headers;
};
const headerCommonList = (logoFor) => {
  if (logoFor === "ASSISTANT") return headersCommon;
  else if (logoFor === "AUTOMATE") return headersCommon;
  else if (logoFor === "WORKATIV") return headersCommon;
  else return headers;
};
const href = (logoFor) => {
  if (logoFor === "ASSISTANT") return "/conversational-ai-platform";
  else if (logoFor === "WORKATIV") return "/";
  else if (logoFor === "AUTOMATE") return "/workflow-automation-platform";
  else return "/";
};
const cName = (logoFor) => {
  if (logoFor === "ASSISTANT") return "assistant_header";
  else if (logoFor === "WORKATIV") return "workativ_header";
  else if (logoFor === "AUTOMATE") return "automate_header";
  else return "";
};

export default function Header({
  backgroundColor,
  logoFor,
  logoAction,
  hideMobileNavButton,
  betaClick,
}) {
  const [isOpen, setOpen] = useState(false);
  const isSmall = useMedia({ maxWidth: "1024px" });

  const [showMore, setShowMore] = useState("hide");

  const [currentLeaf, setCurrentLeaf] = useState("");

  const isMobile = useMedia({ maxWidth: "1024px" });
  const is1440 = useMedia({ maxWidth: "1440px" });

  const [isActive, setActive] = useState("");

  const [subMenuHoverIndex, setSubmenuIndexOnHover] = React.useState(1);
  const [subMenuIndexReactive, setSubMenuIndexReactive] = React.useState(1);

  useEffect(() => {
    setInterval(() => {
      setSubMenuIndexReactive(() => subMenuIndexReactive + 1);
    }, 500);
  }, []);

  React.useEffect(() => {
    const script = document.createElement("script");
    if (document) {
      script.type = "text/javascript";
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (document) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const toggleClass = (header) => {
    if (header == isActive) {
      setActive("");
    } else {
      setActive(header);
    }
  };

  const [sectionList, dispatch] = useReducer(
    openCloseReducer,
    headerList(logoFor)
  );
  const [sectionCommonList, dispatchCommon] = useReducer(
    openCloseReducerCommon,
    headerCommonList(logoFor)
  );
  const headerCommmonClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatchCommon({ type: "header::toggle", text: link.text });
  };
  const headerClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatch({ type: "header::toggle", text: link.text });
  };

  const isWorkativPage =
    logoFor == "WORKATIV" || logoFor == undefined || logoFor == null;

  const isAutomatePage =
    logoFor == "AUTOMATE" || logoFor == undefined || logoFor == null;

  let subMenuTop = is1440 ? 33 : 36;

  console.log("DOING ::: 0 ::: subMenuIndex", isAutomatePage);
  const isAutomatePopupScroll = isSmall ? null : (
    <ScrollPopUpForAutomatePages />
  );
  return (
    <HeaderWrapper
      fullScreen={isSmall && isOpen}
      paddingTop={isWorkativPage && isOpen}
    >
      <div
        className={
          isWorkativPage
            ? isSmall && isOpen
              ? "top_header_open _mob_header"
              : "top_header header res_header home_header"
            : isSmall && isOpen
            ? "top_header_open"
            : "top_header header res_header"
        }
      >
        <Container className="container">
          <TopHeaderFixed>
            <NavbarTop>
              <Link
                style={{
                  pointerEvents:
                    logoAction && logoAction === "NONE" ? "none" : "auto",
                  cursor:
                    logoAction && logoAction === "NONE" ? "auto" : "pointer",
                }}
                href={logoAction && logoAction === "NONE" ? "#" : "/"}
              >
                {" "}
                <NavbarTopLogo
                  src={wlogo}
                  alt="workativ"
                  className="pr-4"
                ></NavbarTopLogo>
              </Link>
              <NavbarMobMButton hideMobileNavButton={hideMobileNavButton}>
                <NavbarIcon onClick={(_) => setOpen(!isOpen)}>
                  {isSmall && isOpen ? <CloseIcon /> : <OpenIcon />}
                </NavbarIcon>
              </NavbarMobMButton>
            </NavbarTop>
            {isSmall ? (
              <div
                className={
                  (isSmall && isOpen) || !isSmall
                    ? "mobile_navbartop_wrapper"
                    : null
                }
              >
                <NavbartopWrapper className="mobile_navbartop">
                  <div className="mob_menu_li">
                    {(isSmall && isOpen) || !isSmall ? (
                      <NavbarProduct className="NavbarProduct">
                        <NavbarWrapper>
                          <DropDownUl>
                            {sectionCommonList.map((link, i) =>
                              link.subMenu && link.subMenu.length > 0 ? (
                                <DropDownLi className={`sticky_pad_0`} key={i}>
                                  <span
                                    className={
                                      link.text == isActive && isOpen
                                        ? `${"svg_roate_icon svg_roate_header"}`
                                        : "svg_roate_header"
                                    }
                                    onClick={(event) => {
                                      headerCommmonClick(link, event);
                                      toggleClass(link.text);
                                    }}
                                  >
                                    {link.text}
                                    <DropdownArrow />
                                  </span>
                                  {((isSmall && link.showSubmenu) ||
                                    !isSmall) && (
                                    <DropDownContent>
                                      {link.subMenu.map((navLink, index) =>
                                        navLink.leaf &&
                                        navLink.leaf.length > 0 ? (
                                          <SubMenu
                                            className="submenu_ul"
                                            // href="#"
                                            key={index}
                                          >
                                            <span
                                              className={
                                                navLink.text == isActive &&
                                                isOpen
                                                  ? `${"svg_roate_icon_inside"}`
                                                  : ""
                                              }
                                              onClick={(event) => {
                                                // console.log("submenu :: event");
                                                event.preventDefault();
                                                event.stopPropagation();
                                                event.nativeEvent.stopImmediatePropagation();
                                                toggleClass(navLink.text);
                                                dispatchCommon({
                                                  type: "submenu::toggle",
                                                  header: link.text,
                                                  leaf: navLink.text,
                                                });
                                              }}
                                            >
                                              {navLink.text} <DropdownArrow />{" "}
                                            </span>
                                            <SubmenuWrapper>
                                              {((isSmall && navLink.showLeaf) ||
                                                !isSmall) && (
                                                <DropDownContent>
                                                  {navLink.leaf.map((leaf) => (
                                                    <SubMenu
                                                      href={leaf.link.trim()}
                                                      key={leaf.link}
                                                    >
                                                      {leaf.text}
                                                    </SubMenu>
                                                  ))}
                                                </DropDownContent>
                                              )}
                                            </SubmenuWrapper>
                                          </SubMenu>
                                        ) : (
                                          <>
                                            <div className="home_menu_top">
                                              <SubMenu
                                                href={navLink.link.trim()}
                                                key={index}
                                              >
                                                {navLink.image ? (
                                                  isMobile ? (
                                                    <div className="header_menu">
                                                      <span className="second_p">
                                                        {navLink.mobImage}
                                                      </span>
                                                      {/* <img
                                                  className="second_p"
                                                  src={navLink.mobImage}
                                                /> */}
                                                      <div className="mob_link_menu">
                                                        <span>
                                                          {navLink.text}
                                                        </span>
                                                        {navLink.content && (
                                                          <p className="second_p">
                                                            {navLink.content}
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="header_menu">
                                                      <img
                                                        className="second_p"
                                                        src={navLink.image}
                                                      />{" "}
                                                      <span>
                                                        {navLink.text}
                                                      </span>
                                                      {navLink.content && (
                                                        <p className="second_p">
                                                          {navLink.content}
                                                        </p>
                                                      )}
                                                    </div>
                                                  )
                                                ) : (
                                                  <div className="header_menu first_child">
                                                    <span>{navLink.text}</span>
                                                    {navLink.content && (
                                                      <p className="second_p">
                                                        {navLink.content}
                                                      </p>
                                                    )}
                                                  </div>
                                                )}
                                              </SubMenu>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </DropDownContent>
                                  )}
                                </DropDownLi>
                              ) : (
                                <StyledLi className={`fff`}>
                                  <NavbaraLi href={link.link.trim()}>
                                    {link.text}
                                  </NavbaraLi>
                                </StyledLi>
                              )
                            )}
                          </DropDownUl>
                        </NavbarWrapper>
                      </NavbarProduct>
                    ) : null}
                    {(isSmall && isOpen) || !isSmall ? (
                      <LinkWrapper className="linkwrapper">
                        {" "}
                        <StyledLink href="https://calendly.com/workativ/workativ-assistant-demo-page">
                          Book Demo
                        </StyledLink>
                      </LinkWrapper>
                    ) : null}
                    {(isSmall && isOpen) || !isSmall ? (
                      <LinkWrapper className="linkwrapper">
                        <Link href="/contact-us">Contact Us</Link>
                      </LinkWrapper>
                    ) : null}
                  </div>
                  <NavbarLogoMobMain className="navbarlogo_main">
                    <NavbarLogoMob className="navbarlogo_img">
                      <NavbarLogoWrapper>
                        <Link className="url_manipulation" href={href(logoFor)}>
                          {getLogoComponent(logoFor)}
                        </Link>
                      </NavbarLogoWrapper>
                    </NavbarLogoMob>
                  </NavbarLogoMobMain>
                  {!isWorkativPage ? (
                    <div
                      className={
                        isSmall && isOpen
                          ? "mob_header_wrapper"
                          : `${"header res_header"} ${
                              backgroundColor ? backgroundColor : ""
                            }`
                      }
                    >
                      <Container className="container">
                        <Row className="row">
                          <Navbar className={`landing_page_head`}>
                            <NavbarLeft className={`sticky_pad nav-bar-left`}>
                              {(isSmall && isOpen) || isSmall ? (
                                <NavbarLogoMob>
                                  <NavbarLogoWrapper className="navbarlogo_img test">
                                    <Link
                                      className="url_manipulation"
                                      href={href(logoFor)}
                                    >
                                      {isSmall && isOpen
                                        ? getLogoComponentMobile(logoFor)
                                        : getLogoComponent(logoFor)}
                                    </Link>
                                  </NavbarLogoWrapper>
                                </NavbarLogoMob>
                              ) : null}
                            </NavbarLeft>
                            {(isSmall && isOpen) || !isSmall ? (
                              <NavbarRight className="navbar_right">
                                {(isSmall && isOpen) || !isSmall ? (
                                  <NavbarWrapper>
                                    <DropDownUl>
                                      {sectionList.map((link, i) =>
                                        link.subMenu &&
                                        link.subMenu.length > 0 ? (
                                          <DropDownLi
                                            className={`sticky_pad_0`}
                                            key={i}
                                          >
                                            <span
                                              className={
                                                link.text == isActive && isOpen
                                                  ? `${"svg_roate_icon svg_roate_header"}`
                                                  : "svg_roate_header"
                                              }
                                              onClick={(event) => {
                                                headerClick(link, event);
                                                toggleClass(link.text);
                                              }}
                                            >
                                              {link.text}
                                              <DropdownArrow />
                                            </span>
                                            {((isSmall && link.showSubmenu) ||
                                              !isSmall) && (
                                              <DropDownContent>
                                                {link.subMenu.map(
                                                  (navLink, index) =>
                                                    navLink.leaf &&
                                                    navLink.leaf.length > 0 ? (
                                                      <SubMenu
                                                        className="submenu_ul"
                                                        // href="#"
                                                        key={index}
                                                      >
                                                        <span
                                                          className={
                                                            navLink.text ==
                                                              isActive && isOpen
                                                              ? `${"svg_roate_icon_inside svg_roate_header"}`
                                                              : "svg_roate_header"
                                                          }
                                                          onClick={(event) => {
                                                            // console.log(
                                                            //   "submenu :: event"
                                                            // );
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            event.nativeEvent.stopImmediatePropagation();
                                                            toggleClass(
                                                              navLink.text
                                                            );
                                                            dispatch({
                                                              type:
                                                                "submenu::toggle",
                                                              header: link.text,
                                                              leaf:
                                                                navLink.text,
                                                            });
                                                          }}
                                                        >
                                                          {navLink.text}{" "}
                                                          <DropdownArrow />{" "}
                                                        </span>
                                                        <SubmenuWrapper
                                                          className={
                                                            link.text ==
                                                            "Chatbot Integrations"
                                                              ? "SubmenuWrapper Chatbot_Integrations "
                                                              : "SubmenuWrapper"
                                                          }
                                                        >
                                                          {((isSmall &&
                                                            navLink.showLeaf) ||
                                                            !isSmall) && (
                                                            <>
                                                              <DropDownContent className="dropdown_li">
                                                                {link.text ==
                                                                "Chatbot Integrations"
                                                                  ? navLink.leaf
                                                                      .filter(
                                                                        (
                                                                          leaf,
                                                                          index
                                                                        ) =>
                                                                          currentLeaf.indexOf(
                                                                            navLink.text
                                                                          ) !=
                                                                            -1 ||
                                                                          index <
                                                                            3
                                                                      )
                                                                      .map(
                                                                        (
                                                                          leaf
                                                                        ) => (
                                                                          <SubMenu
                                                                            href={leaf.link.trim()}
                                                                            key={
                                                                              leaf.link
                                                                            }
                                                                          >
                                                                            {
                                                                              leaf.text
                                                                            }
                                                                            {leaf.new ? (
                                                                              <sup className="header-new-launch font-section-small-signup-form">
                                                                                Beta
                                                                              </sup>
                                                                            ) : null}
                                                                            {leaf.comingsoon ? (
                                                                              <sup className="header-new-launch-soon font-section-small-signup-form">
                                                                                Coming
                                                                                soon
                                                                              </sup>
                                                                            ) : null}
                                                                          </SubMenu>
                                                                        )
                                                                      )
                                                                  : navLink.leaf
                                                                      .filter(
                                                                        (
                                                                          leaf,
                                                                          index
                                                                        ) =>
                                                                          currentLeaf.indexOf(
                                                                            navLink.text
                                                                          ) ||
                                                                          index
                                                                      )
                                                                      .map(
                                                                        (
                                                                          leaf
                                                                        ) => (
                                                                          <SubMenu
                                                                            href={leaf.link.trim()}
                                                                            key={
                                                                              leaf.link
                                                                            }
                                                                          >
                                                                            {
                                                                              leaf.text
                                                                            }
                                                                            {leaf.new ? (
                                                                              <sup className="header-new-launch font-section-small-signup-form">
                                                                                Beta
                                                                              </sup>
                                                                            ) : null}{" "}
                                                                            {leaf.comingsoon ? (
                                                                              <sup className="header-new-launch-soon font-section-small-signup-form">
                                                                                Coming
                                                                                soon
                                                                              </sup>
                                                                            ) : null}
                                                                          </SubMenu>
                                                                        )
                                                                      )}
                                                                {navLink.leaf
                                                                  .length > 3 &&
                                                                link.text ==
                                                                  "Chatbot Integrations" &&
                                                                currentLeaf.indexOf(
                                                                  navLink.text
                                                                ) == -1 ? (
                                                                  <button
                                                                    onClick={(
                                                                      event
                                                                    ) => {
                                                                      event.preventDefault();
                                                                      event.stopPropagation();
                                                                      setShowMore(
                                                                        "show"
                                                                      );
                                                                      setCurrentLeaf(
                                                                        [
                                                                          ...currentLeaf,
                                                                          navLink.text,
                                                                        ]
                                                                      );
                                                                    }}
                                                                  >
                                                                    Show all{" "}
                                                                    {
                                                                      navLink.text
                                                                    }{" "}
                                                                    apps
                                                                  </button>
                                                                ) : null}
                                                              </DropDownContent>
                                                            </>
                                                          )}
                                                        </SubmenuWrapper>
                                                      </SubMenu>
                                                    ) : (
                                                      <SubMenu
                                                        className="no_children_li"
                                                        href={navLink.link.trim()}
                                                        key={index}
                                                      >
                                                        {" "}
                                                        {navLink.text}
                                                      </SubMenu>
                                                    )
                                                )}
                                              </DropDownContent>
                                            )}
                                          </DropDownLi>
                                        ) : isAutomatePage ? (
                                          <StyledLi
                                            className={`ul_li_wrapper autoamte_header_top`}
                                          >
                                            <NavbaraLi href={link.link.trim()}>
                                              {link.text}
                                            </NavbaraLi>
                                          </StyledLi>
                                        ) : (
                                          <StyledLi className={`ul_li_wrapper`}>
                                            <NavbaraLi href={link.link.trim()}>
                                              {link.text}
                                            </NavbaraLi>
                                          </StyledLi>
                                        )
                                      )}
                                    </DropDownUl>
                                  </NavbarWrapper>
                                ) : null}
                                {isAutomatePage ? (
                                  <>
                                    {/* <Link
                                      className="url_manipulation"
                                      href="https://automate.workativ.ai/"
                                    >
                                      <NavbarButton>Login</NavbarButton>
                                    </Link> */}
                                    <Link
                                      className="url_manipulation navbar_button"
                                      onClick={() => {
                                        console.log("betaClick", betaClick);
                                        betaClick && betaClick();
                                      }}
                                      // href="javascript:void();"
                                    >
                                      <NavbarButton
                                        style={{
                                          background: "#2468F6",
                                          color: "#fff",
                                          marginLeft: 15,
                                          texttransform: "none !important",
                                        }}
                                        className="button_navbar_automate"
                                      >
                                        Get Started FREE
                                      </NavbarButton>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      className="url_manipulation"
                                      href="https://assistant.workativ.com"
                                    >
                                      <NavbarButton>Login</NavbarButton>
                                    </Link>
                                    <Link
                                      className="url_manipulation navbar_button"
                                      onClick={() => {
                                        redirectToWithUtm(
                                          "https://assistant.workativ.com/authentication/u/direct-signup"
                                        );
                                      }}
                                      href="#"
                                    >
                                      <NavbarButton
                                        style={{
                                          background: "#2468F6",
                                          color: "#fff",
                                          marginLeft: 15,
                                        }}
                                      >
                                        FREE Bot
                                      </NavbarButton>
                                    </Link>
                                  </>
                                )}
                              </NavbarRight>
                            ) : null}
                          </Navbar>
                        </Row>
                      </Container>
                    </div>
                  ) : null}
                </NavbartopWrapper>
              </div>
            ) : (
              <NavbartopWrapper>
                {(isSmall && isOpen) || !isSmall ? (
                  <NavbarProduct className="NavbarProduct">
                    <NavbarWrapper>
                      <DropDownUl>
                        {sectionCommonList.map((link, i) =>
                          link.subMenu && link.subMenu.length > 0 ? (
                            <DropDownLi className={`sticky_pad_0`} key={i}>
                              <span
                                className={
                                  link.text == isActive && isOpen
                                    ? `${"svg_roate_icon svg_roate_header"}`
                                    : "svg_roate_header"
                                }
                                onClick={(event) => {
                                  headerCommmonClick(link, event);
                                  toggleClass(link.text);
                                }}
                              >
                                {link.text}
                                <DropdownArrow />
                              </span>
                              {((isSmall && link.showSubmenu) || !isSmall) && (
                                <DropDownContent>
                                  {link.subMenu.map((navLink, index) =>
                                    navLink.leaf && navLink.leaf.length > 0 ? (
                                      <SubMenu
                                        className="submenu_ul"
                                        href="#"
                                        key={index}
                                      >
                                        <span
                                          className={
                                            navLink.text == isActive && isOpen
                                              ? `${"svg_roate_icon_inside"}`
                                              : ""
                                          }
                                          onClick={(event) => {
                                            // console.log("submenu :: event");s
                                            event.preventDefault();
                                            event.stopPropagation();
                                            event.nativeEvent.stopImmediatePropagation();
                                            toggleClass(navLink.text);
                                            dispatchCommon({
                                              type: "submenu::toggle",
                                              header: link.text,
                                              leaf: navLink.text,
                                            });
                                          }}
                                        >
                                          {navLink.text} <DropdownArrow />{" "}
                                        </span>
                                        <SubmenuWrapper>
                                          {((isSmall && navLink.showLeaf) ||
                                            !isSmall) && (
                                            <DropDownContent>
                                              {navLink.leaf.map((leaf) => (
                                                <SubMenu
                                                  href={leaf.link.trim()}
                                                  key={leaf.link}
                                                >
                                                  {leaf.text}
                                                </SubMenu>
                                              ))}
                                            </DropDownContent>
                                          )}
                                        </SubmenuWrapper>
                                      </SubMenu>
                                    ) : (
                                      <>
                                        <div className="home_menu_top">
                                          <SubMenu
                                            href={navLink.link.trim()}
                                            key={index}
                                          >
                                            {navLink.image ? (
                                              isMobile ? (
                                                <div className="header_menu">
                                                  <img
                                                    className="second_p"
                                                    src={navLink.image}
                                                  />{" "}
                                                  <div className="mob_link_menu">
                                                    <span>{navLink.text}</span>
                                                    {navLink.content && (
                                                      <p className="second_p">
                                                        {navLink.content}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="header_menu">
                                                  <span className="second_p">
                                                    {navLink.image}
                                                  </span>{" "}
                                                  <span className="header_product_name">
                                                    {navLink.text}
                                                  </span>
                                                  {navLink.content && (
                                                    <p className="second_p">
                                                      {navLink.content}
                                                    </p>
                                                  )}
                                                </div>
                                              )
                                            ) : (
                                              <div className="header_menu first_child">
                                                <span>{navLink.text}</span>
                                                {navLink.content && (
                                                  <p className="second_p">
                                                    {navLink.content}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                          </SubMenu>
                                        </div>
                                      </>
                                    )
                                  )}
                                </DropDownContent>
                              )}
                            </DropDownLi>
                          ) : (
                            <StyledLi className={`fff`}>
                              <NavbaraLi href={link.link.trim()}>
                                {link.text}
                              </NavbaraLi>
                            </StyledLi>
                          )
                        )}
                      </DropDownUl>
                    </NavbarWrapper>
                  </NavbarProduct>
                ) : null}
                {(isSmall && isOpen) || !isSmall ? (
                  <LinkWrapper className="linkwrapper">
                    {" "}
                    {isAutomatePage ? (
                      <StyledLink href="https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/">
                        Book Demo
                      </StyledLink>
                    ) : (
                      <StyledLink href="https://calendly.com/workativ/workativ-assistant-demo-page">
                        Book Demo
                      </StyledLink>
                    )}
                    <Link href="/contact-us">Contact Us</Link>
                  </LinkWrapper>
                ) : null}
                {/* {(isSmall && isOpen) || !isSmall ? (
                  <LinkWrapper className="linkwrapper">
                    {" "}

                  </LinkWrapper>
                ) : null} */}
                <NavbarLogoMobMain className="navbarlogo_main">
                  <NavbarLogoMob className="navbarlogo_img">
                    <NavbarLogoWrapper>
                      <Link className="url_manipulation" href={href(logoFor)}>
                        {getLogoComponent(logoFor)}
                      </Link>
                    </NavbarLogoWrapper>
                  </NavbarLogoMob>
                </NavbarLogoMobMain>
              </NavbartopWrapper>
            )}
          </TopHeaderFixed>
        </Container>
      </div>

      {isSmall ? null : !isWorkativPage ? (
        <div
          className={
            isSmall && isOpen
              ? ""
              : `${"header res_header"} ${
                  backgroundColor ? backgroundColor : ""
                }`
          }
        >
          <Container className="container">
            <Row className="row">
              <Navbar className={`landing_page_head`}>
                <NavbarLeft className={`sticky_pad nav-bar-left`}>
                  {(isSmall && isOpen) || !isSmall ? (
                    <NavbarLogoMob>
                      <NavbarLogoWrapper className="navbarlogo_img">
                        <Link className="url_manipulation" href={href(logoFor)}>
                          {getLogoComponent(logoFor)}
                          {/* {setSubMenuIndexReactive} */}
                        </Link>
                      </NavbarLogoWrapper>
                    </NavbarLogoMob>
                  ) : null}
                </NavbarLeft>
                {(isSmall && isOpen) || !isSmall ? (
                  <NavbarRight className="navbar_right">
                    {(isSmall && isOpen) || !isSmall ? (
                      <NavbarWrapper>
                        <DropDownUl>
                          {sectionList.map((link, i) =>
                            link.subMenu && link.subMenu.length > 0 ? (
                              <DropDownLi className={`sticky_pad_0`} key={i}>
                                <span
                                  className={
                                    link.text == isActive && isOpen
                                      ? `${"svg_roate_icon"}`
                                      : ""
                                  }
                                  onClick={(event) => {
                                    headerClick(link, event);
                                    toggleClass(link.text);
                                  }}
                                >
                                  {link.text}
                                  <DropdownArrow />
                                </span>
                                {((isSmall && link.showSubmenu) ||
                                  !isSmall) && (
                                  <DropDownContent
                                  // height={`${
                                  //   link.maxItems * 36
                                  // }px !important`}
                                  >
                                    {link.subMenu.map(
                                      (navLink, levelOneMenuIndex) =>
                                        navLink.leaf &&
                                        navLink.leaf.length > 0 ? (
                                          <>
                                            <SubMenu
                                              className="submenu_ul"
                                              href="#"
                                              id={`submenu_ul-${
                                                levelOneMenuIndex + 1
                                              }`}
                                              key={levelOneMenuIndex}
                                              onMouseOver={() => {
                                                setSubmenuIndexOnHover(
                                                  levelOneMenuIndex + 1
                                                );
                                                // console.log(
                                                //   "SubMenu onMouseOver",
                                                //   subMenuHoverIndex,
                                                //   subMenuIndexReactive,
                                                //   levelOneMenuIndex + 1,
                                                //   subMenuHoverIndex * 33 - 16
                                                // );
                                              }}
                                              onMouseLeave={() => {
                                                // console.log(
                                                //   "SubMenu onMouseLeave",
                                                //   subMenuHoverIndex
                                                // );
                                              }}
                                            >
                                              <span
                                                className={
                                                  navLink.text == isActive &&
                                                  isOpen
                                                    ? `${"svg_roate_icon_inside"}`
                                                    : ""
                                                }
                                                onClick={(event) => {
                                                  // console.log(
                                                  //   "submenu :: event"
                                                  // );
                                                  event.preventDefault();
                                                  event.stopPropagation();
                                                  event.nativeEvent.stopImmediatePropagation();
                                                  toggleClass(navLink.text);
                                                  // console.log(
                                                  //   "subMenuIndex",
                                                  //   subMenuHoverIndex
                                                  // );
                                                  dispatch({
                                                    type: "submenu::toggle",
                                                    header: link.text,
                                                    leaf: navLink.text,
                                                  });
                                                }}
                                              >
                                                {navLink.text}
                                                <DropdownArrow />{" "}
                                              </span>

                                              <SubmenuWrapper2>
                                                {((isSmall &&
                                                  navLink.showLeaf) ||
                                                  !isSmall) && (
                                                  <>
                                                    <DropDownContent
                                                      top={
                                                        navLink.type &&
                                                        navLink.type ===
                                                          "full-length-submenu"
                                                          ? `-${
                                                              subMenuHoverIndex *
                                                              subMenuTop
                                                            }px !important`
                                                          : "auto"
                                                      }
                                                      // height={`${link.maxItems * 36
                                                      //   }px !important`}
                                                      // height={`${
                                                      //   document.getElementById(
                                                      //     `submenu_ul-${
                                                      //       levelOneMenuIndex +
                                                      //       1
                                                      //     }`
                                                      //   )
                                                      //     ? document.getElementById(
                                                      //         `submenu_ul-${
                                                      //           levelOneMenuIndex +
                                                      //           1
                                                      //         }`
                                                      //       )
                                                      //     : link.subMenu
                                                      //         .length * 36
                                                      // }px !important`}
                                                      flexDirection="row"
                                                    >
                                                      {splitSmaller(
                                                        navLink.leaf,
                                                        navLink.type &&
                                                          navLink.type ===
                                                            "full-length-submenu"
                                                          ? navLink.maxItems
                                                          : navLink.leaf.length
                                                      ).map(
                                                        (
                                                          item,
                                                          sectionIndex
                                                        ) => {
                                                          // console.log(
                                                          //   "DOING ::: 3", item
                                                          // );

                                                          return (
                                                            <SubMenuSection className="section-header">
                                                              {item.map(
                                                                (
                                                                  leaf,
                                                                  index
                                                                ) => (
                                                                  <>
                                                                    <SubMenu
                                                                      href={leaf.link.trim()}
                                                                      key={
                                                                        leaf.link
                                                                      }
                                                                    >
                                                                      {leaf.type &&
                                                                      leaf.type.toLowerCase() ===
                                                                        "domain-landing-page-redirect-button" ? (
                                                                        <div className="header_button_style">
                                                                          {
                                                                            leaf.text
                                                                          }
                                                                        </div>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            leaf.text
                                                                          }
                                                                          {leaf.new ? (
                                                                            <sup className="header-new-launch font-section-small-signup-form">
                                                                              Beta
                                                                            </sup>
                                                                          ) : null}{" "}
                                                                          {leaf.comingsoon ? (
                                                                            <sup className="header-new-launch-soon font-section-small-signup-form">
                                                                              Coming
                                                                              soon
                                                                            </sup>
                                                                          ) : null}
                                                                        </>
                                                                      )}
                                                                    </SubMenu>
                                                                  </>
                                                                )
                                                              )}
                                                            </SubMenuSection>
                                                          );
                                                        }
                                                      )}
                                                    </DropDownContent>
                                                  </>
                                                )}
                                              </SubmenuWrapper2>
                                            </SubMenu>
                                          </>
                                        ) : (
                                          <SubMenu
                                            href={navLink.link.trim()}
                                            key={levelOneMenuIndex}
                                          >
                                            {" "}
                                            {navLink.text.toLowerCase() ===
                                              "all integrations" ||
                                            navLink.text.toLowerCase() ===
                                              "all apps" ||
                                            navLink.text.toLowerCase() ===
                                              "all integration" ? (
                                              <div className="header_button_style">
                                                {navLink.text}
                                              </div>
                                            ) : (
                                              <>{navLink.text}</>
                                            )}
                                          </SubMenu>
                                        )
                                    )}
                                  </DropDownContent>
                                )}
                              </DropDownLi>
                            ) : isAutomatePage ? (
                              <StyledLi className={`autoamte_header_top`}>
                                <NavbaraLi href={link.link.trim()}>
                                  {link.text}
                                </NavbaraLi>
                              </StyledLi>
                            ) : (
                              <StyledLi className={`fff`}>
                                <NavbaraLi href={link.link.trim()}>
                                  {link.text}
                                </NavbaraLi>
                              </StyledLi>
                            )
                          )}
                        </DropDownUl>
                      </NavbarWrapper>
                    ) : null}
                    {isAutomatePage ? (
                      <>
                        {/* <Link
                          className="url_manipulation"
                          href="https://automate.workativ.ai/"
                        >
                          <NavbarButton>Login</NavbarButton>
                        </Link> */}
                        <Link
                          className="url_manipulation navbar_button"
                          onClick={() => {
                            console.log("betaClick", betaClick);
                            betaClick && betaClick();
                          }}
                          // href="javascript:void()"
                        >
                          <NavbarButton
                            style={{
                              background: "#2468F6",
                              color: "#fff",
                              marginLeft: 15,
                              texttransform: "none",
                            }}
                            className="button_navbar_automate"
                          >
                            Get Started FREE
                          </NavbarButton>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          className="url_manipulation"
                          href="https://assistant.workativ.com"
                        >
                          <NavbarButton>Login</NavbarButton>
                        </Link>
                        <Link
                          className="url_manipulation navbar_button"
                          onClick={() => {
                            redirectToWithUtm(
                              "https://assistant.workativ.com/authentication/u/direct-signup"
                            );
                          }}
                          href="#"
                        >
                          <NavbarButton
                            style={{
                              background: "#2468F6",
                              color: "#fff",
                              marginLeft: 15,
                            }}
                          >
                            FREE Bot
                          </NavbarButton>
                        </Link>
                      </>
                    )}
                  </NavbarRight>
                ) : null}
              </Navbar>
            </Row>
          </Container>
        </div>
      ) : null}

      {isAutomatePage ? isAutomatePopupScroll : null}
      {/* {isAutomatePage ? <CookiesPopupHeader /> : null} */}
    </HeaderWrapper>
  );
}
export function AssistantHeaderMobile({
  backgroundColor,
  logoFor,
  logoAction,
  hideMobileNavButton,
  betaClick,
}) {
  const [isOpen, setOpen] = useState(false);
  const isSmall = useMedia({ maxWidth: "1024px" });

  const [showMore, setShowMore] = useState("hide");

  const [currentLeaf, setCurrentLeaf] = useState("");

  const isMobile = useMedia({ maxWidth: "1024px" });
  const is1440 = useMedia({ maxWidth: "1440px" });

  const [isActive, setActive] = useState("");

  const [subMenuHoverIndex, setSubmenuIndexOnHover] = React.useState(1);
  const [subMenuIndexReactive, setSubMenuIndexReactive] = React.useState(1);

  useEffect(() => {
    setInterval(() => {
      setSubMenuIndexReactive(() => subMenuIndexReactive + 1);
    }, 500);
  }, []);

  const toggleClass = (header) => {
    if (header == isActive) {
      setActive("");
    } else {
      setActive(header);
    }
  };

  const [sectionList, dispatch] = useReducer(
    openCloseReducer,
    headerList(logoFor)
  );
  const [sectionCommonList, dispatchCommon] = useReducer(
    openCloseReducerCommon,
    headerCommonList(logoFor)
  );
  const headerCommmonClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatchCommon({ type: "header::toggle", text: link.text });
  };
  const headerClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatch({ type: "header::toggle", text: link.text });
  };

  const isWorkativPage =
    logoFor == "WORKATIV" || logoFor == undefined || logoFor == null;

  const isAutomatePage =
    logoFor == "AUTOMATE" || logoFor == undefined || logoFor == null;

  let subMenuTop = is1440 ? 33 : 36;

  console.log("DOING ::: 0 ::: subMenuIndex", isAutomatePage);
  const isAutomatePopupScroll = isSmall ? null : (
    <ScrollPopUpForAutomatePages />
  );
  return (
    <HeaderWrapper
      fullScreen={isSmall && isOpen}
      paddingTop={isWorkativPage && isOpen}
    >
      <div
        className={
          isWorkativPage
            ? isSmall && isOpen
              ? "top_header_open _mob_header"
              : "top_header header res_header home_header"
            : isSmall && isOpen
            ? "top_header_open"
            : "top_header header res_header"
        }
      >
        <Container className="container">
          <TopHeaderFixed>
            <NavbarTop>
              <Link
                style={{
                  pointerEvents:
                    logoAction && logoAction === "NONE" ? "none" : "auto",
                  cursor:
                    logoAction && logoAction === "NONE" ? "auto" : "pointer",
                }}
                href={logoAction && logoAction === "NONE" ? "#" : "/"}
              >
                {" "}
                <NavbarTopLogo
                  src={wlogo}
                  alt="workativ"
                  className="pr-4"
                ></NavbarTopLogo>
              </Link>
              <NavbarMobMButton hideMobileNavButton={hideMobileNavButton}>
                <NavbarIcon onClick={(_) => setOpen(!isOpen)}>
                  {isSmall && isOpen ? <CloseIcon /> : <OpenIcon />}
                </NavbarIcon>
              </NavbarMobMButton>
            </NavbarTop>
            {isSmall ? (
              <div
                className={
                  (isSmall && isOpen) || !isSmall
                    ? "mobile_navbartop_wrapper"
                    : null
                }
              >
                <NavbartopWrapper className="mobile_navbartop">
                  <div className="mob_menu_li">
                    {(isSmall && isOpen) || !isSmall ? (
                      <NavbarProduct className="NavbarProduct">
                        <NavbarWrapper>
                          <DropDownUl>
                            {sectionCommonList.map((link, i) =>
                              link.subMenu && link.subMenu.length > 0 ? (
                                <DropDownLi className={`sticky_pad_0`} key={i}>
                                  <span
                                    className={` font-section-normal-text-testimonials-medium
                                      ${
                                        link.text == isActive && isOpen
                                          ? `${"svg_roate_icon svg_roate_header"}`
                                          : "svg_roate_header"
                                      }
                                    `}
                                    onClick={(event) => {
                                      headerCommmonClick(link, event);
                                      toggleClass(link.text);
                                    }}
                                  >
                                    {link.text}
                                    <DropdownArrow />
                                  </span>
                                  {((isSmall && link.showSubmenu) ||
                                    !isSmall) && (
                                    <DropDownContent>
                                      {link.subMenu.map((navLink, index) =>
                                        navLink.leaf &&
                                        navLink.leaf.length > 0 ? (
                                          <SubMenu
                                            className="submenu_ul"
                                            // href="#"
                                            key={index}
                                          >
                                            <span
                                              className={
                                                navLink.text == isActive &&
                                                isOpen
                                                  ? `${"svg_roate_icon_inside"}`
                                                  : ""
                                              }
                                              onClick={(event) => {
                                                // console.log("submenu :: event");
                                                event.preventDefault();
                                                event.stopPropagation();
                                                event.nativeEvent.stopImmediatePropagation();
                                                toggleClass(navLink.text);
                                                dispatchCommon({
                                                  type: "submenu::toggle",
                                                  header: link.text,
                                                  leaf: navLink.text,
                                                });
                                              }}
                                            >
                                              {navLink.text} <DropdownArrow />{" "}
                                            </span>
                                            <SubmenuWrapper>
                                              {((isSmall && navLink.showLeaf) ||
                                                !isSmall) && (
                                                <DropDownContent>
                                                  {navLink.leaf.map((leaf) => (
                                                    <SubMenu
                                                      href={leaf.link.trim()}
                                                      key={leaf.link}
                                                    >
                                                      {leaf.text}
                                                    </SubMenu>
                                                  ))}
                                                </DropDownContent>
                                              )}
                                            </SubmenuWrapper>
                                          </SubMenu>
                                        ) : (
                                          <>
                                            <div className="home_menu_top">
                                              <SubMenu
                                                href={navLink.link.trim()}
                                                key={index}
                                              >
                                                {navLink.image ? (
                                                  isMobile ? (
                                                    <div className="header_menu">
                                                      <span className="second_p">
                                                        {navLink.mobImage}
                                                      </span>
                                                      {/* <img
                                                  className="second_p"
                                                  src={navLink.mobImage}
                                                /> */}
                                                      <div className="mob_link_menu">
                                                        <span>
                                                          {navLink.text}
                                                        </span>
                                                        {navLink.content && (
                                                          <p className="second_p">
                                                            {navLink.content}
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="header_menu">
                                                      <img
                                                        className="second_p"
                                                        src={navLink.image}
                                                      />{" "}
                                                      <span>
                                                        {navLink.text}
                                                      </span>
                                                      {navLink.content && (
                                                        <p className="second_p">
                                                          {navLink.content}
                                                        </p>
                                                      )}
                                                    </div>
                                                  )
                                                ) : (
                                                  <div className="header_menu first_child">
                                                    <span className="font-header-menu-bold-small">
                                                      {navLink.text}
                                                    </span>
                                                    {navLink.content && (
                                                      <p className="second_p">
                                                        {navLink.content}
                                                      </p>
                                                    )}
                                                  </div>
                                                )}
                                              </SubMenu>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </DropDownContent>
                                  )}
                                </DropDownLi>
                              ) : (
                                <StyledLi className={`fff`}>
                                  <NavbaraLi href={link.link.trim()}>
                                    {link.text}
                                  </NavbaraLi>
                                </StyledLi>
                              )
                            )}
                          </DropDownUl>
                        </NavbarWrapper>
                      </NavbarProduct>
                    ) : null}
                    {(isSmall && isOpen) || !isSmall ? (
                      <LinkWrapper className="linkwrapper">
                        {" "}
                        <StyledLink href="https://calendly.com/workativ/workativ-assistant-demo-page">
                          Book Demo
                        </StyledLink>
                      </LinkWrapper>
                    ) : null}
                    {(isSmall && isOpen) || !isSmall ? (
                      <LinkWrapper className="linkwrapper">
                        <Link href="/contact-us">Contact us</Link>
                      </LinkWrapper>
                    ) : null}
                  </div>
                  <NavbarLogoMobMain className="navbarlogo_main">
                    <NavbarLogoMob className="navbarlogo_img">
                      <NavbarLogoWrapper>
                        <Link className="url_manipulation" href={href(logoFor)}>
                          {getLogoComponent(logoFor)}
                        </Link>
                      </NavbarLogoWrapper>
                    </NavbarLogoMob>
                  </NavbarLogoMobMain>
                  {!isWorkativPage ? (
                    <div
                      className={
                        isSmall && isOpen
                          ? "mob_header_wrapper"
                          : `${"header res_header"} ${
                              backgroundColor ? backgroundColor : ""
                            }`
                      }
                    >
                      <Container className="container">
                        <Row className="row">
                          <Navbar className={`landing_page_head`}>
                            <NavbarLeft className={`sticky_pad nav-bar-left`}>
                              {(isSmall && isOpen) || isSmall ? (
                                <NavbarLogoMob>
                                  <NavbarLogoWrapper className="navbarlogo_img test">
                                    <Link
                                      className="url_manipulation"
                                      href={href(logoFor)}
                                    >
                                      {isSmall && isOpen
                                        ? getLogoComponentMobile(logoFor)
                                        : getLogoComponent(logoFor)}
                                    </Link>
                                  </NavbarLogoWrapper>
                                </NavbarLogoMob>
                              ) : null}
                            </NavbarLeft>
                            {(isSmall && isOpen) || !isSmall ? (
                              <NavbarRight className="navbar_right">
                                {(isSmall && isOpen) || !isSmall ? (
                                  <NavbarWrapper>
                                    <DropDownUl>
                                      {sectionList.map((link, i) =>
                                        link.subMenu &&
                                        link.subMenu.length > 0 ? (
                                          <DropDownLi
                                            className={`sticky_pad_0`}
                                            key={i}
                                          >
                                            <span
                                              className={` font-section-normal-text-testimonials-medium
                                              ${
                                                link.text === isActive
                                                  ? `${"svg_roate_icon svg_roate_header"}`
                                                  : "svg_roate_header"
                                              }
                                            `}
                                              onClick={(event) => {
                                                headerClick(link, event);
                                                toggleClass(link.text);
                                                setTimeout(() => {
                                                  event.target.scrollIntoView({
                                                    behavior: "smooth",
                                                  });
                                                }, 100);
                                              }}
                                            >
                                              {link.text}
                                              <DropdownArrow />
                                            </span>
                                            {((isSmall && link.showSubmenu) ||
                                              !isSmall) && (
                                              <DropDownContent heightfit={true}>
                                                {link.text == "Marketplace" ? (
                                                  <MarketplaceMobile />
                                                ) : null}
                                                {link.subMenu.map(
                                                  (navLink, index) =>
                                                    navLink.leaf &&
                                                    navLink.leaf.length > 0 ? (
                                                      <SubMenu
                                                        className="submenu_ul"
                                                        key={index}
                                                      >
                                                        <span
                                                          className={` font-header-menu-bold-small sub-name-header-menu
                                                          ${
                                                            navLink.text ===
                                                              isActive && isOpen
                                                              ? `${"svg_roate_icon_inside svg_roate_header"}`
                                                              : "svg_roate_header "
                                                          }
                                                        `}
                                                          onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            event.nativeEvent.stopImmediatePropagation();
                                                            toggleClass(
                                                              navLink.text
                                                            );
                                                            dispatch({
                                                              type:
                                                                "submenu::toggle",
                                                              header: link.text,
                                                              leaf:
                                                                navLink.text,
                                                            });
                                                            setTimeout(() => {
                                                              event.target.scrollIntoView(
                                                                {
                                                                  behavior:
                                                                    "smooth",
                                                                }
                                                              );
                                                            }, 100);

                                                            // console.log("event", event.target.scrollIntoView())
                                                          }}
                                                        >
                                                          {navLink.text}
                                                          <DropdownArrow />{" "}
                                                        </span>
                                                        <SubmenuWrapper
                                                          className={
                                                            link.text ===
                                                            "Chatbot Integrations"
                                                              ? "SubmenuWrapper Chatbot_Integrations"
                                                              : "SubmenuWrapper"
                                                          }
                                                        >
                                                          {((isSmall &&
                                                            navLink.showLeaf) ||
                                                            !isSmall) && (
                                                            <>
                                                              <DropDownContent
                                                                className="dropdown_li"
                                                                heightfit={true}
                                                              >
                                                                {link.text ===
                                                                "Chatbot Integrations" ? (
                                                                  navLink.leaf
                                                                    .filter(
                                                                      (
                                                                        leaf,
                                                                        index
                                                                      ) =>
                                                                        currentLeaf.indexOf(
                                                                          navLink.text
                                                                        ) !==
                                                                          -1 ||
                                                                        index <
                                                                          3
                                                                    )
                                                                    .map(
                                                                      (
                                                                        leaf
                                                                      ) => (
                                                                        <SubMenu
                                                                          href={leaf.link.trim()}
                                                                          key={
                                                                            leaf.link
                                                                          }
                                                                          className="font-header-menu-medium-small"
                                                                        >
                                                                          {
                                                                            leaf.text
                                                                          }
                                                                          {leaf.new ? (
                                                                            <sup className="header-new-launch font-section-small-signup-form">
                                                                              Beta
                                                                            </sup>
                                                                          ) : null}
                                                                          {leaf.comingsoon ? (
                                                                            <sup className="header-new-launch-soon font-section-small-signup-form">
                                                                              Coming
                                                                              soon
                                                                            </sup>
                                                                          ) : null}
                                                                        </SubMenu>
                                                                      )
                                                                    )
                                                                ) : navLink.text ===
                                                                  "By Features" ? (
                                                                  <ByFeaturesMobile />
                                                                ) : navLink.text ===
                                                                  "By Usecases" ? (
                                                                  <UsecasesMobile />
                                                                ) : navLink.text ===
                                                                  "By Company Size" ? (
                                                                  <CompanySizeMobile />
                                                                ) : (
                                                                  navLink.leaf
                                                                    .filter(
                                                                      (
                                                                        leaf,
                                                                        index
                                                                      ) =>
                                                                        currentLeaf.indexOf(
                                                                          navLink.text
                                                                        ) ||
                                                                        index
                                                                    )
                                                                    .map(
                                                                      (
                                                                        leaf
                                                                      ) => (
                                                                        <SubMenu
                                                                          href={leaf.link.trim()}
                                                                          key={
                                                                            leaf.link
                                                                          }
                                                                          className="font-header-menu-medium-small"
                                                                        >
                                                                          {
                                                                            leaf.text
                                                                          }
                                                                          {leaf.new ? (
                                                                            <sup className="header-new-launch font-section-small-signup-form">
                                                                              Beta
                                                                            </sup>
                                                                          ) : null}{" "}
                                                                          {leaf.comingsoon ? (
                                                                            <sup className="header-new-launch-soon font-section-small-signup-form">
                                                                              Coming
                                                                              soon
                                                                            </sup>
                                                                          ) : null}
                                                                        </SubMenu>
                                                                      )
                                                                    )
                                                                )}

                                                                {navLink.leaf
                                                                  .length > 3 &&
                                                                link.text ===
                                                                  "Chatbot Integrations" &&
                                                                currentLeaf.indexOf(
                                                                  navLink.text
                                                                ) === -1 ? (
                                                                  <button
                                                                    onClick={(
                                                                      event
                                                                    ) => {
                                                                      event.preventDefault();
                                                                      event.stopPropagation();
                                                                      setShowMore(
                                                                        "show"
                                                                      );
                                                                      setCurrentLeaf(
                                                                        [
                                                                          ...currentLeaf,
                                                                          navLink.text,
                                                                        ]
                                                                      );
                                                                    }}
                                                                    className="font-header-menu-medium-small"
                                                                  >
                                                                    Show all{" "}
                                                                    {
                                                                      navLink.text
                                                                    }{" "}
                                                                    apps
                                                                  </button>
                                                                ) : null}
                                                              </DropDownContent>
                                                            </>
                                                          )}
                                                        </SubmenuWrapper>
                                                      </SubMenu>
                                                    ) : link.text ===
                                                      "Marketplace" ? null : (
                                                      <SubMenu
                                                        className="no_children_li font-header-menu-bold-small"
                                                        href={navLink.link.trim()}
                                                        key={index}
                                                      >
                                                        {navLink.text}
                                                      </SubMenu>
                                                    )
                                                )}
                                              </DropDownContent>
                                            )}
                                          </DropDownLi>
                                        ) : (
                                          <StyledLi className={`ul_li_wrapper`}>
                                            <NavbaraLi
                                              href={link.link.trim()}
                                              className="font-section-normal-text-testimonials-medium"
                                            >
                                              {link.text}
                                            </NavbaraLi>
                                          </StyledLi>
                                        )
                                      )}
                                    </DropDownUl>
                                  </NavbarWrapper>
                                ) : null}

                                <Link
                                  className="url_manipulation"
                                  href="https://assistant.workativ.com"
                                >
                                  <NavbarButton className="font-section-normal-text-testimonials-medium">
                                    Login
                                  </NavbarButton>
                                </Link>
                                <Link
                                  className="url_manipulation navbar_button free-bot-mobile-header"
                                  onClick={() => {
                                    redirectToWithUtm(
                                      "https://assistant.workativ.com/authentication/u/direct-signup"
                                    );
                                  }}
                                  href="#"
                                >
                                  <NavbarButton
                                    style={{
                                      background: "#2468F6",
                                      color: "#fff",
                                      marginLeft: 15,
                                    }}
                                    className="font-section-normal-text-testimonials-medium"
                                  >
                                    FREE Bot
                                  </NavbarButton>
                                </Link>
                              </NavbarRight>
                            ) : null}
                          </Navbar>
                        </Row>
                      </Container>
                    </div>
                  ) : null}
                </NavbartopWrapper>
              </div>
            ) : null}
          </TopHeaderFixed>
        </Container>
      </div>
    </HeaderWrapper>
  );
}
export function AssistantHeader({
  backgroundColor,
  logoFor,
  logoAction,
  hideMobileNavButton,
  betaClick,
}) {
  const [isOpen, setOpen] = useState(false);
  const isSmall = useMedia({ maxWidth: "1024px" });

  const isMobile = useMedia({ maxWidth: "1024px" });
  const is1440 = useMedia({ maxWidth: "1440px" });

  const [isActive, setActive] = useState("");
  const [subMenuIndexReactive, setSubMenuIndexReactive] = React.useState(1);

  useEffect(() => {
    setInterval(() => {
      setSubMenuIndexReactive(() => subMenuIndexReactive + 1);
    }, 500);
  }, []);

  const toggleClass = (header) => {
    if (header == isActive) {
      setActive("");
    } else {
      setActive(header);
    }
  };

  const [sectionList, dispatch] = useReducer(
    openCloseReducer,
    headerList(logoFor)
  );
  const [sectionCommonList, dispatchCommon] = useReducer(
    openCloseReducerCommon,
    headerCommonList(logoFor)
  );
  const headerCommmonClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatchCommon({ type: "header::toggle", text: link.text });
  };
  const headerClick = (link, event) => {
    // console.log("header :: event");
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    dispatch({ type: "header::toggle", text: link.text });
  };

  const isWorkativPage =
    logoFor == "WORKATIV" || logoFor == undefined || logoFor == null;

  const isAutomatePage =
    logoFor == "AUTOMATE" || logoFor == undefined || logoFor == null;

  let subMenuTop = is1440 ? 33 : 36;

  console.log("DOING ::: 0 ::: subMenuIndex", isAutomatePage);
  const isAutomatePopupScroll = isSmall ? null : (
    <ScrollPopUpForAutomatePages />
  );
  return (
    <HeaderWrapper
      fullScreen={isSmall && isOpen}
      paddingTop={isWorkativPage && isOpen}
    >
      <div
        className={
          isWorkativPage
            ? isSmall && isOpen
              ? "top_header_open _mob_header"
              : "top_header header res_header home_header"
            : isSmall && isOpen
            ? "top_header_open"
            : "top_header header res_header"
        }
      >
        <Container className="container">
          <TopHeaderFixed>
            <NavbarTop>
              <Link
                style={{
                  pointerEvents:
                    logoAction && logoAction === "NONE" ? "none" : "auto",
                  cursor:
                    logoAction && logoAction === "NONE" ? "auto" : "pointer",
                }}
                href={logoAction && logoAction === "NONE" ? "#" : "/"}
              >
                {" "}
                <NavbarTopLogo
                  src={wlogo}
                  alt="workativ"
                  className="pr-4"
                ></NavbarTopLogo>
              </Link>
              <NavbarMobMButton hideMobileNavButton={hideMobileNavButton}>
                <NavbarIcon onClick={(_) => setOpen(!isOpen)}>
                  {isSmall && isOpen ? <CloseIcon /> : <OpenIcon />}
                </NavbarIcon>
              </NavbarMobMButton>
            </NavbarTop>

            <NavbartopWrapper>
              {(isSmall && isOpen) || !isSmall ? (
                <NavbarProduct className="NavbarProduct">
                  <NavbarWrapper>
                    <DropDownUl>
                      {sectionCommonList.map((link, i) =>
                        link.subMenu && link.subMenu.length > 0 ? (
                          <DropDownLi className={`sticky_pad_0`} key={i}>
                            <span
                              className={
                                link.text == isActive && isOpen
                                  ? `${"svg_roate_icon svg_roate_header"}`
                                  : "svg_roate_header"
                              }
                              onClick={(event) => {
                                headerCommmonClick(link, event);
                                toggleClass(link.text);
                              }}
                            >
                              {link.text}
                              <DropdownArrow />
                            </span>
                            {((isSmall && link.showSubmenu) || !isSmall) && (
                              <DropDownContent>
                                {link.subMenu.map((navLink, index) =>
                                  navLink.leaf && navLink.leaf.length > 0 ? (
                                    <SubMenu
                                      className="submenu_ul"
                                      href="#"
                                      key={index}
                                    >
                                      <span
                                        className={
                                          navLink.text == isActive && isOpen
                                            ? `${"svg_roate_icon_inside"}`
                                            : ""
                                        }
                                        onClick={(event) => {
                                          // console.log("submenu :: event");s
                                          event.preventDefault();
                                          event.stopPropagation();
                                          event.nativeEvent.stopImmediatePropagation();
                                          toggleClass(navLink.text);
                                          dispatchCommon({
                                            type: "submenu::toggle",
                                            header: link.text,
                                            leaf: navLink.text,
                                          });
                                        }}
                                      >
                                        {navLink.text} <DropdownArrow />{" "}
                                      </span>
                                      <SubmenuWrapper>
                                        {((isSmall && navLink.showLeaf) ||
                                          !isSmall) && (
                                          <DropDownContent>
                                            {navLink.leaf.map((leaf) => (
                                              <SubMenu
                                                href={leaf.link.trim()}
                                                key={leaf.link}
                                              >
                                                {leaf.text}
                                              </SubMenu>
                                            ))}
                                          </DropDownContent>
                                        )}
                                      </SubmenuWrapper>
                                    </SubMenu>
                                  ) : (
                                    <>
                                      <div className="home_menu_top">
                                        <SubMenu
                                          href={navLink.link.trim()}
                                          key={index}
                                        >
                                          {navLink.image ? (
                                            isMobile ? (
                                              <div className="header_menu">
                                                <img
                                                  className="second_p"
                                                  src={navLink.image}
                                                />{" "}
                                                <div className="mob_link_menu">
                                                  <span>{navLink.text}</span>
                                                  {navLink.content && (
                                                    <p className="second_p">
                                                      {navLink.content}
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="header_menu">
                                                <span className="second_p">
                                                  {navLink.image}
                                                </span>{" "}
                                                <span className="header_product_name">
                                                  {navLink.text}
                                                </span>
                                                {navLink.content && (
                                                  <p className="second_p">
                                                    {navLink.content}
                                                  </p>
                                                )}
                                              </div>
                                            )
                                          ) : (
                                            <div className="header_menu first_child">
                                              <span>{navLink.text}</span>
                                              {navLink.content && (
                                                <p className="second_p">
                                                  {navLink.content}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </SubMenu>
                                      </div>
                                    </>
                                  )
                                )}
                              </DropDownContent>
                            )}
                          </DropDownLi>
                        ) : (
                          <StyledLi className={`fff`}>
                            <NavbaraLi href={link.link.trim()}>
                              {link.text}
                            </NavbaraLi>
                          </StyledLi>
                        )
                      )}
                    </DropDownUl>
                  </NavbarWrapper>
                </NavbarProduct>
              ) : null}
              {(isSmall && isOpen) || !isSmall ? (
                <LinkWrapper className="linkwrapper">
                  {" "}
                  {isAutomatePage ? (
                    <StyledLink href="https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/">
                      Book Demo
                    </StyledLink>
                  ) : (
                    <StyledLink href="https://calendly.com/workativ/workativ-assistant-demo-page">
                      Book Demo
                    </StyledLink>
                  )}
                  <Link href="/contact-us">Contact Us</Link>
                </LinkWrapper>
              ) : null}
              {/* {(isSmall && isOpen) || !isSmall ? (
                  <LinkWrapper className="linkwrapper">
                    {" "}

                  </LinkWrapper>
                ) : null} */}
              <NavbarLogoMobMain className="navbarlogo_main">
                <NavbarLogoMob className="navbarlogo_img">
                  <NavbarLogoWrapper>
                    <Link className="url_manipulation" href={href(logoFor)}>
                      {getLogoComponent(logoFor)}
                    </Link>
                  </NavbarLogoWrapper>
                </NavbarLogoMob>
              </NavbarLogoMobMain>
            </NavbartopWrapper>
          </TopHeaderFixed>
        </Container>
      </div>

      {isSmall ? null : !isWorkativPage ? (
        <div
          className={
            isSmall && isOpen
              ? ""
              : `${"header res_header"} ${
                  backgroundColor ? backgroundColor : ""
                }`
          }
        >
          <Container
            className="container"
            onMouseLeave={(event) => {
              // headerClick(link, event);
              // toggleClass(link.text);
              setActive("");
            }}
          >
            <Row className="row">
              <Navbar className={`landing_page_head`}>
                <NavbarLeft
                  className={`sticky_pad nav-bar-left`}
                  onMouseEnter={(event) => {
                    setActive("");
                  }}
                >
                  {(isSmall && isOpen) || !isSmall ? (
                    <NavbarLogoMob>
                      <NavbarLogoWrapper className="navbarlogo_img">
                        <Link className="url_manipulation" href={href(logoFor)}>
                          {getLogoComponent(logoFor)}
                          {/* {setSubMenuIndexReactive} */}
                        </Link>
                      </NavbarLogoWrapper>
                    </NavbarLogoMob>
                  ) : null}
                </NavbarLeft>
                {(isSmall && isOpen) || !isSmall ? (
                  <NavbarRight className="navbar_right">
                    {(isSmall && isOpen) || !isSmall ? (
                      <NavbarWrapper>
                        <DropDownUl>
                          {/* Secondary menu desktop */}
                          {console.log("link.text", isActive)}
                          {sectionList.map((link, i) =>
                            link.subMenu && link.subMenu.length > 0 ? (
                              <>
                                <DropDownLi
                                  className={`sticky_pad_0 `}
                                  key={i}
                                  paddingno={true}
                                  marginyes={true}
                                >
                                  <div className="d-flex flex-column align-items-center">
                                    <span
                                      className={
                                        link.text == isActive
                                          ? `${"svg_roate_icon color-blue fill-blue"}`
                                          : ""
                                      }
                                      onMouseEnter={(event) => {
                                        headerClick(link, event);
                                        toggleClass(link.text);
                                        setActive(link.text);
                                      }}
                                    >
                                      {link.text}
                                      <DropdownArrow
                                        className={
                                          link.text == isActive
                                            ? `${"fill-blue"}`
                                            : ""
                                        }
                                        defaultcolor={true}
                                      />
                                    </span>
                                    {isActive == "Why Workativ" ||
                                    isActive == "Chatbot Integrations" ? (
                                      <span
                                        className={
                                          link.text == isActive
                                            ? `${"arrow-header-menu-active"}`
                                            : `${"arrow-header-menu-deactive"}`
                                        }
                                      ></span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {isActive == "Marketplace" ||
                                  isActive == "Learn" ? (
                                    <DropDownContentNew
                                      className={`drop-down-box-small-menu ${
                                        isActive == "Learn"
                                          ? "drop-down-box-small-menu-learn"
                                          : ""
                                      } `}
                                    >
                                      {isActive == "Marketplace" ? (
                                        <MarketPlace />
                                      ) : null}
                                      {isActive == "Learn" ? <Learn /> : null}
                                    </DropDownContentNew>
                                  ) : null}
                                </DropDownLi>
                              </>
                            ) : (
                              <StyledLi className={`fff`}>
                                <NavbaraLi
                                  href={link.link.trim()}
                                  onMouseEnter={(event) => {
                                    setActive("");
                                  }}
                                >
                                  {link.text}
                                </NavbaraLi>
                              </StyledLi>
                            )
                          )}
                        </DropDownUl>
                      </NavbarWrapper>
                    ) : null}

                    <>
                      <Link
                        className="url_manipulation"
                        href="https://assistant.workativ.com"
                        onMouseEnter={(event) => {
                          setActive("");
                        }}
                      >
                        <NavbarButton>Login</NavbarButton>
                      </Link>
                      <Link
                        className="url_manipulation navbar_button"
                        onClick={() => {
                          redirectToWithUtm(
                            "https://assistant.workativ.com/authentication/u/direct-signup"
                          );
                        }}
                        href="#"
                        onMouseEnter={(event) => {
                          setActive("");
                        }}
                      >
                        <NavbarButton
                          style={{
                            background: "#2468F6",
                            color: "#fff",
                            marginLeft: 15,
                          }}
                        >
                          FREE Bot
                        </NavbarButton>
                      </Link>
                    </>
                  </NavbarRight>
                ) : null}

                <DropDownContentNew
                  style={{ display: "flex" }}
                  className="drop-down-box-mega-menu"
                >
                  {isActive == "Why Workativ" ? <WhyWorkativ /> : null}
                  {isActive == "Chatbot Integrations" ? (
                    <ChatbotIntegration />
                  ) : null}
                  {/* {isActive == "Marketplace" ? (
                    <MarketPlace />
                  ) : null}
                   {isActive == "Learn" ? (
                    <Learn/>
                  ) : null} */}
                </DropDownContentNew>
              </Navbar>
            </Row>
          </Container>
        </div>
      ) : null}

      {/* {isAutomatePage ? <CookiesPopupHeader /> : null} */}
    </HeaderWrapper>
  );
}
const DropDownUl = styled.ul`
  padding-left: 0px;
  margin: 0px;
  width: 100%;
  float: left;
  position: relative;
  top: 2px;

  @media (max-width: 1024px) {
    width: 100%;
    float: left;
    display: flex;
    padding-left: 0px;
    flex-direction: column;
    margin-top: 0px;
    overflow-y: scroll;
  }
`;

const StyledLi = styled.li`
  float: left;
  position: relative;
  list-style: none;
  padding-right: ${(props) => (props.paddingno ? "0" : "20px")};
  margin-right: ${(props) => (props.marginyes ? "20px" : "0")};
  position: relative;
  font-size: 15px;
  font-family: "Gordita-Medium";
  color: #333;
  top: 8px;
  &:hover {
    color: #2468f6;
    fill: #2468f6;
  }
  & svg {
    margin-left: 6px;
    width: 12px;
    height: 12px;
    transform: rotate(0deg);
  }
  :hover svg {
    fill: #2468f6;
    transform: rotate(180deg);
  }
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 18px;
  }
  @media (min-width: 2058px) and (max-width: 2560px) {
    font-size: 28px;
  }
  @media (max-width: 1024px) {
    width: max-content;
    float: left;
    display: block;
    padding-bottom: 15px;
    padding-left: 0px;
    font-size: 18px;
    top: 0px;

    & svg {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }
    :hover svg {
      fill: #2468f6;
      transform: rotate(0deg);
    }
  }
  @media (max-width: 1024px) {
    padding-right: 0px;
    svg {
      fill: #000;
      margin-left: 0px;
      width: 16px;
      height: 16px;
    }
    :hover svg {
      fill: #000;
      width: 16px;
      height: 16px;
    }
  }
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  flex-direction: ${(props) =>
    !props.flexDirection ? "column" : `${props.flexDirection}`};
  max-width: max-content;
  min-width: 200px;
  left: -30px;
  top: ${(props) => (!props.top ? "auto" : `${props.top}`)};
  height: ${(props) => (!props.height ? "auto" : `${props.height}`)};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 15px;
  z-index: 1;
  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    min-width: 300px;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 45%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-bottom: 15px solid #fff;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
  @media (max-width: 1024px) {
    position: relative;
    width: 100% !important;
    top: ${(props) => (!props.top ? "auto" : `${props.top}`)};
    margin-top: 15px;
    box-shadow: none;
    left: 0px;
    ::before {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    position: relative;
    width: 100% !important;
    top: ${(props) => (!props.top ? "auto" : `${props.top}`)};
    margin-top: 0px;
    box-shadow: none;
    max-width: 100%;
    max-height: ${(props) =>
      props.heightfit ? "fit-content" : "calc(100vh - 341px)"};
    overflow-y: scroll;
    left: 0px;
    background-color: transparent;
    ::before {
      display: none;
    }
  }
`;
const DropDownContentNew = styled.div`
  display: none;
  position: absolute;
  top: 89%;
  left: 0;
  width: 100%;
`;
const DropDownContentArrow = styled.div`
  position: relative;
  display: none;
`;
const SubmenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  float: left;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  justify-content: center;
  display: none;
  z-index: 1;

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    top: 0px;
    display: block;
    margin-top: 0px;
    box-shadow: none;
    left: 0;
    ::before {
      display: none;
    }
  }

  ${DropDownContent} {
    top: 0px;
    left: 0;
  }
  ${DropDownContent}:before {
    display: none;
  }
`;
const SubmenuWrapper2 = styled.div`
  position: absolute;
  top: 400;
  left: 100%;
  width: 100%;
  float: left;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  justify-content: center;
  display: none;
  z-index: 1;

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    top: 0px;
    display: block;
    margin-top: 0px;
    box-shadow: none;
    left: 0;
    ::before {
      display: none;
    }
  }

  ${DropDownContent} {
    top: -27px;
    left: -4px;
    background: rgb(255, 255, 255);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  ${DropDownContent}:before {
    display: none;
  }
`;

const DropDownLi = styled(StyledLi)`
  display: inline-block;
  color: #333;
  padding-bottom: 15px;
  position: relative;
  top: 8px;
  &:hover ${DropDownContent} {
    display: flex;
    width: max-content;
  }
  &:hover ${DropDownContentNew} {
    display: flex;
    width: max-content;
  }

  &:hover ${DropDownContentArrow} {
    display: flex;
    width: max-content;
  }
  @media (max-width: 1024px) {
    width: max-content;
    float: left;
    display: block;
    padding-bottom: 20px;

    ${DropDownContent} {
      display: block;
      width: max-content;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    float: left;
    display: block;
    padding-bottom: 0px;
    top: 0px;
  }
`;

const NavbaraLi = styled.a`
  display: inline-block;
  color: #333;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #2468f6;
  }
  @media (max-width: 1024px) {
    display: block;
    color: #333;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    float: left;
  }
`;
const SubMenu = styled.a`
  list-style: none;
  position: relative;
  font-size: 14px;
  font-family: "Gordita-Regular";
  line-height: 1.25;
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
  padding: 8px 15px;
  color: #333;
  display: flex;
  align-items: center;
  height: ${(props) => (!props.height ? "auto" : `${props.height}`)};

  @media (min-width: 2508px) and (max-width: 2560px) {
    line-height: 1.7;
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-left: 3px;
    transform: rotate(-90deg) !important;
    fill: #333 !important;
  }
  :hover {
    text-decoration: none;
    color: #2468f6;
    background-color: rgb(255, 255, 255);
  }
  @media (min-width: 2508px) and (max-width: 2560px) {
    font-size: 26px !important;
  }
  @media (min-width: 1800px) and (max-width: 2560px) {
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    display: block;
    background-color: #fff;
    padding: 8px 0px;
    font-size: 16px;

    :hover {
      display: block;
      background-color: #fff;
    }
    // :hover svg {
    //   transform: rotate(0deg) !important;
    // }
  }
  :hover svg {
    fill: #2468f6 !important;
  }
  :hover ${SubmenuWrapper} {
    display: flex;
  }

  :hover ~ ${SubmenuWrapper2} {
    display: flex;
  }
  :hover ${SubmenuWrapper2} {
    display: flex;
  }
  @media (max-width: 1024px) {
    :hover ${SubmenuWrapper} {
      display: block;
      margin-top: 0px;
    }
  }
  :first-child {
    padding-top: 12px;
  }
  :first-child:hover {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child:hover {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  @media (max-width: 1024px) {
    padding: 0px;
  }
  @media (max-width: 520px) {
    :first-child {
      padding-top: 0px;
    }
  }
`;

const SubMenuSection = styled.div`
  max-width: 270px;
  padding: 5px 10px;

  @media screen and (min-width: 2058px) and (max-width: 2560px) {
    max-width: 370px;
  }

  :nth-child(2)::before {
    content: "";
    width: 0;
    height: 92%;
    position: absolute;
    border: 0.5px solid #afafaf;
    top: 10px;
    left: 50%;
  }
`;

const NavbarProduct = styled.div`
  ${DropDownUl} {
    max-height: 100%;
    @media (max-width: 1024px) {
      margin-top: 0px;
    }
    ${DropDownLi}:first-child {
      ${DropDownContent} {
        margin-left: -70px;
        @media (max-width: 1024px) {
          margin-left: 0px;
        }
      }
    }
  }
`;

const NavbarLogoMob = styled.div`
width:100%;
float;left;
`;
const NavbarLogoMobMain = styled.div`
width:100%;
float;left;
display:none;
padding-top:15px;
@media (max-width: 1024px) {
  display:block;
}
`;

const Container = styled.div`
  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

const splitSmaller = (array, lengthToSplit) => {
  var result = [],
    chunk;
  var dup = JSON.parse(JSON.stringify(array));

  while (dup.length > 0) {
    chunk = dup.splice(0, lengthToSplit ? lengthToSplit : 10);

    result.push(chunk);
  }
  // console.log("DPING :: splitSmaller", result);
  return result;
};
export function CookiesPopupHeader() {
  const COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
    "COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY";

  const [popupVisiblity, setPopupVisiblity] = useState(true);

  const POPUP_EXPIRY_IN_MINUTES = 50 * 60 * 1000;

  const onClickClose = () => {
    setPopupVisiblity(false);
    setLocalStorageWithExpiry(
      COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
      { route: window.location.href, componentId: uniqueId },
      POPUP_EXPIRY_IN_MINUTES
    );
  };

  const uniqueId = useUniqueId();

  // console.log("GoToPopup :: storedKey", uniqueId)

  const storedKey = getLocalStorageWithExpiry(
    COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
  );

  const cookiePopupShownAlready =
    storedKey == null || storedKey == undefined || storedKey == ""
      ? "not-shown"
      : `shown::${storedKey.componentId}`;

  // console.log("GoToPopup :: storedKey", storedKey, cookiePopupShownAlready)
  // React.useEffect(() => {
  //   console.log("GoToPopup :: Update")
  //   if (cookiePopupShownAlready === "not-shown" && popupVisiblity) {
  //     setLocalStorageWithExpiry(
  //       COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
  //       { route: window.location.href, componentId: uniqueId },
  //       POPUP_EXPIRY_IN_MINUTES
  //     );
  //   }
  // });

  if (popupVisiblity && cookiePopupShownAlready === "not-shown") {
    return (
      <div className="cookies_popup_container">
        <p className="font-name-smaller-text mb-0 pb-0 text-align-center">
          This website uses cookies to improve your experience. By using this
          site, you agree with our use of cookies.
        </p>
        <button className="cookies_popup_button" onClick={onClickClose}>
          <span className="font-name-smaller-text-medium">Accept</span>
        </button>
      </div>
    );
  }

  return null;
}
