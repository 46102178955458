import React, { useState, useEffect } from "react";
import { TrialRequestForm, PostTrialRequestForm } from "../modalform/form";

export const RequestFormModal = (props) => {
  const [contactId, setContactId] = useState("");

  console.log("RequestFormModal :: props", props)

  return (
    <>
    {props.product=="automate" ?  <>
    {contactId == "" && !props.showThanksPage ? (
        <TrialRequestForm
          fromPage={props.fromPage}
          setPlan={props.setPlan}
          contactId={contactId}
          setContactId={setContactId}
          plan={props.plan}
          product="automate"
         
        />
      ) : null}
      <div className="second_form">
        <PostTrialRequestForm
          setPlan={props.setPlan}
          contactId={contactId}
          setContactId={setContactId}
          setShowThanksPage={props.setShowThanksPage}
          plan={props.plan}
          product="automate"
          fromPage={props.fromPage}
         
        />
      </div>
      </>
    :
    <>
    {contactId == "" && !props.showThanksPage ? (
      <TrialRequestForm
        fromVideoPage={props.fromVideoPage}
        fromPage={props.fromPage}
        setPlan={props.setPlan}
        contactId={contactId}
        setContactId={setContactId}
        plan={props.plan}
        product={props.product}
      />
    ) : null}
    <div className="second_form">
      <PostTrialRequestForm
        setPlan={props.setPlan}
        contactId={contactId}
        setContactId={setContactId}
        setShowThanksPage={props.setShowThanksPage}
        plan={props.plan}
        product={props.product}
      />
    </div>  
    </>
    }
     
    </>
  );
};
