import React from "react";
import { useMedia } from "use-media";

TopFormWithImage.Header = ({
  children,
  newrelease,
  comingsoon,
  reacentrelease,
}) => {
  const isSmall = window.innerWidth <= 520;

  return (
    <>
      {isSmall ? (
        <h1 className="font-page-header">
          {children}
          {newrelease && (
            <span className="font-section-sub-header-small new-release-features-beta">
              Beta!
            </span>
          )}
          {comingsoon && (
            <span className="font-section-sub-header-small new-release-features">
              Coming Soon!
            </span>
          )}
          {reacentrelease && (
            <span className="font-section-sub-header-small new-release-features">
              New Release!
            </span>
          )}
        </h1>
      ) : (
        <>
          {newrelease && (
            <span className="font-section-sub-header-small new-release-features-beta">
              Beta!
            </span>
          )}
          {comingsoon && (
            <span className="font-section-sub-header-small new-release-features">
              Coming Soon!
            </span>
          )}
          {reacentrelease && (
            <span className="font-section-sub-header-small new-release-features">
              New Release!
            </span>
          )}
          <h1 className="font-page-header">{children}</h1>
        </>
      )}
    </>
  );
};

TopFormWithImage.Content = ({ children, paddingRemovePara, paddingAdd }) => {
  return (
    <p
      className={`font-section-normal-text-testimonials line-height-18 mb-1 ${
        paddingRemovePara ? "pb-0" : ""
      } ${paddingAdd ? paddingAdd : ""}`}
    >
      {children}
    </p>
  );
};

export default function TopFormWithImage({
  children,
  image,
  image_mob,
  altImage,
  product,
  reduceImagePadding,
  additionalClassImage,
  additionalClassSection,
  additionalClassContent,
  isVideo,
  video,
  video_mob,
  isMarginTopZero,
}) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      className={`main-slider feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img ${
        additionalClassSection ? additionalClassSection : ""
      }`}
    >
      <div className="feature_bg_img"></div>
      <div className="container">
        <div className="row">
          {product == "automate" ? null : (
            <div className="back_features">
              <div className="back_features_page">
                <a
                  href="/conversational-ai-platform/features"
                  className="url_manipulation  font-section-small-text-medium"
                >
                  <span>←</span>Back to all features
                </a>
              </div>
            </div>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div
              className={`main-slider-left container-width-changes-features ${
                additionalClassContent ? additionalClassContent : ""
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        <div
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header ${
            reduceImagePadding ? "margin-top-minus-10" : ""
          }`}
        >
          <div className="features_change_img">
            <div className="features_change_img-center display-none-desktop-feature">
              {isVideo ? (
                <video loop autoPlay muted className="w-100 ">
                  <source src={video} type="video/mp4" />
                </video>
              ) : (
                <img
                  className={` ${"d-block"} ${
                    additionalClassImage ? additionalClassImage : ""
                  }`}
                  src={image}
                  alt={altImage}
                />
              )}
            </div>
            <div className="features_change_img-center display-none-mob-feature">
              {isVideo ? (
                <video
                  loop
                  autoPlay
                  muted
                  className={`w-100 ${isMarginTopZero ? "mt-0" : ""}`}
                >
                  <source src={video_mob} type="video/mp4" />
                </video>
              ) : (
                <img className="d-block" src={image_mob} alt={altImage} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
