import React, { useState } from "react";
import { options } from "../../common/utils";
import { isBlockedDomain } from "./domain-utils";
import { redirectToWithUtm } from "../Header";

export function PostTrialRequestForm(props) {
  const { contactId, setContactId, setPlan} = props;

  const [showThankYou, setShowThankYou] = useState(false);

  console.log("PostTrialRequestForm :: Modal", props);

  function closeModal() {
    setPlan("");
    setContactId("");
    setShowThankYou(false);
    props.setShowThanksPage(false);
    props.onRegisterSuccessCallback && props.onRegisterSuccessCallback();
    window.location.href = window.location.origin + window.location.pathname;
  }

  return (
    <div className="modal_submit_form">
      <div
        className={
          contactId.length > 0 || showThankYou
            ? "modal fade show"
            : "modal fade"
        }
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{
          display: contactId.length > 0 || showThankYou ? "flex" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {showThankYou && (
            <div
              className="modal-content modal-content-popup"
              id="beta-thankyou-page"
              style={{ display: "block" }}
            >
              <div className="thankyou-page-img">
                <img
                  src={require("../../assets/images/thanking_img.svg")}
                  alt="thankyou"
                />
                {props.product == "automate" ? (
                  <>
                    <h2 className="font-section-sub-header-small-home text-align-center">
                      Thank you for registering
                    </h2>
                    <p className="font-section-normal-text-testimonials mb-0">
                      Our team will reach out to you soon and provide you with
                      workspace access. 
                    </p>
                  </>
                ) : (
                  <p>
                    Thank You for Registering for the Workativ Assistant Trial!
                    We’ll get back to you soon!
                  </p>
                )}
              </div>
              <button onClick={() => closeModal()}>Close</button>
            </div>
          )}
          {contactId.length > 0 && (
            <PostRequestForm
              contactId={contactId}
              setShowThankYou={() => {
                setContactId("");
                setShowThankYou(true);
                props.setShowThanksPage(true);
              }}
              closeModal={closeModal}
              setPlan={props.setPlan}
              plan={props.plan}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function PostRequestForm(props) {
  let { contactId, setShowThankYou, setPlan, plan, fromPage } = props;

  const [state, setState] = useState({
    phone: "",
    country: "Select your country",
    loader: false,
    automate_usecase: "",
    company_name: "",
  });

  function closeModal() {
    setPlan("");
  }

  function updateContact() {
    setState({ ...state, loader: true });
    const mobile_number = state.phone;
    const country = state.country;
    const company_name = state.company_name;
    console.log("updateContact", state, contactId);
    console.log(plan, "this is plan");

    window.history.pushState(
      {},
      "",
      window.location.search + "?thankyou&" + company_name
    );

    if (contactId != undefined) {
      fetch("/beta-signup", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(
          plan != "enterprise"
            ? {
                email: contactId,
                country,
                company_name,
                mobile_number,
                automate_usecase: state.automate_usecase,
                product: "automate",
              }
            : {
                email: contactId,
                country,
                company_name,
                mobile_number,
                automate_usecase: state.automate_usecase,
                product: "automate",
              }
        ),
      })
        .then((res) => res.json())
        .then((res) => {
          setState({ ...state, loader: false });
          setShowThankYou();
        })
        .catch((error) => {});
    }
  }

  function enableUpdateSubmit() {
    return plan != "enterprise"
      ? contactId.length > 0 &&
          state.phone.length > 0 &&
          state.country.length > 0 &&
          state.country != "Select your country" &&
          state.company_name.length > 0 &&
          state.automate_usecase.length > 0
      : contactId.length > 0 &&
          state.phone.length > 0 &&
          state.automate_usecase.length > 0;
  }

  if (state.loader)
    return (
      <div
        className="loader_demo"
        id="loader_demo"
        style={{ display: "flex", zIndex: "100001" }}
      >
        <div className="loader"></div>
      </div>
    );

  return (
    <div className="modal_submit_form">
      <div
        className="modal fade show"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "flex" }}
      >
        <div
          className="modal-content second-modal-content modal-content-popup"
          id="beta-detail-page"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Help us get to know more about you so that we can serve you better
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}
            >
              <span className="btn_close_popup" aria-hidden="true">
                ×
              </span>
            </button>
          </div>
          <div className="modal-body-header">
            <div className="modal-body">
              <div className="_form_element _x12955626 _full_width ">
                <label className="_form-label">Phone number *</label>
                <div className="_field-wrapper">
                  <input
                    id="beta-phone"
                    type="number"
                    name="phone"
                    onChange={(e) =>
                      setState({ ...state, phone: e.target.value })
                    }
                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="Type your phone number with country code"
                    required
                  />
                </div>
              </div>
              {plan != "enterprise" ? (
                <>
                  <div className="_form_element _field10 _full_width ">
                    <label className="_form-label">Country *</label>
                    <div className="_field-wrapper">
                      <select
                        id="beta-country"
                        name="field[10]"
                        onChange={(e) =>
                          setState({ ...state, country: e.target.value })
                        }
                        required
                        value={state.country}
                      >
                        <option>Select your country</option>
                        {options.map((opt, i) => (
                          <option value={opt.value} key={i}>
                            {opt.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="_form_element _x50476773 _full_width ">
                    <label className="_form-label">Company name *</label>
                    <div className="_field-wrapper">
                      <input
                        id="beta-company"
                        type="text"
                        name="organization"
                        placeholder="Type your organization"
                        onChange={(e) => {
                          // console.log("On Change :: company",e.target.value)
                          setState({ ...state, company_name: e.target.value });
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="_form_element">
                    <label className="_form-label">Use case to Automate</label>
                    <textarea
                      id=""
                      className="form_text_area_automate"
                      name=""
                      rows="6"
                      cols="36"
                      onChange={(e) =>
                        setState({ ...state, automate_usecase: e.target.value })
                      }
                    ></textarea>
                  </div>
                  {/* <div className="_form_element _field4 _full_width ">
                    <label className="_form-label">
                      Helpdesk Tickets Per Month? *
                    </label>
                    <div className="_row _checkbox-radio" id="beta-ticket">
                      <input
                        id="beta-ticket1"
                        type="radio"
                        name="field[4]"
                        defaultValue="Less than 5K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "Less than 5K" })
                        }
                        checked={state.tickets == "Less than 5K"}
                        required
                      />
                      <span>
                        <label htmlFor="field_4Less than 5K">
                          Less than 5K
                        </label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket2"
                        type="radio"
                        name="field[4]"
                        defaultValue="5K to 10K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "5K to 10K" })
                        }
                        checked={state.tickets == "5K to 10K"}
                      />
                      <span>
                        <label htmlFor="field_45K to 10K">5K to 10K</label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket3"
                        type="radio"
                        name="field[4]"
                        defaultValue="10K to 20K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "10K to 20K" })
                        }
                        checked={state.tickets == "10K to 20K"}
                      />
                      <span>
                        <label htmlFor="field_410K to 20K">10K to 20K</label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket4"
                        type="radio"
                        name="field[4]"
                        defaultValue="More than 20K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "More than 20K" })
                        }
                        checked={state.tickets == "More than 20K"}
                      />
                      <span>
                        <label htmlFor="field_4More than 20K">
                          More than 20K
                        </label>
                      </span>
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div className="_form_element">
                    <label className="_form-label">Use case to Automate</label>
                    <textarea
                      id=""
                      className="form_text_area_automate"
                      name=""
                      rows="6"
                      cols="36"
                    ></textarea>
                  </div>
                  {/* <div className="_form_element _field4 _full_width ">
                    <label className="_form-label">
                      Helpdesk Tickets Per Month? *
                    </label>
                    <div className="_row _checkbox-radio" id="beta-ticket">
                      <input
                        id="beta-ticket1"
                        type="radio"
                        name="field[4]"
                        defaultValue="Less than 5K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "Less than 5K" })
                        }
                        checked={state.tickets == "Less than 5K"}
                        required
                      />
                      <span>
                        <label htmlFor="field_4Less than 5K">
                          Less than 5K
                        </label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket2"
                        type="radio"
                        name="field[4]"
                        defaultValue="5K to 10K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "5K to 10K" })
                        }
                        checked={state.tickets == "5K to 10K"}
                      />
                      <span>
                        <label htmlFor="field_45K to 10K">5K to 10K</label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket3"
                        type="radio"
                        name="field[4]"
                        defaultValue="10K to 20K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "10K to 20K" })
                        }
                        checked={state.tickets == "10K to 20K"}
                      />
                      <span>
                        <label htmlFor="field_410K to 20K">10K to 20K</label>
                      </span>
                    </div>
                    <div className="_row _checkbox-radio">
                      <input
                        id="beta-ticket4"
                        type="radio"
                        name="field[4]"
                        defaultValue="More than 20K"
                        onChange={() => {}}
                        onClick={(e) =>
                          setState({ ...state, tickets: "More than 20K" })
                        }
                        checked={state.tickets == "More than 20K"}
                      />
                      <span>
                        <label htmlFor="field_4More than 20K">
                          More than 20K
                        </label>
                      </span>
                    </div>
                  </div> */}

                  <div className="_form_element _x50476773 _full_width ">
                    <label className="_form-label">Message</label>
                    <div className="_field-wrapper">
                      <input
                        id="beta-company"
                        type="text"
                        name="message"
                        placeholder="Type Message"
                        onChange={(e) =>
                          setState({ ...state, message: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="_button-wrapper _full_width">
                <button
                  type="button"
                  className={
                    enableUpdateSubmit()
                      ? "btn btn-primary "
                      : "btn btn-primary disabled_button"
                  }
                  id="Submit"
                  onClick={updateContact}
                >
                  Submit
                </button>
              </div>
              <div className="_clear-element"></div>
            </div>
            <div className="modal_body_right">
              <img
                src={require("../../assets/images/landing/register.png")}
                alt="register"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function TrialRequestForm(props) {
  const { setContactId, setPlan, plan, fromVideoPage } = props;
  console.log("TrialRequestFormModel::: ", props);
  const [state, setState] = useState({
    name: "",
    email: "",
    loader: false,
    contactId: "",
    infoucs: false,
  });

  function closeModal() {
    setPlan("");
  }

  function register() {
    if (
      state.name.length > 0 &&
      state.email.length > 0 &&
      regex.test(state.email)
    ) {
      setState({ ...state, loader: true });
      const name = state.name;
      const email = state.email;
      window.history.pushState({}, "", window.location.search + "?f2&" + email);

      const originurl = window.location.search.trim().slice(1);
      const [origin, code] = originurl.split("=");

      fetch("/beta-signup", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(
          fromVideoPage
            ? {
                name,
                email,
                origin: code ? code : "",
                fromPage: props.fromPage || undefined,
              }
            : {
                name,
                email,
                origin: code ? code : "",
                fromPage: props.fromPage || undefined,
                plan: plan && plan == "homepage" ? undefined : plan,
                product: window.location.pathname.includes(
                  "workflow-automation-platform"
                )
                  ? "automate"
                  : "assistant",
              }
        ),
      })
        .then((res) => res.json())
        .then((res) => {
          const product = window.location.pathname.includes(
            "workflow-automation-platform"
          )
            ? "automate"
            : "assistant";

          if (product === "assistant") {
            window.location = `https://assistant.workativ.com/authentication/u/direct-signup?email=${state.email}`;

            return;
          }
          // else if(product === "automate") {
          //   window.location = `https://automate.workativ.com/authentication/u/direct-signup?email=${state.email}`;
          //   return;
          // }
          setState({
            name: "",
            email: "",
            loader: false,
          });
          setContactId(email);
        })
        .catch((error) => {});
    }
  }

  function enableSubmit() {
    return (
      state.name.length > 0 &&
      state.email.length > 0 &&
      regex.test(state.email) &&
      !isBlockedDomain(state.email)
    );
  }

  return (
    <>
      {state.loader ? (
        <div
          className="loader_demo"
          id="loader_demo"
          style={{ display: "flex" }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="modal_submit_form first_popup_form">
          <div
            className={`${"modal fade first_popup_form_modal"} ${
              setPlan ? "show" : ""
            }`}
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            style={{
              display: setContactId || null ? "flex" : "flex",
            }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content modal-content-popup"
                id="beta-detail-page"
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    Help us get to know more about you so that we can serve you
                    better
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => closeModal()}
                  >
                    <span className="btn_close_popup" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div className="modal-body-header ">
                  <div className="modal-body">
                    <div className="main-slider-button beta-button popup_form_pricing">
                      <form name="form">
                        <input
                          type="text"
                          id="beta-name"
                          onChange={(e) =>
                            setState({ ...state, name: e.target.value })
                          }
                          name="firstname"
                          placeholder="Name"
                          required
                        />
                        {/* {isBlockedDomain(state.email) && (
                          <p className="font-section-small-signup-form alert_signup_mail signup_mail_alert_automate pb-1 mb-0">
                            Please use work emails only
                          </p>
                        )} */}
                        <input
                          type="text"
                          id="beta-email"
                          className={
                            isBlockedDomain(state.email) && !state.infoucs
                              ? "error_email color-red-req"
                              : ""
                          }
                          onChange={(e) =>
                            setState({ ...state, email: e.target.value })
                          }
                          name="lastname"
                          placeholder="Your work email"
                          required
                          onFocus={() => {
                            setState({ ...state, infoucs: true });
                          }}
                          onBlur={() => {
                            setState({ ...state, infoucs: false });
                          }}
                          value={
                            isBlockedDomain(state.email) && !state.infoucs
                              ? "Please use work emails only"
                              : state.email
                          }
                        />
                        {/* Button trigger modal */}
                        {props.product == "automate" ? (
                          <button
                            type="button"
                            onClick={() => register("automate")}
                            id="register-for-trail"
                            disabled={!enableSubmit()}
                            className={
                              enableSubmit()
                                ? "btn btn-primary autoamte_button"
                                : "btn btn-primary autoamte_button disabled_button new_automate_form_button"
                            }
                          >
                            FREE Sign up
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={register}
                            id="register-for-trail"
                            disabled={!enableSubmit()}
                            className={
                              enableSubmit()
                                ? "btn btn-primary"
                                : "btn btn-primary disabled_button"
                            }
                          >
                            Signup - Free Trial
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="modal_body_right">
                    <img
                      src={require("../../assets/images/landing/register.png")}
                      alt="register"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
