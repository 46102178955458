import React from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";

const box = require("../../assets/images/icons-web/box_1.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const freshdesk = require("../../assets/images/icons-web/fd_1.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const gdrive = require("../../assets/images/icons-web/gdrive.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onedrive = require("../../assets/images/icons-web/onedrive.svg");
const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const sharepoint = require("../../assets/images/icons-web/sharepoint.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const zendesk = require("../../assets/images/icons-web/zd_1.svg");
const zenefits = require("../../assets/images/icons-web/zenefits.svg");
const authO = require("../../assets/images/icons-web/auth0.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const deputy = require("../../assets/images/icons-web/deputy.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const peoplehr = require("../../assets/images/icons-web/people-hr.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const zoho_desk = require("../../assets/images/icons-web/zohodesk.svg");
const lastpass = require("../../assets/images/icons-web/lastpass.svg");
const tsheets = require("../../assets/images/icons-web/tsheets.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const fingercheck = require("../../assets/images/icons-web/fingercheck.svg");
const halo = require("../../assets/images/icons-web/halo.svg");
const idaptive = require("../../assets/images/icons-web/idaptive.svg");
const jira_desk = require("../../assets/images/icons-web/jira-desk.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const aws = require("../../assets/images/icons-web/aws.svg");
const duo = require("../../assets/images/icons-web/duo.svg");
const maas_360 = require("../../assets/images/icons-web/mass_360.svg");
const Jamf = require("../../assets/images/icons-web/jamf.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const airwatch = require("../../assets/images/icons-web/airwatch.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");
const addigy = require("../../assets/images/icons-web/Addigy.svg");
const confluence = require("../../assets/images/icons-web/Confluence.svg");
const activecampaign = require("../../assets/images/icons-web/ActiveCampaign.svg");
const freshworkscrm = require("../../assets/images/icons-web/Freshworks_CRM.svg");
const hubspot = require("../../assets/images/icons-web/Hubspot_CRM.svg");
const insightly = require("../../assets/images/icons-web/Insightly.svg");
const microsoftdynamics = require("../../assets/images/icons-web/Microsoft_Dynamics.svg");
const pipedrive = require("../../assets/images/icons-web/Pipedrive.svg");
const salesforcecrm = require("../../assets/images/icons-web/salesforce_desk_CRM.svg");
const zohocrm = require("../../assets/images/icons-web/zoho_crm.svg");
const workday = require("../../assets/images/icons-web/workday.svg");

const heart = require("../../assets/images/header/heart_icon.svg");
const customer_spotlight = require("../../assets/images/header/header_menu_customer_spotlight.png");
const con_ai = require("../../assets/images/header/header_menu_con_ai_icon.svg");
const inbox = require("../../assets/images/header/header_menu_inbox_icon.svg");
const knowledge = require("../../assets/images/header/header_menu_knowledge_ai_icon.svg");
const workflow = require("../../assets/images/header/header_menu_workflow_icon.svg");

const chatbot = require("../../assets/images/header/bot.svg");
const workflows = require("../../assets/images/header/workflows.svg");
const video = require("../../assets/images/header/videos.svg");
const blog = require("../../assets/images/header/blog.svg");
const roi = require("../../assets/images/header/calc.svg");
const whitepaper = require("../../assets/images/header/whitepaper.svg");
const allresources = require("../../assets/images/header/all_resources.svg");

export default function HeaderAssitants() {
  return (
    <Container>
      <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
        {/* <WhyWorkativ />
        <ChatbotIntegration />
        <MarketPlace />
        <Learn /> */}
        <UsecasesMobile />
        <ByFeaturesMobile />
        <CompanySizeMobile />
        <MarketplaceMobile />
      </Layout>
    </Container>
  );
}

export function WhyWorkativ() {
  const lastIndex = whyWorkativData.length - 1;
  const lastBeforeIndex = lastIndex - 1;
  return (
    <section className="section-header-menu-assistants">
      <div className="header-menu-flex-container">
        {whyWorkativData.map((data, index) => (
          <div
            key={index}
            className={`header-menu-assistants-box ${
              index === lastIndex ? "header-menu-assistants-lastbox mr-0" : ""
            } ${index === lastBeforeIndex ? "mr-0" : ""}`}
          >
            <h2 className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3">
              {data.header}{" "}
              {index === lastIndex ? (
                <span className="ml-2">
                  <img src={heart} className="heart_image_header"></img>
                </span>
              ) : null}
            </h2>
            <div className="sub-menu-container">
              {data.subMenu.map((submenu, subIndex) =>
                submenu.customerSpotlight ? (
                  <a
                    href={submenu.url}
                    className="text-decoration-none color-inherit cursor-pointer"
                  >
                    <img src={submenu.lastImage} />
                  </a>
                ) : (
                  <a
                    href={submenu.url}
                    className="text-decoration-none color-inherit"
                  >
                    <div
                      key={subIndex}
                      className={`sub-menu-items ${
                        submenu.isComingSoon ? "mb-3" : ""
                      }`}
                    >
                      {submenu.icon && (
                        <div className="sub-menu-icon">
                          <img src={submenu.icon} />
                        </div>
                      )}
                      <div
                        className={`sub-menu-header-description ${
                          submenu.widthSmall
                            ? "sub-menu-header-description-last-before"
                            : ""
                        }`}
                      >
                        <h3 className="font-section-normal-text-medium-small mb-1">
                          {submenu.subHeader}
                          {submenu.isComingSoon ? (
                            <sup
                              style={{
                                top: "-0.1em",
                                color: "#E05959",
                                fontSize: "16px",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h3>
                        <p
                          className={`font-name-smaller-text mb-0 pb-0 line-height-18`}
                        >
                          {submenu.description}
                        </p>
                      </div>
                    </div>
                  </a>
                )
              )}
            </div>
            {data.headerLinkText && (
              <a
                href={data.headerLinkUrl}
                className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mt-3"
              >
                {" "}
                {data.headerLinkText}&nbsp; &#8594;
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
const whyWorkativData = [
  {
    header: "By Features",
    headerLinkText: "View all features",
    headerLinkUrl: "/conversational-ai-platform/features",
    subMenu: [
      {
        icon: con_ai,
        subHeader: "Conversational AI Platform",
        description: "Build advanced chatbot with Generative AI",
        url: "/conversational-ai-platform/conversational-ai",
      },
      {
        icon: workflow,
        subHeader: "App Workflow Automation",
        description: "Connect bots with apps and automate your workflows",
        url: "/conversational-ai-platform/chatbot-automation",
      },
      {
        icon: knowledge,
        subHeader: "Knowledge AI",
        description:
          "Connect your knowledge base with Gen AI to resolve user queries",
        url: "/conversational-ai-platform/knowledge-ai",
      },
      {
        icon: inbox,
        subHeader: "Shared Live Inbox",
        isComingSoon: true,
        description:
          "Engage users with confidence using shared inbox for live chat",
        url: "/conversational-ai-platform/shared-inbox",
      },
    ],
  },
  {
    header: "By Usecases",
    subMenu: [
      {
        subHeader: "Virtual Agent",
        description: "Scale your support and boost your agents productivity",
        url: "/conversational-ai-platform/virtual-agent",
      },
      {
        subHeader: "HR Chatbot",
        description: "Deliver smarter employee experience with HR Chatbot",
        url: "/conversational-ai-platform/hr-chatbot",
      },
      {
        subHeader: "IT Support Chatbot",
        description: "Automate your employee IT issues and service in seconds",
        url: "/conversational-ai-platform/it-helpdesk-chatbot",
      },
    ],
  },
  {
    header: "By Company Size",
    subMenu: [
      {
        subHeader: "Mid-Market / Enterprise",
        description: "The complete user experience platform for the Enterprise",
        url:
          "/conversational-ai-platform/employee-experience-platform-for-enterprise",
        widthSmall: true,
      },
    ],
  },
  {
    header: "Customer Spotlight",
    subMenu: [
      {
        customerSpotlight: true,
        lastImage: customer_spotlight,
        url:
          "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation",
      },
    ],
  },
];

export function ChatbotIntegration() {
  const lastIndex = chatbotIntegrationData.length - 1;
  return (
    <section className="section-header-menu-assistants">
      <div className="integration-menu-white-box">
        <div className="integration-menu-flex-container d-flex justify-content-between">
          {chatbotIntegrationData.map((data, Index) => (
            <div
              key={Index}
              className={`integration-menu-assistants-box ${
                Index === lastIndex ? "mr-0" : ""
              }`}
            >
              <h2 className="font-section-normal-text-medium-small text-decoration-underline text-underline-offset color-anchor-blue mb-2">
                {data.integrationName}
              </h2>
              {data.apps.map((appdata) => (
                <a href={appdata.applink} className="color-inherit">
                  <div className="integrations-menu-icon-app-name-flex-box">
                    <img
                      src={appdata.appicon}
                      className="integrations-menu-icon"
                    />
                    <h3 className="font-name-smaller-text mb-0 pb-0">
                      {appdata.appname}
                    </h3>
                  </div>
                </a>
              ))}
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center flex-column">
          <p className="font-section-normal-text-testimonials line-height-18 text-align-center ">
            Integrate chatbot with more than 15 category of apps such Expense
            Management, Accounting,
            <br /> SMS Gateways, MSP Software, Project Management, Email Clients
            and more.
          </p>

          <a
            href="/conversational-ai-platform/integrations"
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue text-align-center"
          >
            {" "}
            View all integrations&nbsp; &#8594;
          </a>
        </div>
      </div>
    </section>
  );
}
const chatbotIntegrationData = [
  {
    integrationName: "ITSM",
    apps: [
      {
        appname: "Freshdesk",
        applink: "/conversational-ai-platform/freshdesk-chatbot",
        appicon: freshdesk,
      },
      {
        appname: "Freshservice",
        applink: "/conversational-ai-platform/freshservice-chatbot",
        appicon: freshservice,
      },
      {
        appname: "ServiceNow",
        applink: "/conversational-ai-platform/servicenow-chatbot",
        appicon: servicenow,
      },
      {
        appname: "Zendesk",
        applink: "/conversational-ai-platform/zendesk-chatbot",
        appicon: zendesk,
      },
      {
        appname: "Jira",
        applink: "/conversational-ai-platform/jira-chatbot",
        appicon: jira,
      },
      {
        appname: "Jira Service Desk",
        applink: "/conversational-ai-platform/jira-service-desk-chatbot",
        appicon: jira_desk,
      },
      {
        appname: "SolarWinds",
        applink: "/conversational-ai-platform/solarwinds-chatbot",
        appicon: solarwinds,
      },
      {
        appname: "ZohoDesk",
        applink: "/conversational-ai-platform/zoho-desk-chatbot",
        appicon: zoho_desk,
      },

      {
        appname: "ServiceDesk Plus",
        applink: "/conversational-ai-platform/servicedesk-plus-chatbot",
        appicon: service_desk,
      },

      {
        appname: "HaloITSM",
        applink: "/conversational-ai-platform/halo-itsm-chatbot",
        appicon: halo,
      },
    ],
  },
  {
    integrationName: "HR",
    apps: [
      {
        appname: "Zenefits",
        applink: "/conversational-ai-platform/zenefits-chatbot",
        appicon: zenefits,
      },
      {
        appname: "BambooHR",
        applink: "/conversational-ai-platform/bamboohr-chatbot",
        appicon: bamboo,
      },
      {
        appname: "Workday",
        applink: "/conversational-ai-platform/workday-chatbot",
        appicon: workday,
      },
      {
        appname: "Zoho People",
        applink: "/conversational-ai-platform/zoho-people-chatbot",
        appicon: zoho_people,
      },
      {
        appname: "Deputy",
        applink: "/conversational-ai-platform/deputy-chatbot",
        appicon: deputy,
      },
      {
        appname: "OrangeHRM",
        applink: "/conversational-ai-platform/orange-hrm-chatbot",
        appicon: orangehrm,
      },

      {
        appname: "People HR",
        applink: "/conversational-ai-platform/peoplehr-chatbot",
        appicon: peoplehr,
      },
      {
        appname: "Fingercheck",
        applink: "/conversational-ai-platform/fingercheck-chatbot",
        appicon: fingercheck,
      },
      {
        appname: "TSheets",
        applink: "/conversational-ai-platform/tsheets-chatbot",
        appicon: tsheets,
      },
      {
        appname: "Gusto",
        applink: "/conversational-ai-platform/gusto-chatbot",
        appicon: gusto,
      },
    ],
  },
  {
    integrationName: "Access Management",
    apps: [
      {
        appname: "Okta",
        applink: "/conversational-ai-platform/okta-chatbot",
        appicon: okta,
      },
      {
        appname: "Office 365",
        applink: "/conversational-ai-platform/office-365-chatbot",
        appicon: office365,
      },
      {
        appname: "LastPass",
        applink: "/conversational-ai-platform/lastpass-chatbot",
        appicon: lastpass,
      },
      {
        appname: "Microsoft Azure AD",
        applink: "/conversational-ai-platform/active-directory-chatbot",
        appicon: ad,
      },
      {
        appname: "Auth0",
        applink: "/conversational-ai-platform/auth0-chatbot",
        appicon: authO,
      },
      {
        appname: "Onelogin",
        applink: "/conversational-ai-platform/onelogin-chatbot",
        appicon: onelogin,
      },
      {
        appname: "Jumpcloud",
        applink: "/conversational-ai-platform/jumpcloud-chatbot",
        appicon: jumpcloud,
      },
      {
        appname: "Idaptive",
        applink: "/conversational-ai-platform/idaptive-chatbot",
        appicon: idaptive,
      },

      {
        appname: "Duo Security",
        applink: "/conversational-ai-platform/duo-security-chatbot",
        appicon: duo,
      },
      {
        appname: "AWS IAM ",
        applink: "/conversational-ai-platform/aws-chatbot",
        appicon: aws,
      },
    ],
  },
  {
    integrationName: "CRM",
    apps: [
      {
        appname: "ActiveCampaign",
        applink: "/conversational-ai-platform/activecampaign-chatbot",
        appicon: activecampaign,
      },
      {
        appname: "Hubspot CRM",
        applink: "/conversational-ai-platform/hubspot-crm-chatbot",
        appicon: hubspot,
      },
      {
        appname: "Salesforce CRM",
        applink: "/conversational-ai-platform/salesforce-crm-chatbot",
        appicon: salesforcecrm,
      },
      {
        appname: "Zoho CRM",
        applink: "/conversational-ai-platform/zoho-crm-chatbot",
        appicon: zohocrm,
      },
      {
        appname: "Freshworks CRM",
        applink: "/conversational-ai-platform/freshworks-crm-chatbot",
        appicon: freshworkscrm,
      },
      {
        appname: "Insightly",
        applink: "/conversational-ai-platform/insightly-chatbot",
        appicon: insightly,
      },
      {
        appname: "Pipedrive",
        applink: "/conversational-ai-platform/pipedrive-chatbot",
        appicon: pipedrive,
      },
      {
        appname: "Zendesk Sell",
        applink: "/conversational-ai-platform/zendesk-sell-chatbot",
        appicon: zendesk,
      },
      {
        appname: "Microsoft Dynamics",
        applink: "/conversational-ai-platform/microsoft-dynamics-chatbot",
        appicon: microsoftdynamics,
      },
    ],
  },
  {
    integrationName: "Document Management",
    apps: [
      {
        appname: "Dropbox",
        applink: "/conversational-ai-platform/dropbox-chatbot",
        appicon: dropbox,
      },
      {
        appname: "Box",
        applink: "/conversational-ai-platform/box-chatbot",
        appicon: box,
      },
      {
        appname: "Onedrive",
        applink: "/conversational-ai-platform/onedrive-chatbot",
        appicon: onedrive,
      },
      {
        appname: "Google Drive",
        applink: "/conversational-ai-platform/google-drive-chatbot",
        appicon: gdrive,
      },
      {
        appname: "SharePoint",
        applink: "/conversational-ai-platform/sharepoint-chatbot",
        appicon: sharepoint,
      },
      {
        appname: "Confluence",
        applink: "/conversational-ai-platform/confluence-chatbot",
        appicon: confluence,
      },
    ],
  },
  {
    integrationName: "MDM",
    apps: [
      {
        appname: "Microsoft Intune",
        applink: "/conversational-ai-platform/microsoft-intune-chatbot",
        appicon: intune,
      },
      {
        appname: "JAMF",
        applink: "/conversational-ai-platform/jamf-chatbot",
        appicon: Jamf,
      },
      {
        appname: "ManageEngine MDM",
        applink: "/conversational-ai-platform/manageengine-mdm-chatbot",
        appicon: service_desk,
      },
      {
        appname: "MobileIron",
        applink: "/conversational-ai-platform/mobileiron-chatbot",
        appicon: mobileiron,
      },
      {
        appname: "MaaS360",
        applink: "/conversational-ai-platform/maas360-chatbot",
        appicon: maas_360,
      },
      {
        appname: "Airwatch",
        applink: "/conversational-ai-platform/airwatch-chatbot",
        appicon: airwatch,
      },
      {
        appname: "Hexnode",
        applink: "/conversational-ai-platform/hexnode-chatbot",
        appicon: hexnode,
      },
      {
        appname: "Citrix",
        applink: "/conversational-ai-platform/citrix-chatbot",
        appicon: citrix,
      },
      {
        appname: "Addigy",
        applink: "/conversational-ai-platform/addigy-chatbot",
        appicon: addigy,
      },
    ],
  },
];

export function MarketPlace() {
  return (
    <div className="marketplace-header-menu-container">
      {marketPlaceData.map((data, index) => (
        <a href={data.url} className="text-decoration-none color-inherit">
          <div className="marketplace-header-menu-flex-box">
            <div className="marketplace-header-menu-icon-box">
              <img src={data.icon} />
            </div>
            <div className="marketplace-header-menu-description-box">
              <h3 className="font-section-normal-text-medium-small mb-0">
                {" "}
                {data.heading}
              </h3>
              <p
                className="font-name-smaller-text line-height-18  mb-0 pb-0"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />{" "}
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}
const marketPlaceData = [
  {
    icon: chatbot,
    heading: "Pre-built Chatbots",
    url: "/conversational-ai-platform/pre-built-chatbot-template-marketplace",
    description:
      "Jump-start your chatbot with easy-to<br/> use pre-built bot templates",
  },
  {
    icon: workflows,
    heading: "App Workflows for Chatbot",
    url: "/conversational-ai-platform/app-workflow-template-marketplace",
    description:
      "Jump-start your chatbot with easy-to<br/> use pre-built app workflow templates",
  },
];

export function Learn() {
  return (
    <div className="learn-header-menu-container">
      {learnData.map((data, index) => (
        <a
          href={data.url}
          className="text-decoration-none color-inherit color-black"
        >
          <div className="learn-header-menu-flex-box">
            <div className="marketplace-header-menu-icon-box">
              <img src={data.icon} />
            </div>
            <div className="learn-header-menu-description-box">
              <h3 className="font-section-normal-text-medium-small mb-0">
                {" "}
                {data.pagename}
              </h3>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}
const learnData = [
  {
    url: "/conversational-ai-platform/chatbot-videos",
    icon: video,
    pagename: "Videos",
  },
  {
    url: "https://workativ.com/blog",
    icon: blog,
    pagename: "Blog",
  },
  // {
  //   url: "/conversational-ai-platform/chatbot-roi-calculator",
  //   icon: roi,
  //   pagename: "ROI Calculator",
  // },
  {
    url: "/conversational-ai-platform/chatbot-whitepaper",
    icon: whitepaper,
    pagename: "Whitepaper",
  },
  {
    url: "/conversational-ai-platform/resources",
    icon: allresources,
    pagename: "All Resources",
  },
];

export function ByFeaturesMobile() {
  return (
    <section className="w-100 float-left background-color-grey section-mobile-header-new">
      <div className="container">
        <div className="div-wrapper-box-header-menu-mobile">
          {featuresdata.map((data, Index) => (
            <>
              <a href={data.url} className="text-decoration-none color-black">
                <div className="d-flex gap-10-px mb-4 align-items-start">
                  <div className="features-menu-icon-mobile">
                    <img src={data.icon} />
                  </div>
                  <div>
                    <h3 className="font-section-normal-text-testimonials-medium mb-1">
                      {data.subHeader}
                      {data.isComingSoon ? (
                        <sup
                          style={{
                            top: "0.1em",
                            color: "#E05959",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </sup>
                      ) : null}
                    </h3>
                    <p className="font-section-normal-text-testimonials mb-0 pb-0 line-height-18">
                      {" "}
                      {data.description}
                    </p>
                  </div>
                </div>
              </a>
              {data.isViewAllFeatures ? (
                <a
                  href="/conversational-ai-platform/features"
                  className="font-section-normal-text-testimonials-medium text-decoration-underline text-underline-offset color-anchor-blue mt-3"
                >
                  {" "}
                  View all features&nbsp; &#8594;
                </a>
              ) : null}
            </>
          ))}
        </div>
      </div>
    </section>
  );
}

const featuresdata = [
  {
    icon: con_ai,
    subHeader: "Conversational AI Platform",
    description: "Build advanced chatbot with Generative AI",
    url: "/conversational-ai-platform/conversational-ai",
  },
  {
    icon: workflow,
    subHeader: "App Workflow Automation",
    description: "Connect bots with apps and automate your workflows",
    url: "/conversational-ai-platform/chatbot-automation",
  },
  {
    icon: knowledge,
    subHeader: "Knowledge AI",
    description:
      "Connect your knowledge base with Gen AI to resolve user queries",
    url: "/conversational-ai-platform/knowledge-ai",
  },
  {
    icon: inbox,
    subHeader: "Shared Live Inbox",
    isComingSoon: true,
    description:
      "Engage users with confidence using shared inbox for live chat",
    url: "/conversational-ai-platform/shared-inbox",
    isViewAllFeatures: true,
  },
];

export function UsecasesMobile() {
  return (
    <section className="w-100 float-left background-color-grey section-mobile-header-new">
      <div className="container">
        <div className="div-wrapper-box-header-menu-mobile-usecase">
          {usecasesdata.map((data, Index) => (
            <>
              <a href={data.url} className="text-decoration-none color-black">
                <div className="d-flex mb-4 flex-column">
                  <h3 className="font-section-normal-text-testimonials-medium mb-1">
                    {data.subHeader}
                  </h3>
                  <p className="font-section-normal-text-testimonials mb-0 pb-0 line-height-18">
                    {" "}
                    {data.description}
                  </p>
                </div>
              </a>
            </>
          ))}
        </div>
      </div>
    </section>
  );
}

const usecasesdata = [
  {
    subHeader: "Virtual Agent",
    description: "Scale your support and boost your agents productivity",
    url: "/conversational-ai-platform/virtual-agent",
  },
  {
    subHeader: "HR Chatbot",
    description: "Deliver smarter employee experience with HR Chatbot",
    url: "/conversational-ai-platform/hr-chatbot",
  },
  {
    subHeader: "IT Support Chatbot",
    description: "Automate your employee IT issues and service in seconds",
    url: "/conversational-ai-platform/it-helpdesk-chatbot",
  },
];

export function CompanySizeMobile() {
  return (
    <section className="w-100 float-left background-color-grey section-mobile-header-new">
      <div className="container">
        <div className="div-wrapper-box-header-menu-mobile-usecase">
          {companysizedata.map((data, Index) => (
            <>
              <a href={data.url} className="text-decoration-none color-black">
                <div className="d-flex mb-4 flex-column">
                  <h3 className="font-section-normal-text-testimonials-medium mb-1">
                    {data.subHeader}
                  </h3>
                  <p className="font-section-normal-text-testimonials mb-0 pb-0 line-height-18">
                    {" "}
                    {data.description}
                  </p>
                </div>
              </a>
            </>
          ))}
        </div>
      </div>
    </section>
  );
}

const companysizedata = [
  {
    subHeader: "Mid-Market / Enterprise",
    description: "The complete user experience platform for the Enterprise",
    url:
      "/conversational-ai-platform/employee-experience-platform-for-enterprise",
    widthSmall: true,
  },
];

export function MarketplaceMobile() {
  return (
    <section className="w-100 float-left background-color-grey section-mobile-header-new">
      <div className="container">
        <div className="div-wrapper-box-header-menu-mobile-marketplace">
          {marketplacemobiledata.map((data, Index) => (
            <>
              <a href={data.url} className="text-decoration-none color-black">
                <div className="d-flex gap-10-px mb-4 align-items-start">
                  <div className="features-menu-icon-mobile">
                    <img src={data.icon} />
                  </div>
                  <div>
                    <h3 className="font-section-normal-text-testimonials-medium mb-1">
                      {data.heading}
                    </h3>
                    <p className="font-section-normal-text-testimonials mb-0 pb-0 line-height-18">
                      {" "}
                      {data.description}
                    </p>
                  </div>
                </div>
              </a>
            </>
          ))}
        </div>
      </div>
    </section>
  );
}

const marketplacemobiledata = [
  {
    icon: chatbot,
    heading: "Pre-built Chatbots",
    url: "/conversational-ai-platform/pre-built-chatbot-template-marketplace",
    description:
      "Jump-start your chatbot with easy-to use pre-built bot templates",
  },
  {
    icon: workflows,
    heading: "App Workflows for Chatbot",
    url: "/conversational-ai-platform/app-workflow-template-marketplace",
    description:
      "Jump-start your chatbot with easy-to use pre-built app workflow templates",
  },
];
