import React, { useEffect, useState } from "react";
import { options } from "../../common/utils";
import useMedia from "use-media";
import { isBlockedDomain } from "../modalform/domain-utils";
import { redirectToWithUtm } from "../Header";
import { product } from "ramda";

//second form for automate with thak you form
export function PostTrialRequestForm(props) {
  const { contactId, setContactId, product } = props;

  const [showThankYou, setShowThankYou] = useState(false);

  function closeModal() {
    setContactId("");
    setShowThankYou(false);
    props.onRegisterSuccessCallback && props.onRegisterSuccessCallback();
    window.location.href = window.location.origin + window.location.pathname;
  }
  console.log("PostTrialRequestForm", props, product == "automate");
  // console.log("arunddd", props);
  return (
    <div className="modal_submit_form">
      <div
        className={
          contactId.length > 0 || showThankYou
            ? "modal fade show"
            : "modal fade"
        }
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{
          display: contactId.length > 0 || showThankYou ? "flex" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {showThankYou && (
            <div
              className="modal-content modal-content-popup"
              id="beta-thankyou-page"
              style={{ display: "block" }}
            >
              <div className="thankyou-page-img">
                <img
                  src={require("../../assets/images/thanking_img.svg")}
                  alt="thankyou"
                />
                {console.log("prodcut", product)}
                {product == "automate" ? (
                  <>
                    <h2 className="font-section-sub-header-small-home text-align-center">
                      Thank you for registering
                    </h2>
                    <p className="font-section-normal-text-testimonials mb-0">
                      Our team will reach out to you soon and provide you with
                      workspace access.
                    </p>
                  </>
                ) : (
                  <p>
                    Thank You for Registering for the Workativ Assistant Trial!
                    We’ll get back to you soon!
                  </p>
                )}
              </div>
              <button onClick={() => closeModal()}>Close</button>
            </div>
          )}
          {contactId.length > 0 && (
            <PostRequestForm
              contactId={contactId}
              setShowThankYou={() => {
                setContactId("");
                setShowThankYou(true);
              }}
              closeModal={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}
function PostRequestForm(props) {
  let { contactId, setShowThankYou, closeModal, fromPage } = props;

  const [state, setState] = useState({
    phone: "",
    country: "Select your country",
    loader: false,
    automate_usecase: "",
    company_name: "",
  });

  function updateContact() {
    setState({ ...state, loader: true });
    const mobile_number = state.phone;
    const country = state.country;
    const company_name = state.company_name;
    console.log("updateContact", state,contactId);

    window.history.pushState(
      {},
      "",
      window.location.search + "?thankyou&" + company_name
    );

    if (contactId != undefined) {
      fetch("/beta-signup", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: contactId,
          country,
          company_name,
          mobile_number,
          automate_usecase: state.automate_usecase,
          product : "automate",
          // ticketsPerMonth: state.tickets,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setState({ ...state, loader: false });
          setShowThankYou();
        })
        .catch((error) => {});
    }
  }

  function enableUpdateSubmit() {
    return (
      contactId.length > 0 &&
      state.phone.length > 0 &&
      state.country.length > 0 &&
      state.country != "Select your country" &&
      state.company_name.length > 0 &&
      state.automate_usecase.length > 0
    );
  }

  if (state.loader)
    return (
      <div
        className="loader_demo"
        id="loader_demo"
        style={{ display: "flex", zIndex: "100001" }}
      >
        <div className="loader"></div>
      </div>
    );

  return (
    <div className="modal-content modal-content-popup" id="beta-detail-page">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">
          Help us get to know more about you so that we can serve you better
        </h5>
        <button type="button" className="close" onClick={closeModal}>
          <span className="btn_close_popup" aria-hidden="true">
            ×
          </span>
        </button>
      </div>
      <div className="modal-body-header">
        <div className="modal-body">
          <div className="_form_element _x12955626 _full_width ">
            <label className="_form-label">Phone number *</label>
            <div className="_field-wrapper">
              <input
                id="beta-phone"
                type="number"
                name="phone"
                onChange={(e) => setState({ ...state, phone: e.target.value })}
                // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                placeholder="Type your phone number with country code"
                required
              />
            </div>
          </div>
          <div className="_form_element _field10 _full_width ">
            <label className="_form-label">Country *</label>
            <div className="_field-wrapper">
              <select
                id="beta-country"
                name="field[10]"
                onChange={(e) =>
                  setState({ ...state, country: e.target.value })
                }
                required
                value={state.country}
              >
                <option>Select your country</option>
                {options.map((opt, i) => (
                  <option value={opt.value} key={i}>
                    {opt.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="_form_element _x50476773 _full_width ">
            <label className="_form-label">Company name *</label>
            <div className="_field-wrapper">
              <input
                id="beta-company"
                type="text"
                name="organization"
                placeholder="Type your organization"
                onChange={(e) =>
                  setState({ ...state, company_name: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="_form_element">
            <label className="_form-label">Use case to Automate</label>
            <textarea
              id=""
              className="form_text_area_automate"
              onChange={(e) =>
                setState({ ...state, automate_usecase: e.target.value })
              }
              name=""
              rows="6"
              cols="36"
            ></textarea>
          </div>
          {/* <div className="_form_element _field4 _full_width ">
            <label className="_form-label">Helpdesk Tickets Per Month? *</label>
            <div className="_row _checkbox-radio" id="beta-ticket">
              <input
                id="beta-ticket1"
                type="radio"
                name="field[4]"
                defaultValue="Less than 5K"
                onChange={() => {}}
                onClick={(e) => setState({ ...state, tickets: "Less than 5K" })}
                checked={state.tickets == "Less than 5K"}
                required
              />
              <span>
                <label htmlFor="field_4Less than 5K">Less than 5K</label>
              </span>
            </div>
            <div className="_row _checkbox-radio">
              <input
                id="beta-ticket2"
                type="radio"
                name="field[4]"
                defaultValue="5K to 10K"
                onChange={() => {}}
                onClick={(e) => setState({ ...state, tickets: "5K to 10K" })}
                checked={state.tickets == "5K to 10K"}
              />
              <span>
                <label htmlFor="field_45K to 10K">5K to 10K</label>
              </span>
            </div>
            <div className="_row _checkbox-radio">
              <input
                id="beta-ticket3"
                type="radio"
                name="field[4]"
                defaultValue="10K to 20K"
                onChange={() => {}}
                onClick={(e) => setState({ ...state, tickets: "10K to 20K" })}
                checked={state.tickets == "10K to 20K"}
              />
              <span>
                <label htmlFor="field_410K to 20K">10K to 20K</label>
              </span>
            </div>
            <div className="_row _checkbox-radio">
              <input
                id="beta-ticket4"
                type="radio"
                name="field[4]"
                defaultValue="More than 20K"
                onChange={() => {}}
                onClick={(e) =>
                  setState({ ...state, tickets: "More than 20K" })
                }
                checked={state.tickets == "More than 20K"}
              />
              <span>
                <label htmlFor="field_4More than 20K">More than 20K</label>
              </span>
            </div>
          </div> */}
          <div className="_button-wrapper _full_width">
            <button
              type="button"
              className={
                enableUpdateSubmit()
                  ? "btn btn-primary "
                  : "btn btn-primary disabled_button"
              }
              id="Submit"
              onClick={updateContact}
            >
              Submit
            </button>
          </div>
          <div className="_clear-element"></div>
        </div>
        <div className="modal_body_right">
          <img
            src={require("../../assets/images/landing/register.png")}
            alt="register"
          />
        </div>
      </div>
    </div>
  );
}

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function TrialRequestForm(props) {
  console.log("TrialRequestForm::: ", props);

  const { setContactId, list } = props;
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    loader: false,
    infoucs: false,
  });
  function register(product) {
    console.log("register ::: product", product);
    if (
      // state.name.length > 0 &&
      formState.email.length > 0 &&
      regex.test(formState.email)
    ) {
      if (props.parentContainerRef && props.parentContainerRef.current) {
        props.parentContainerRef.current.style.zIndex = "10000";
      }
      setFormState({ ...formState, loader: true });
      const name = formState.name ? formState.name : "";
      const email = formState.email;
      console.log("formstate email:::", formState, email);
      window.history.pushState({}, "", window.location.search + "?f2&" + email);

      const originurl = window.location.search.trim().slice(1);

      const [origin, code] = originurl.split("=");

      const makeBetaRequest = () =>
        fetch("/beta-signup", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(
            product && typeof product == "string"
              ? {
                  name,
                  email,
                  origin: code ? code : "",
                  product,
                  fromPage: props.fromPage || undefined,
                }
              : {
                  name,
                  email,
                  origin: code ? code : "",
                  fromPage: props.fromPage || undefined,
                  listType: list ? list : "__NO_VALID_LIST__",
                }
          ),
        }).then((res) => res.json());

      // const makeBetaRequest = () => Promise.resolve({ contactId: 10 });

      makeBetaRequest()
        .then((res) => {
          if (props.onRegisterSuccessCallback) {
            props.onRegisterSuccessCallback();
          }

          if (product === "assistant" || product == undefined) {
            if (formState.email.length > 0 && regex.test(formState.email)) {
              window.location = `https://assistant.workativ.com/authentication/u/direct-signup?email=${formState.email}`;
            }
            return;
          }
          // else if (product === "automate") {
          //   if (formState.email.length > 0 && regex.test(formState.email)) {
          //     window.location = `https://automate.workativ.com/authentication/u/direct-signup?email=${formState.email}`;
          //   }
          //   return;
          // }
          if (props.parentContainerRef && props.parentContainerRef.current) {
            props.parentContainerRef.current.style.zIndex = "4";
          }

          setFormState({
            name: "",
            email: "",
            loader: false,
          });
          setContactId(email);
        })
        .catch((error) => {
          console.log("error ::: ", error);
        });
    }
  }

  function enableSubmit() {
    return (
      formState.email.length > 0 &&
      regex.test(formState.email) &&
      !isBlockedDomain(formState.email)
    );
  }

  function enableSubmitForAssistant() {
    return (
      formState.email.length > 0 &&
      regex.test(formState.email) &&
      !isBlockedDomain(formState.email)
    );
  }
  const handleKeyDown = (event) => {
    const enableSubmit = enableSubmitForAssistant();
    // console.log("event", event.keyCode, event.key, enableSubmit, props.product)
    // console.log("1")
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log("2")
      if (enableSubmit) {
        // console.log("3")
        if (props.product == "automate") {
          register("automate");
        } else {
          register("assistant");
        }
      }
    }
  };
  // console.log("TrailRequestForm", props);
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      {formState.loader && (
        <div
          className="loader_demo"
          id="loader_demo"
          style={{ display: "flex" }}
        >
          <div className="loader"></div>
        </div>
      )}
      {/* {isBlockedDomain(formState.email) ? (
        <p className="font-section-small-signup-form alert_signup_mail pb-1 mb-0">
          Please use work emails only
        </p>
      ) : <p className="font-section-small-signup-form alert_signup_mail pb-1 mb-0 mh-25"> */}

      {/* </p>} */}
      <form
        className={`sign_up_form form_sign_update  ${
          props.additionalClassForm ? props.additionalClassForm : ""
        }`}
        name="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          id="beta-email"
          className={
            isBlockedDomain(formState.email) && !formState.infoucs
              ? "sign_up_form_input font-section-small-signup-form error_email color-red-req"
              : "sign_up_form_input"
          }
          onChange={(e) => {
            setFormState({ ...formState, email: e.target.value });
            // setContactId(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          name="lastname"
          placeholder="Your work email"
          required
          onFocus={() => {
            setFormState({ ...formState, infoucs: true });
          }}
          onBlur={() => {
            setFormState({ ...formState, infoucs: false });
          }}
          value={
            isBlockedDomain(formState.email) && !formState.infoucs
              ? "Please use work emails only"
              : formState.email
          }
        />
        {props.product === "automate" ? (
          <button
            type="button"
            onClick={() => register("automate")}
            id="register-for-trial"
            disabled={!enableSubmit()}
            className={
              enableSubmit()
                ? "btn btn-primary automate_button"
                : "btn btn-primary automate_button disabled_button"
            }
          >
            Get Started FREE
          </button>
        ) : props.product === "paidad" ? (
          <button
            type="button"
            onClick={() => register("assistant")}
            id="register-for-trial"
            disabled={!enableSubmitForAssistant()}
            className={
              enableSubmitForAssistant()
                ? "btn btn-primary"
                : "btn btn-primary disabled_button"
            }
          >
            Register for FREE Pilot
          </button>
        ) : (
          <button
            type="button"
            onClick={() => register("assistant")}
            id="register-for-trial"
            disabled={!enableSubmitForAssistant()}
            className={
              enableSubmitForAssistant()
                ? "btn btn-primary"
                : "btn btn-primary disabled_button"
            }
          >
            Get your FREE Bot
          </button>
        )}
      </form>
      {props.AutomateCTA ? (
        <div className="form_below_content signup_cta form-automate-signup-new form_assitants_signup_new">
          <span className="automate-signup-form-span1">Easy setup.</span>
          <span className="automate-signup-form-span2">No credit card. </span>
          <span
            className={`automate-signup-form-span3 ${isSmall ? "pb-0" : null}`}
          >
            Onboarding included.
          </span>
        </div>
      ) : (
        <>
          <div
            className={`form_below_content signup_cta  form_assitants_signup_new ${
              props.contentHidden ? "display-none" : ""
            }`}
          >
            <span
              className={props.isFooterForm === true ? "" : "span_marginleft1"}
            >
              Easy setup.
            </span>
            <span
              className={props.isFooterForm === true ? "" : "span_marginleft2"}
            >
              No credit card.{" "}
            </span>
            <span
              className={
                props.isFooterForm === true
                  ? "footer_below_span_content_last"
                  : "span_marginleft3"
              }
            >
              Onboarding included.
            </span>
          </div>
        </>
      )}
      {props.automateContent ? (
        <p className="basic_plans font-section-sub-header-small basic-plan-center-automate">
          Free sign up. Free to use.
        </p>
      ) : (
        <>
          <p
            className={`basic_plans display-none-all-features signup_cta_p font-section-sub-header-small  ${
              isSmall ? "text-align-center" : ""
            } ${props.contentHidden ? "display-none" : ""}`}
          >
            {" "}
            Free sign up. Free to use.
          </p>
        </>
      )}
    </>
  );
}

export function RequestFormWithOnlyButton(props) {
  console.log("RequestFormWithOnlyButton::: ");

  const redirect = () => {
    window.location = `/conversational-ai-platform`;
  };

  return (
    <>
      <div className="main-slider-button beta-button">
        <form className="sign_up_form button_only_form d-flex" name="form">
          <button
            type="button"
            onClick={() => redirect()}
            className="btn btn-primary"
          >
            Get Started FREE
          </button>
        </form>
      </div>
    </>
  );
}
